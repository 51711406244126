import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { QuotationItemService } from '@shared/services/api/quotation-item.service';
import { ServiceService } from '@shared/services/api/service.service';
import { QuotationOptions, ServiceOptions } from '@shared/models/options';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { CompanyService } from '@shared/services/api/company.service';
import { PopupService } from '@shared/services/popup.service';
import { QuotationModel } from '@shared/models/quotation.model';
import { BaseEditComponent } from '@shared/components/base/base-edit.component';
import * as _ from 'lodash';
import { QuotationService } from '@shared/services/api/quotation.service';
import { InterfaceBaseEdit } from '@shared/interfaces/interface-base-edit';
import { tableConfigs } from '@shared/config';
import { ConfirmWithNoteService } from '@app/shared/services/system/confirm-with-note/confirm-with-note.service';

@Component({
  selector: 'app-quotation-edit',
  templateUrl: './quotation-edit.component.html'
})
export class QuotationEditComponent extends BaseEditComponent
  implements OnInit, InterfaceBaseEdit {
  tableConfig = tableConfigs.quotation;
  tableConfigs = tableConfigs;

  public itemForm: FormGroup;
  public dataFormGroup: FormGroup;
  public quotationOptions: QuotationOptions;

  @Input() id;
  @Input() inPopup = false;
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();
  @Output() onBack = new EventEmitter();
  public item: QuotationModel;
  public clients;
  public suppliers;
  public serviceIdIsValid = false;

  constructor(
    private fb: FormBuilder,
    private serviceService: ServiceService,
    private quotationItemService: QuotationItemService,
    private maoService: ModelAttributeOptionService,
    private companyService: CompanyService,
    private popup: PopupService,
    public injector: Injector,
    public _service: QuotationService,
    private confirmWithNoteService: ConfirmWithNoteService
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.inPopup) {
      //
    } else {
      this.id = this.route.snapshot.paramMap.get('id');
    }
    this.getOptions();
    this.buildItemForm();
    this.show();
    this.companyService.supplierCompaniesBelongFm().subscribe((data: any) => {
      this.suppliers = data;
    });
    this.companyService.clientCompaniesBelongFm().subscribe((data: any) => {
      this.clients = data;
    });
  }

  buildItemForm() {
    this.itemForm = this.fb.group({
      type: ['', Validators.required],
      source_company_id: ['', Validators.required], // supplier company
      status: ['', Validators.required]
    });
    this.dataFormGroup = this.fb.group({
      service_id: ['', { updateOn: 'blur' }],
      discipline: [{ value: '', disabled: true }],
      out_of_hours_work_required: ['no'],
      we_require_another_discipline_contractor_for_these_works: ['No'],
      work_detail: [''],
      special_terms: [''],
      client_reference: [''],
      lead_in_time: [''],
      work_duration: [''],
      client_name: [{ value: '', disabled: true }],
      building_name: [{ value: '', disabled: true }],
      building_address: [{ value: '', disabled: true }]
    });
    this.dataFormGroup.get('service_id').valueChanges.subscribe(change => {
      if (!change) {
        this.serviceIdIsValid = false;
      } else {
        this.serviceService
          .checkValid(change, 'quotation')
          .subscribe((data: any) => {
            if (data.valid) {
              this.serviceIdIsValid = true;
            } else {
              this.serviceIdIsValid = false;
            }
          });
      }
    });
  }

  fillItemForm(item) {
    this.itemForm = this.fb.group({
      type: item.type || '',
      source_company_id: item.source_company_id || '', // supplier company
      status: item.status || ''
    });
    this.dataFormGroup.patchValue({
      service_id: _.get(item, 'data.service_id', ''),
      discipline: _.get(item, 'data.discipline', ''),
      out_of_hours_work_required: _.get(
        item,
        'data.out_of_hours_work_required',
        ''
      ),
      we_require_another_discipline_contractor_for_these_works: _.get(
        item,
        'data.we_require_another_discipline_contractor_for_these_works',
        ''
      ),
      work_detail: _.get(item, 'data.work_detail', ''),
      special_terms: _.get(item, 'data.special_terms', ''),
      client_reference: _.get(item, 'data.client_reference', ''),
      lead_in_time: _.get(item, 'data.lead_in_time', ''),
      work_duration: _.get(item, 'data.work_duration', ''),
      client_name: _.get(item, 'data.client_name', ''),
      building_name: _.get(item, 'data.building_name', ''),
      building_address: _.get(item, 'data.building_address', '')
    });
  }

  getOptions() {
    this.maoService.all().subscribe((data: any) => {
      this.quotationOptions = data.quotation;
    });
  }

  show() {
    this._service.show(this.id).subscribe((data: QuotationModel) => {
      this.item = data;
      this.fillItemForm(data);
    });
  }

  submit() {
    let data = {
      ...this.itemForm.value,
      id: this.id,
      data: this.dataFormGroup.value
    };
    let loader = this.loader.open();
    this._service
      .update(this.id, data)
      .finally(() => {
        loader.close();
        this.refresh();
      })
      .subscribe(() => {
        this.toast.show('Quotation updated!');

        this.onSubmitted.emit();
      });
  }

  openClientReview() {
    this.popup
      .openQuotationClientReview(this.item.target_company_id)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          let loader = this.loader.open();
          this._service
            .clientReview(this.id, { note: res.note, user_ids: res.userIds })
            .finally(() => loader.close())
            .subscribe(() => {
              this.toast.show('Create client review success.');
              this.refresh();
            });
        }
      });
  }

  approve() {
    this.confirmWithNoteService
      .show({ title: `Confirm reject this quotation?` })
      .subscribe(res => {
        if (res) {
          let loader = this.loader.open();
          this._service
            .approve(this.id, { ...res })
            .finally(() => loader.close())
            .subscribe(() => {
              this.toast.show('Quotation approve success.');
              this.refresh();
            });
        }
      });
  }

  reject() {
    this.confirmWithNoteService
      .show({ title: `Confirm reject this quotation?` })
      .subscribe(res => {
        if (res) {
          let loader = this.loader.open();
          this._service
            .reject(this.id, { ...res })
            .finally(() => loader.close())
            .subscribe(() => {
              this.toast.show('Quotation reject success.');
              this.refresh();
            });
        }
      });
  }

  generateService() {
    this.confirmWithNoteService
      .show({ title: `Confirm create service?` })
      .subscribe(res => {
        if (res) {
          let loader = this.loader.open();
          this._service
            .generateService(this.id, { ...res })
            .finally(() => loader.close())
            .subscribe(() => {
              this.toast.show('Create service success.');
              this.refresh();
            });
        }
      });
  }

  openEditServicePopUp(row) {
    this.popup
      .openServiceEditPage(row.id)
      .afterClosed()
      .subscribe(res => {
        if (!res) {
          return;
        }
      });
  }

  openQuotationItemCreatePopup() {
    this.popup
      .openQuotationItemCreatePage(this.id, this.item.data.relation.service)
      .afterClosed()
      .subscribe(res => {
        if (!res) {
          return;
        }
        this.refresh();
      });
  }

  deleteItem(row) {
    this.confirmService
      .confirm({ message: `Delete ${row.id}?` })
      .subscribe(res => {
        if (res) {
          let loader = this.loader.open();
          this.quotationItemService
            .destroy(row.id)
            .finally(() => loader.close())
            .subscribe(() => {
              this.refresh();
              this.toast.show(`Quotation item ${row.id} deleted!`);
            });
        }
      });
  }

  openEditItemPopup(row) {
    this.popup
      .openQuotationItemEditPage(row.id)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.refresh();
        }
      });
  }

  gotoTable() {
    this.router.navigate([`/${this.tableConfig.urlPrefix}`]);
  }

  refresh() {
    this.show();
  }

  openEditServicePopup(serviceId) {
    this.popup.openServiceEditPage(serviceId);
  }
}
