import {
  ModelAttributeOptions,
  NoteOptions
} from './../../../../models/options';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { AppLoaderService } from '@shared/services/system/app-loader/app-loader.service';
import { NoteService } from '@shared/services/api/note.service';
import { CompanyModel } from '@shared/models/company.model';
import { UserService } from '@shared/services/api/user.service';

@Component({
  selector: 'app-note-create',
  templateUrl: './note-create.component.html'
})
export class NoteCreateComponent implements OnInit {
  @Input() id; // type_id
  @Input() type;
  @Input() isPopup = false;
  @Output() onCreated = new EventEmitter();

  public noteOptions: NoteOptions;
  public users = [];
  public userCompanies: CompanyModel[] = [];
  public companies: CompanyModel[] = [];
  public userAllSub;
  public usersLoading = false;

  public itemForm: FormGroup = new FormGroup({
    note: new FormControl('', [Validators.required]),
    severity: new FormControl('')
  });

  constructor(
    private _service: NoteService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private _user: UserService,
    private mao: ModelAttributeOptionService
  ) { }

  ngOnInit() {
    this.mao.all().subscribe((data: ModelAttributeOptions) => {
      this.noteOptions = data.note;
    });
  }

  submit() {
    let data = { ...this.itemForm.value, type: this.type, type_id: this.id };
    let loader = this.loader.open();
    this._service
      .store(data)
      .finally(() => loader.close())
      .subscribe(() => {
        this.snack.open('Note created!', 'OK', { duration: 4000 });
        this.itemForm.reset();
        this.onCreated.emit(true);
      });
  }

  severityStyle(value) {
    return this._service.severityStyle(value);
  }
}
