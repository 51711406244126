import { GlobalSettingConfig } from '@app/shared/interfaces/global-setting-config';
import { Component, OnInit, Input } from '@angular/core';
import { GlobalSettingService } from '@shared/services/system/global-setting.service';
import { FormControl, FormGroup } from '@angular/forms';
import { CompanyModel } from '@shared/models/company.model';
import { CompanyService } from '@shared/services/api/company.service';
import { NavigationEnd, Router } from '@angular/router';
import {NotifyService} from '@shared/services/notify.service';

@Component({
  selector: 'app-global-setting',
  templateUrl: './global-setting.component.html',
  styleUrls: ['./global-setting.component.scss']
})
export class GlobalSettingComponent implements OnInit {
  @Input() globalSettingPanel;
  companies: CompanyModel[] = [];
  listenChange = false;

  settings = new FormGroup({
    pageStartTimeText: new FormControl(''),
    pageEndTimeText: new FormControl(''),
    useCompanyId: new FormControl(''),
  });

  constructor(
    private globalSettingService: GlobalSettingService,
    private toast: NotifyService,
    private companyService: CompanyService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.router.events.subscribe((routeChange) => {
      if (routeChange instanceof NavigationEnd) {
        this.globalSettingPanel.close();
      }
    });
    this.initConfig();
  }

  initConfig() {
    this.settings.patchValue({
      pageStartTimeText: new Date(this.globalSettingService.config.pageStartTimestamp),
      pageEndTimeText: new Date(this.globalSettingService.config.pageEndTimestamp),
      useCompanyId: +this.globalSettingService.config.useCompanyId
    });
    // listen config change
    this.globalSettingService.onConfigChange.subscribe((config: GlobalSettingConfig) => {
      if (+config.useCompanyId !== +this.settings.value.useCompanyId) {
        this.listenChange = true;
        this.settings.patchValue({
          useCompanyId: +config.useCompanyId
        });
      }
    });
    this.companies = this.globalSettingService.getConfig('companies', []);

    this.settings.valueChanges.subscribe((change) => {
      if (!this.listenChange) {
        this.globalSettingService.publishConfig({
          pageStartTimestamp: change.pageStartTimeText.getTime(),
          pageEndTimestamp: change.pageEndTimeText.getTime(),
          useCompanyId: change.useCompanyId,
          useCompany: this.companies.find(item => +item.id === +change.useCompanyId)
        });
        this.toast.show('Save success.');

      } else {
        this.listenChange = false;
      }
    });
  }
}
