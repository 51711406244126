import { CompanyModel } from '@shared/models/company.model';
import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { UserService } from '@shared/services/api/user.service';
import { CompanyService } from '@shared/services/api/company.service';
import * as _ from 'lodash';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { ServiceOptions } from '@shared/models/options';
import { DocumentService } from '@shared/services/api/document.service';
import { DocumentModel } from '@shared/models/document.model';
import { AssetModel } from '@shared/models/asset.model';
import { AssetService } from '@shared/services/api/asset.service';
import { PopupService } from '@shared/services/popup.service';
import { LayoutService } from '@shared/services/system/layout.service';
import { BaseEditComponent } from '@shared/components/base/base-edit.component';
import { parseDate } from '@shared/helpers/utils';
import { ServiceModel } from '@shared/models/service.model';
import { InterfaceBaseEdit } from '@shared/interfaces/interface-base-edit';
import { tableConfigs } from '@shared/config';
import { ServiceService } from '@shared/services/api/service.service';
import { BuildingModel } from '@shared/models/building.model';
import { BuildingService } from '@shared/services/api/building.service';
import { QuotationModel } from '@shared/models/quotation.model';
import { PurchaseOrderModel } from '@shared/models/purchase-order.model';
import {UserModel} from '@shared/models/user.model';
import {GlobalSettingService} from '@shared/services/system/global-setting.service';
import {ServiceCustomizeService} from '@shared/components/common/service-customize.service';
import {ServiceExtraService} from '@shared/components/common/service-extra.service';
import {TimeConvertService} from '@shared/services/time-convert.service';

@Component({
  selector: 'app-service-edit',
  templateUrl: './service-edit.component.html',
  providers: [
    ServiceCustomizeService,
    ServiceExtraService,
  ]
})
export class ServiceEditComponent extends BaseEditComponent implements OnInit, InterfaceBaseEdit {
  _ = _;
  tableConfig = tableConfigs.service;
  tableConfigs = tableConfigs;
  public relationServicesDisplayedColumns = [
    { display: 'Id', key: 'id', useColor: false },
    { display: 'Client', key: 'client_name', useColor: false },
    { display: 'Building', key: 'building_name', useColor: false },
    { display: 'Relation Type', key: 'relation_type', useColor: false },
    { display: 'Type', key: 'service_type', useColor: false },
    { display: 'Discipline', key: 'discipline', useColor: false },
    { display: 'Contractors', key: 'contractors', useColor: false },
    { display: 'Creator', key: 'create_user_name', useColor: false },
    { display: 'Status', key: 'status', useColor: true },
    { display: 'Priority', key: 'severity', useColor: false },
    { display: 'Date', key: 'created_at', useColor: false }
  ];

  public isPPM = false;
  public itemForm: FormGroup;
  loadingAssets = false;

  @Input() id = null;
  @Input() inPopup = false;
  @Input() view = false;
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();
  @Output() onBack = new EventEmitter();

  public item: ServiceModel;
  public status: any[];
  public user: UserModel;
  public buildings: BuildingModel[] = [];
  public serviceOptions: ServiceOptions;
  public supplierCompanies: CompanyModel[];
  public assets: AssetModel[] = [];
  public building: BuildingModel;
  public quotations: QuotationModel[] = [];
  public purchaseOrders: PurchaseOrderModel[] = [];
  public relationServices = [];
  public visits = [];
  public rooms = [];


  // visit
  public certificates: [DocumentModel];

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private globalSettingService: GlobalSettingService,
    private companyService: CompanyService,
    private maoService: ModelAttributeOptionService,
    private documentService: DocumentService,
    private assetService: AssetService,
    public popup: PopupService,
    public layout: LayoutService,
    public injector: Injector,
    public _service: ServiceService,
    public buildingService: BuildingService,
    public customizeService: ServiceCustomizeService,
    public extraService: ServiceExtraService,
    public timeConvertService: TimeConvertService,
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.buildItemForm();
    this.initData();
  }

  initData() {
    this.show();
    this.refreshCertificateTable();
    this.getOptions();
    this.getSupplierCompanies();
    this.getPurchaseOrders();
    this.getQuotations();
    this.getRelationServices();
    this.getServiceVisits();
    this.user = this.globalSettingService.getConfig('user');
    this.getBuildings(this.globalSettingService.config.useCompanyId);
  }

  getPurchaseOrders() {
    this._service.purchaseOrders(this.id).subscribe((data: any) => {
      this.purchaseOrders = data;
    });
  }
  getServiceVisits() {
    this._service.visits(this.id).subscribe((data: any) => {
      this.visits = data;
    });
  }

  getRelationServices() {
    this._service.relationServices(this.id).subscribe((data: any) => {
      this.relationServices = data;
    });
  }

  getQuotations() {
    this._service.quotations(this.id).subscribe((data: any) => {
      this.quotations = data;
    });
  }

  buildItemForm() {
    this.itemForm = this.fb.group({
      building_id: ['', Validators.required],
      subject: [''], // string
      problem: ['', Validators.required], // string
      budget: [0], // number
      service_type: ['', Validators.required], // option
      discipline: ['', Validators.required], // option
      severity: [''], // option
      expect_start_time: [''],
      expect_end_time: [''],
      supplier_company_ids: [[]],
      status: [''],
      asset_ids: [[]],
      room_id: [null],
      client_reference: [''],
    });
    this.itemForm.get('building_id').valueChanges.subscribe(building_id => {
      if (building_id) {
        this.getBuilding(building_id);
        this.getAssets(building_id);
      }
    });
    this.itemForm.valueChanges.subscribe(change => {
      this.isPPM = change.service_type === 'ppm';
    });
  }

  fillForm(item) {
    this.itemForm.patchValue({
      building_id: item.building_id || '',
      subject: item.subject || '', // string
      problem: item.problem || '', // string
      budget: item.budget || 0, // number
      service_type: item.service_type || '', // option
      discipline: item.discipline || '', // option
      severity: item.severity || '', // option
      expect_start_time: parseDate(item, 'expect_start_time'),
      expect_end_time: parseDate(item, 'expect_end_time'),
      supplier_company_ids: item.supplier_company_ids || [],
      status: item.status || '',
      asset_ids: _.get(item, 'asset_ids', []),
      room_id: _.get(item, 'data.room_id', null),
      client_reference: _.get(item, 'data.client.client_reference', ''),
    });

    this.extraService.setFormGroup(_.get(item, 'data.extra', {}));
    this.customizeService.setByObject(_.get(item, 'data.customize', {}));
  }

  getBuilding(buildingId) {
    let loader = this.loader.open();
    this.buildingService
      .show(buildingId)
      .finally(() => loader.close())
      .subscribe((data: BuildingModel) => {
        this.building = data;
        this.rooms = _.get(this.building, 'data.rooms', []);
      });
  }

  getAssets(building_id) {
    let loader = this.loader.open();
    this.loadingAssets = true;
    this.assetService
      .byBuildingId(building_id)
      .finally(() => {
        loader.close();
        this.loadingAssets = false;
      })
      .subscribe((data: [AssetModel]) => {
        this.assets = data;
      });
  }

  getOptions() {
    this.maoService.all().subscribe((data: any) => {
      this.serviceOptions = data.service;
    });
  }

  getSupplierCompanies() {
    let loader = this.loader.open();
    this.companyService
      .contractorsWithRelation(this.id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.supplierCompanies = data;
      });
  }

  getBuildings(client_id) {
    let loader = this.loader.open();
    this._service
      .buildingsByClient(client_id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.buildings = data;
      });
  }

  submit() {
    this.confirmService.confirm({}).subscribe(res => {
      if (res) {
        let itemFormValue = this.itemForm.value;
        itemFormValue['expect_start_time'] = this.timeConvertService.convert(itemFormValue['expect_start_time']);
        itemFormValue['expect_end_time'] = this.timeConvertService.convert(itemFormValue['expect_end_time']);
        let data = {
          ...itemFormValue,
          extra: { ...this.extraService.formGroup.value },
          customize: this.customizeService.allObject()
        };
        let loader = this.loader.open();
        this._service
          .update(this.id, data)
          .finally(() => {
            loader.close();
          })
          .subscribe(() => {
            this.toast.show('Service Updated!');
            this.refresh();
            this.onSubmitted.emit();
          });
      }
    });
  }

  show() {
    let loader = this.loader.open();
    this._service
      .show(this.id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.item = data;
        this.fillForm(data);
      });
  }

  gotoTable() {
    this.router.navigate(['/service']);
  }

  refresh() {
    this.initData();
  }

  refreshCertificateTable() {
    this.documentService.certificates(this.id).subscribe((data: any) => {
      this.certificates = data;
    });
  }

  openQuotationEditPopup($event) {
    this.popup
      .openQuotationEditPage($event.id)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.refresh();
        }
      });
  }

  openPurchaseOrderEditPopup($event) {
    this.popup
      .openPurchaseOrderEditPage($event.id)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.refresh();
        }
      });
  }

  cancel() {
    let loader = this.loader.open();
    this._service
      .cancel(this.id)
      .finally(() => loader.close())
      .subscribe(() => {
        this.toast.show('Cancel success');
        this.refresh();
      });
  }

  openEditServicePopup(row) {
    this.popup.openServiceEditPage(row.id);
  }

  deleteServiceVisit(row) {
    this.confirmService
      .confirm({ message: `Delete ${row.id}?` })
      .subscribe(res => {
        if (res) {
          let loader = this.loader.open();
          this._service
            .destroy(row.id)
            .finally(() => loader.close())
            .subscribe(() => {
              this.refresh();
              this.toast.show(`Service visit ${row.id} deleted!`);
            });
        }
      });
  }

  openEditServiceVisit(row) {
    this.popup
      .openServiceVisitEditPage(row.id)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.refresh();
        }
      });
  }

  searchRoom(term, item) {
    return item.name.toString().toLowerCase().indexOf(term.toString().toLowerCase()) >= 0;
  }
}
