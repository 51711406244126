import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { Logger } from './system/logger.service';

const log = new Logger('debug');

@Injectable({
  providedIn: 'root'
})
export class NotifyService {

  constructor(
    public snackBar: MatSnackBar
  ) { }

  show(message, action = 'Close', configs: MatSnackBarConfig = {}) {
    this.snackBar.open(message, action);
  }

  debug(message, ...data) {
    log.debug(message, ...data);
  }

}
