import {
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import {
  ModelAttributeOptions,
  QuotationItemOptions,
  ServiceOptions
} from '@shared/models/options';
import { CompanyService } from '@shared/services/api/company.service';
import { BuildingService } from '@shared/services/api/building.service';
import { BaseCreateComponent } from '@shared/components/base/base-create.component';
import { tableConfigs } from '@shared/config';
import { QuotationItemService } from '@shared/services/api/quotation-item.service';
import { ServiceEditPopupComponent } from '../../service/service-edit/service-edit-popup.component';

@Component({
  selector: 'app-quotation-item-create',
  templateUrl: './quotation-item-create.component.html'
})
export class QuotationItemCreatePopupComponent extends BaseCreateComponent
  implements OnInit {
  tableConfig = tableConfigs.quotation_item;

  @Input() id;
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();
  public itemFormGroup: FormGroup;
  public lineTotal = 0;
  public options: QuotationItemOptions;
  @Input() public services: { type: string; id: string }[];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { id: number; services: { type: string; id: string }[] },
    public dialogRef: MatDialogRef<QuotationItemCreatePopupComponent>,
    private fb: FormBuilder,
    private maoService: ModelAttributeOptionService,
    public injector: Injector,
    public _service: QuotationItemService,
    public dialog: MatDialog
  ) {
    super(injector);
    if (data.id) {
      this.id = data.id;
    }
    if (data.services) {
      this.services = data.services;
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.buildItemForm();
    this.getOptions();
  }

  buildItemForm() {
    this.itemFormGroup = this.fb.group({
      service_id: [''],
      item_type: ['', Validators.required],
      item_name: ['', Validators.required],
      item_description: ['', Validators.required],
      item_unit_price: [
        0,
        Validators.compose([Validators.required, Validators.min(0)])
      ],
      item_quantity: [
        0,
        Validators.compose([Validators.required, Validators.min(0)])
      ],
      item_status: ['', Validators.required]
    });

    this.itemFormGroup
      .get('item_unit_price')
      .valueChanges.subscribe(item_unit_price => {
        let item_quantity = this.itemFormGroup.value.item_quantity || 0;
        let price = item_unit_price || 0;
        this.lineTotal = item_quantity * price;
      });
    this.itemFormGroup
      .get('item_quantity')
      .valueChanges.subscribe(item_quantity => {
        let q = item_quantity || 0;
        let price = this.itemFormGroup.value.item_unit_price || 0;
        this.lineTotal = q * price;
      });
    this.lineTotal =
      this.itemFormGroup.value.item_quantity *
      this.itemFormGroup.value.item_unit_price;
  }

  getOptions() {
    this.maoService
      .all()
      .subscribe((data: ModelAttributeOptions) => {
        this.options = data.quotation_item;
      });
  }

  submit() {
    let data = { ...this.itemFormGroup.value, quotation_id: this.id };
    let loader = this.loader.open();
    this._service
      .store(data)
      .finally(() => loader.close())
      .subscribe(() => {
        this.onSubmitted.emit();
        this.toast.show('Item Added!');
        if (this.data) {
          this.dialogRef.close(true);
        }
      });
  }

  close() {
    if (this.data) {
      this.dialogRef.close();
    }
  }

  openEditServicePopup(id) {
    this.dialog
      .open(ServiceEditPopupComponent, {
        width: '80%',
        disableClose: false,
        data: { id }
      })
      .afterClosed();
  }
}
