export const dashboardConfigs = {
  service: {
    client_dashboard: {
      display: 'Dashboard',
      items: [
        { hidden_select: false, key: 'ppm' },
        { hidden_select: false, key: 'capital' },
        { hidden_select: false, key: 'reactive' },
        { hidden_select: true, key: 'open_service' },
        { hidden_select: false, key: 'create_quotation' }
      ]
    }
  },
  quotation: {
    critical_quoted_works: {
      display: 'Critical Quoted Works',
      items: [
        { key: 'open_health_and_safety' },
        { key: 'open_non_compliant' },
        { key: 'open_business_critical' },
      ]
    }
  }
};

export const tableConfigs = {
  service: {
    displayName: 'Job',
    key: 'service',
    urlPrefix: 'service',
    displayedColumns: [
      { display: 'Id', key: 'id', width: 100 },
      { display: 'Building', key: 'building_name' },
      { display: 'Type', key: 'service_type', width: 100 },
      { display: 'Discipline', key: 'discipline' },
      { display: 'Contractors', key: 'contractors', flexGrow: 2 },
      { display: 'Status', key: 'status', useColor: true, width: 100 },
      { display: 'Priority', key: 'severity' },
      { display: 'Date', key: 'created_at' },
    ]
  },
  sale: {
    displayName: 'Sale',
    key: 'sale',
    urlPrefix: '',
    displayedColumns: [
      { display: 'Service', key: 'service_id' },
      { display: 'Type', key: 'item_type' },
      { display: 'Name', key: 'item_name' },
      { display: 'Desc', key: 'item_description' },
      { display: 'Total', key: 'item_total_price' },
      { display: 'Unit Price', key: 'item_unit_price' },
      { display: 'Unit Duration', key: 'item_unit_duration' },
      { display: 'Quantity', key: 'item_quantity' },
      { display: 'Operator', key: 'operator' },
      { display: 'Status', key: 'item_status', useColor: true },
    ]
  },
  quotation: {
    displayName: 'Quotation',
    key: 'quotation',
    urlPrefix: 'quotation',
    displayedColumns: [
      { display: 'Id', key: 'id', width: 100 },
      { display: 'Client', key: 'target_company_name' },
      { display: 'Total', key: 'total', width: 100 },
      { display: 'Type', key: 'type' },
      { display: 'Contractor', key: 'source_company_name', flexGrow: 2 },
      { display: 'Date', key: 'created_at' },
      { display: 'Status', key: 'status', useColor: true },
    ]
  },
  purchase_order: {
    displayName: 'Purchase Order',
    key: 'purchase_order',
    urlPrefix: 'purchase-order',
    displayedColumns: [
      { display: 'Id', key: 'id', width: 100 },
      { display: 'Total', key: 'total', width: 100 },
      { display: 'Source', key: 'source_company_name' },
      { display: 'Target', key: 'target_company_name' },
      { display: 'Status', key: 'status', useColor: true, width: 100 },
    ]
  },
  invoice: {
    displayName: 'Certificate',
    key: 'invoice',
    urlPrefix: 'invoice',
    displayedColumns: [
      { display: 'Reference', key: 'reference' },
      { display: 'Type', key: 'type', width: 100 },
      { display: 'Contractor', key: 'source_company_name' },
      { display: 'Client', key: 'target_company_name' },
      { display: 'Total', key: 'total', width: 100 },
      { display: 'Vat', key: 'vat', width: 100 },
      { display: 'Subtotal', key: 'subtotal', width: 100 },
      { display: 'Status', key: 'status', useColor: true, width: 100 },
      { display: 'Issue At', key: 'created_at', width: 100 },
    ]
  },
  company: {
    displayName: 'Company',
    key: 'company',
    urlPrefix: 'settings/company',
    displayedColumns: [
      { display: 'Id', key: 'id', width: 100 },
      { display: 'Name', key: 'name' },
      { display: 'Email', key: 'email' },
      { display: 'Types', key: 'types_show', useColor: true, isArray: true },
      { display: 'Phone', key: 'phone' },
      { display: 'Industry', key: 'industry' },
      { display: 'Status', key: 'status', useColor: true, width: 100 },
    ]
  },
  building: {
    displayName: 'Building',
    key: 'building',
    urlPrefix: 'settings/building',
    displayedColumns: [
      { display: 'Store Number', key: 'id', width: 100 },
      { display: 'Name', key: 'name' },
      { display: 'Status', key: 'status', useColor: true, width: 100 },
      { display: 'Manager Name', key: 'data.contact.manager.name', multiLevel: true},
      { display: 'Manager Email', key: 'data.contact.manager.email', multiLevel: true},
      { display: 'Manager Phone', key: 'data.contact.manager.phone', multiLevel: true},
      { display: 'Region', key: 'data.region', multiLevel: true},
    ]
  },
  application: {
    displayName: 'Application',
    key: 'application',
    urlPrefix: 'application',
    displayedColumns: [
      { display: 'Id', key: 'id', width: 100 },
      { display: 'Total', key: 'total', width: 100 },
      { display: 'Contractor', key: 'source_company_name' },
      { display: 'Client', key: 'target_company_name' },
      { display: 'Status', key: 'status', useColor: true, width: 100 },
      { display: 'Submitted At', key: 'created_at' }
    ]
  },
  purchase_order_item: {
    displayName: 'Purchase Order Item',
    key: 'purchase_order_item',
    urlPrefix: '',
    displayedColumns: [
      { display: 'Id', key: 'id', width: 100 },
      { display: 'Type', key: 'item_type', width: 100 },
      { display: 'Total Price', key: 'item_total_price', width: 100 },
      { display: 'Name', key: 'item_name' },
      { display: 'Unit Price', key: 'item_unit_price', width: 100 },
      { display: 'Quantity', key: 'item_quantity', width: 100 },
      { display: 'Status', key: 'item_status', useColor: true, width: 100 },
    ]
  },
  quotation_item: {
    displayName: 'Quotation Item',
    key: 'quotation_item',
    urlPrefix: '',
    displayedColumns: [
      { display: 'Id', key: 'id' },
      { display: 'Type', key: 'item_type' },
      { display: 'Total Price', key: 'item_total_price' },
      { display: 'Service Id', key: 'item_type_id' },
      { display: 'Name', key: 'item_name' },
      { display: 'Unit Price', key: 'item_unit_price' },
      { display: 'Quantity', key: 'item_quantity' },
      { display: 'Status', key: 'item_status', useColor: true },
    ]
  },
  invoice_item: {
    displayName: 'Invoice Item',
    key: 'invoice_item',
    urlPrefix: '',
    displayedColumns: [
      { display: 'Id', key: 'id' },
      { display: 'Type', key: 'item_type' },
      { display: 'Total Price', key: 'item_total_price' },
      { display: 'Service Id', key: 'item_type_id' },
      { display: 'Name', key: 'item_name' },
      { display: 'Unit Price', key: 'item_unit_price' },
      { display: 'Quantity', key: 'item_quantity' },
      { display: 'Status', key: 'item_status', useColor: true },
    ]
  },
  service_visit: {
    displayName: 'Service Visit',
    key: 'service_visit',
    urlPrefix: '',
    displayedColumns: [
      { display: 'Id', key: 'id' },
      { display: 'User Name', key: 'user_name' },
      { display: 'Check In Time', key: 'check_in_time' },
      { display: 'Check Out Time', key: 'check_out_time' },
      { display: 'Service Status', key: 'service_status', useColor: true },
      { display: 'Observer', key: 'observer' },
      { display: 'Service Id', key: 'service_id' },
    ]
  },
  asset: {
    displayName: 'Asset',
    key: 'asset',
    urlPrefix: 'settings/asset',
    displayedColumns: [
      { display: 'Id', key: 'id', width: 100 },
      { display: 'Building Name', key: 'building_name' },
      { display: 'Category', key: 'category' },
      { display: 'Name', key: 'name' },
      { display: 'Service Interval', key: 'service_interval', width: 100 },
      { display: 'Last Service Time', key: 'last_service_time' },
      { display: 'Next Service Time', key: 'next_service_time' },
      { display: 'Status', key: 'status', useColor: true, width: 100 },
    ]
  },
  ppm: {
    displayName: 'Ppm',
    key: 'ppm',
    urlPrefix: 'ppm',
    displayedColumns: [
      { display: 'Id', key: 'id', width: 100 },
      { display: 'Building Name', key: 'building_name' },
      { display: 'Category', key: 'category' },
      { display: 'Name', key: 'name' },
      { display: 'Service Interval', key: 'service_interval', width: 100 },
      { display: 'Last Service Time', key: 'last_service_time' },
      { display: 'Next Service Time', key: 'next_service_time' },
      { display: 'Status', key: 'ppm_status', useColor: true, width: 100 },
    ]
  },
  approval: {
    display_name: 'Approval',
    key: 'approval',
    displayedColumns: [
      { display: 'Id', key: 'id' },
      { display: 'Subject', key: 'subject' },
      { display: 'Create User', key: 'create_user_name' },
      { display: 'Create Company', key: 'company_name' },
      { display: 'Status', key: 'status', useColor: true },
    ]
  }
};

export const tableReplaceValueColumn = {
  severity: (value) => {
    return value;
  },
  item_total_price: (value) => {
    return parseFloat(value).toFixed(2);
  },
  total: (value) => {
    return parseFloat(value).toFixed(2);
  },
};
