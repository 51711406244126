import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ServiceService} from '@shared/services/api/service.service';

@Component({
  selector: 'app-service-table-popup',
  template: `
    <app-service-table [inPopup]="true" (onClickEdit)="onClickEdit()"></app-service-table>`
})
export class ServiceTablePopupComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { dashboardType: string; dashboardGroup: string; },
    public dialogRef: MatDialogRef<ServiceTablePopupComponent>,
    private service: ServiceService
  ) {
    if (this.data.dashboardType && this.data.dashboardGroup) {
      this.service.changeConditionValue('dashboard_type', {item: data.dashboardType, group: data.dashboardGroup});
    }
  }

  ngOnInit() {
  }

  onClickEdit() {
    this.dialogRef.close();
  }
}
