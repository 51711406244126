import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { ModelAttributeOptions, QuotationItemOptions, ServiceOptions } from '@shared/models/options';
import { QuotationOptions } from '@shared/models/options';
import { CompanyService } from '@shared/services/api/company.service';
import { BaseCreateComponent } from '@shared/components/base/base-create.component';
import { InterfaceBaseCreate } from '@shared/interfaces/interface-base-create';
import { tableConfigs } from '@shared/config';
import { QuotationService } from '@shared/services/api/quotation.service';
import { ServiceService } from '@shared/services/api/service.service';
import { ServiceModel } from '@shared/models/service.model';
import { PopupService } from '@shared/services/popup.service';
import { SimpleReuseStrategy } from '@app/simple-reuse-strategy';

@Component({
  selector: 'app-quotation-create',
  templateUrl: './quotation-create.component.html',
  styleUrls: ['./quotation-create.component.scss'],
  providers: [SimpleReuseStrategy]
})
export class QuotationCreateComponent extends BaseCreateComponent implements OnInit, InterfaceBaseCreate {
  tableConfig = tableConfigs.quotation;
  tableConfigs = tableConfigs;
  public quotationItemDisplayColumns = [
    { display: 'Type', key: 'item_type', useColor: false },
    { display: 'Total Price', key: 'item_total_price', useColor: false },
    { display: 'Name', key: 'item_name', useColor: false },
    { display: 'Unit Price', key: 'item_unit_price', useColor: false },
    { display: 'Quantity', key: 'item_quantity', useColor: false },
  ];
  @Input() serviceId;
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();
  @Output() onBack = new EventEmitter();
  public basicFormGroup: FormGroup;
  public itemFormGroup: FormGroup;
  public dataFormGroup: FormGroup;
  public quotationItems = [];
  public quotationOptions: QuotationOptions;
  public quotationItemOptions: QuotationItemOptions;
  public total = 0;
  public lineTotal = 0;
  public subtotal = 0;

  public serviceOptions: ServiceOptions;
  public clients;
  public suppliers;
  public serviceIdIsValid = false;
  public service: ServiceModel;

  constructor(
    private fb: FormBuilder,
    private maoService: ModelAttributeOptionService,
    private companyService: CompanyService,
    public injector: Injector,
    public _service: QuotationService,
    public serviceService: ServiceService,
    public popup: PopupService
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.buildItemForm();
    if (this.inPopup) {
      this.dataFormGroup.patchValue({ service_id: this.serviceId });
    }
    if (this.serviceId) {
      this.showService(this.serviceId);
    }
    this.getOptions();
    this.companyService.supplierCompaniesBelongFm()
      .subscribe((data: any) => {
        this.suppliers = data;
      });
    this.companyService.clientCompaniesBelongFm()
      .subscribe((data: any) => {
        this.clients = data;
      });
  }

  showService(serviceId) {
    let loader = this.loader.open();
    this.serviceService.show(serviceId)
      .finally(() => loader.close())
      .subscribe((data: ServiceModel) => {
        this.service = data;
        this.dataFormGroup.patchValue({
          discipline: data.discipline,
          client_name: data.client_name,
          building_name: data.building_name,
          building_address: data.building.address1 || data.building.address2,
        });
      });
  }

  buildItemForm() {
    this.basicFormGroup = this.fb.group({
      type: ['', Validators.required],
      source_company_id: ['', Validators.required], // supplier company
      status: ['', Validators.required]
    });

    this.itemFormGroup = this.fb.group({
      item_type: ['', Validators.required],
      item_name: ['', Validators.required],
      item_description: ['', Validators.required],
      item_unit_price: [0, Validators.compose([Validators.required, Validators.min(0)])],
      item_quantity: [0, Validators.compose([Validators.required, Validators.min(0)])],
    });

    this.dataFormGroup = this.fb.group({
      service_id: ['', { updateOn: 'blur' }],
      discipline: [''],
      out_of_hours_work_required: ['no'],
      we_require_another_discipline_contractor_for_these_works: ['No'],
      work_detail: [''],
      special_terms: [''],
      client_reference: [''],
      lead_in_time: [''],
      work_duration: [''],
      client_name: [''],
      building_name: [''],
      building_address: [''],
    });

    this.dataFormGroup.get('service_id').valueChanges.subscribe((change) => {
      console.warn('service id', change);
      if (!change) {
        this.serviceIdIsValid = false;
      } else {
        this.serviceService.checkValid(change, 'quotation')
          .subscribe((data: any) => {
            if (data.valid) {
              this.serviceIdIsValid = true;
              this.showService(change);
            } else {
              this.serviceIdIsValid = false;
            }
          });
      }
    });

    this.itemFormGroup.get('item_unit_price').valueChanges.subscribe((item_unit_price) => {
      let item_quantity = this.itemFormGroup.value.item_quantity || 0;
      let price = item_unit_price || 0;
      this.lineTotal = item_quantity * price;
    });
    this.itemFormGroup.get('item_quantity').valueChanges.subscribe((item_quantity) => {
      let q = item_quantity || 0;
      let price = this.itemFormGroup.value.item_unit_price || 0;
      this.lineTotal = q * price;
    });
  }

  getOptions() {
    this.maoService.all()
      .subscribe((data: ModelAttributeOptions) => {
        this.serviceOptions = data.service;
        this.quotationOptions = data.quotation;
        this.quotationItemOptions = data.quotation_item;
      });
  }

  submit() {
    let data = { ...this.basicFormGroup.value, items: this.quotationItems, data: this.dataFormGroup.value };
    let loader = this.loader.open();
    this._service.store(data)
      .finally(() => loader.close())
      .subscribe(() => {
        SimpleReuseStrategy.deleteRouteSnapshot('//quotation/create');
        this.onSubmitted.emit();
        this.toast.show('Quotation Added!');
      });
  }

  // Quotation Item Function

  addQuotationItem() {
    let now = this.quotationItems;
    now.push({ ...this.itemFormGroup.value, item_total_price: this.itemFormGroup.value.item_unit_price * this.itemFormGroup.value.item_quantity });
    this.quotationItems = [...this.quotationItems];
    this.computeTotal();
  }

  removeQuotationItem(row) {
    let index = this.quotationItems.indexOf(row);
    this.quotationItems.splice(index, 1);
    this.quotationItems = [...this.quotationItems];
    this.computeTotal();
  }

  computeTotal(vat = 0) {
    let subtotal = 0;
    for (let item of this.quotationItems) {
      subtotal += item.item_unit_price * item.item_quantity;
    }
    this.subtotal = subtotal;
    if (!vat) {
      vat = this.basicFormGroup.value.vat || 0;
    }
    this.total = this.subtotal + vat;
  }

  openEditServicePopup(serviceId) {
    this.popup.openServiceEditPage(serviceId);
  }
}
