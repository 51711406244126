import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
import { CompanyService } from '@shared/services/api/company.service';
import { AssetOptions, ModelAttributeOptions } from '@shared/models/options';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { AssetModel } from '@shared/models/asset.model';
import { parseDate } from '@shared/helpers/utils';
import { BaseEditComponent } from '@shared/components/base/base-edit.component';
import { TableConfigModel } from '@shared/models/table-config.model';
import { tableConfigs } from '@shared/config';
import { AssetService } from '@shared/services/api/asset.service';
import { get } from 'lodash';
import {GlobalSettingService} from '@shared/services/system/global-setting.service';
import {UserModel} from '@shared/models/user.model';
import {TimeConvertService} from '@shared/services/time-convert.service';

@Component({
  selector: 'app-asset-edit',
  templateUrl: './asset-edit.component.html'
})
export class AssetEditComponent extends BaseEditComponent implements OnInit {
  tableConfig: TableConfigModel = tableConfigs.asset;
  @Input() id;
  @Input() inPopup = false;
  @Output() onSubmitted = new EventEmitter();
  @Output() onBack = new EventEmitter();
  public companies;
  public buildings;
  public options: AssetOptions;
  public item: AssetModel;
  public user: UserModel;
  public itemForm = new FormGroup({
    building_id: new FormControl('', [Validators.required]),
    name: new FormControl(''),
    category: new FormControl(''),
    description: new FormControl(''),
    service_interval: new FormControl(''),
    last_service_time: new FormControl(''),
    next_service_time: new FormControl('')
  });
  public dataForm = new FormGroup({
    notification_period: new FormControl(0),
    vehicle_make: new FormControl(''),
    asset_condition: new FormControl(''),
    compliant_status: new FormControl('')
  });
  constructor(
    private companyService: CompanyService,
    private globalSetting: GlobalSettingService,
    private maoService: ModelAttributeOptionService,
    private _service: AssetService,
    private timeConvertService: TimeConvertService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.getOptions();
    this.user = this.globalSetting.getConfig('user');
    this.getBuildings(this.globalSetting.config.useCompanyId);
    this.show();
  }

  buildItemForm(item) {
    this.itemForm.patchValue({
      building_id: parseInt(item.building_id, 0) || '',
      name: item.name || '',
      category: item.category || '',
      description: item.description || '',
      service_interval: item.service_interval || '',
      last_service_time: parseDate(item, 'last_service_time'),
      next_service_time: parseDate(item, 'next_service_time')
    });
    this.dataForm.patchValue({
      notification_period: get(item, 'data.notification_period', 0),
      vehicle_make: get(item, 'data.vehicle_make', ''),
      asset_condition: get(item, 'data.asset_condition', ''),
      compliant_status: get(item, 'data.compliant_status', '')
    });
  }

  show() {
    let loader = this.loader.open();
    this._service
      .show(this.id)
      .finally(() => loader.close())
      .subscribe((data: AssetModel) => {
        this.item = data;
        this.buildItemForm(data);
      });
  }

  getOptions() {
    this.maoService.all().subscribe((data: ModelAttributeOptions) => {
      this.options = data.asset;
    });
  }

  getBuildings(company_id) {
    let loader = this.loader.open();
    this.companyService
      .getBuildingsByClientId(company_id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.buildings = data;
      });
  }

  submit() {
    let loader = this.loader.open();
    let itemFormValue = this.itemForm.value;
    itemFormValue['last_service_time'] = this.timeConvertService.convert(itemFormValue['last_service_time']);
    itemFormValue['next_service_time'] = this.timeConvertService.convert(itemFormValue['next_service_time']);
    this._service
      .update(this.id, { ...itemFormValue, data: this.dataForm.value })
      .finally(() => loader.close())
      .subscribe(() => {
        this.onSubmitted.emit();
        this.toast.show('Asset Updated!');
      });
  }
}
