import { UserModel } from '@shared/models/user.model';
import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ServiceService } from '@shared/services/api/service.service';
import { PurchaseOrderItemService } from '@shared/services/api/purchase-order-item.service';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { CompanyService } from '@shared/services/api/company.service';
import { PurchaseOrderOptions } from '@shared/models/options';
import { PopupService } from '@shared/services/popup.service';
import { BaseEditComponent } from '@shared/components/base/base-edit.component';
import { InterfaceBaseEdit } from '@shared/interfaces/interface-base-edit';
import { tableConfigs } from '@shared/config';
import { PurchaseOrderService } from '@shared/services/api/purchase-order.service';
import * as _ from 'lodash';
import { AuthGuard } from '@shared/services/system/auth/auth.guard';
import { GlobalSettingService } from '@shared/services/system/global-setting.service';
import { PurchaseOrderModel } from '@shared/models/purchase-order.model';
import { CompanyModel } from '@app/shared/models/company.model';

@Component({
  selector: 'app-purchase-order-edit',
  templateUrl: './purchase-order-edit.component.html'
})
export class PurchaseOrderEditComponent extends BaseEditComponent implements OnInit, InterfaceBaseEdit {
  tableConfig = tableConfigs.purchase_order;
  tableConfigs = tableConfigs;
  public itemForm: FormGroup;
  public purchaseOrderOptions: PurchaseOrderOptions;

  @Input() id;
  @Input() inPopup = false;
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();
  @Output() onBack = new EventEmitter();

  public item: PurchaseOrderModel;

  public clients: CompanyModel[];
  public serviceSuppliers: CompanyModel[];

  public users = [];
  public orderMethods = [
    { option_value: 'telephone' },
    { option_value: 'email' },
  ];
  public serviceIdIsValid = false;
  public authUser: UserModel;

  constructor(
    private fb: FormBuilder,
    private serviceService: ServiceService,
    private maoService: ModelAttributeOptionService,
    private companyService: CompanyService,
    private popup: PopupService,
    private purchaseOrderItemService: PurchaseOrderItemService,
    public injector: Injector,
    public _service: PurchaseOrderService,
    public auth: AuthGuard,
    public globalSetting: GlobalSettingService
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    if (!this.inPopup) {
      this.id = this.route.snapshot.paramMap.get('id');
    }
    this.authUser = this.globalSetting.getConfig('user');
    this.getOptions();
    this.buildItemForm();
    this.show();
    this.getClients();
    this.companyService.users(this.globalSetting.getConfig('useCompanyId'))
      .subscribe((data: any) => {
        this.users = data;
      });
  }

  buildItemForm() {
    this.itemForm = this.fb.group({
      type: ['', Validators.required],
      subject: ['', Validators.required],
      description: ['', Validators.required],
      vat: [0, Validators.required],
      status: ['', Validators.required],
      source_company_id: ['', Validators.required],
      target_company_id: ['', Validators.required],

      service_id: ['', { updateOn: 'blur' }],
      reference: ['', Validators.required],
      date_of_order: ['', Validators.required],
      delivery_to: ['', Validators.required],
      delivery_address: [''],
      delivery_date: [''],
      order_method: [''],
      emails: [''],
      user_identity: ['']
    });
    this.itemForm.get('service_id').valueChanges.subscribe((change) => {
      if (!change) {
        this.serviceIdIsValid = false;
      } else {
        this.serviceService.checkValid(change)
          .subscribe((data: any) => {
            if (data.valid) {
              this.serviceIdIsValid = true;
            } else {
              this.serviceIdIsValid = false;
            }
          });
      }
    });
    this.itemForm.get('delivery_to').valueChanges.subscribe((change) => {
      if (change === 'fm') {
        const useCompany = this.globalSetting.getConfig('useCompany', new CompanyModel);
        this.itemForm.patchValue({ delivery_address: useCompany.address1 || useCompany.address2 });
      }
    });
    this.itemForm.get('vat').valueChanges.subscribe((change) => {
      this.item.total = this.item.subtotal + change;
    });
    this.itemForm.get('source_company_id').valueChanges.subscribe((clientId) => {
      this.getServiceSuppliers(clientId);
    });
  }

  fillItemForm(item) {
    this.itemForm.patchValue({
      type: item.type || '',
      subject: item.subject || '',
      description: item.description || '',
      vat: item.vat || 0,
      source_company_id: item.source_company_id || '', // supplier company
      target_company_id: item.target_company_id || '', // client company
      status: item.status || '',

      service_id: _.get(item, 'data.service_id', ''),
      reference: item.reference || '',
      date_of_order: _.get(item, 'data.date_of_order'),
      delivery_to: _.get(item, 'data.delivery_to'),
      delivery_address: _.get(item, 'data.delivery_address'),
      delivery_date: _.get(item, 'data.delivery_date'),
      order_method: _.get(item, 'data.order_method'),
      emails: _.get(item, 'data.emails'),
      user_identity: _.get(item, 'data.user_identity')
    });
  }

  getOptions() {
    this.maoService.all()
      .subscribe((data: any) => {
        this.purchaseOrderOptions = data.purchase_order;
      });
  }

  show() {
    let loader = this.loader.open();
    this._service.show(this.id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.item = data;
        this.fillItemForm(data);
      });
  }

  submit() {
    let data = { ...this.itemForm.value, id: this.id };
    let loader = this.loader.open();
    this._service.update(this.id, data)
      .finally(() => {
        loader.close();
        this.show();
      })
      .subscribe(() => {
        this.toast.show('Purchase Order updated!');

        this.onSubmitted.emit();
      });
  }

  approval(status: boolean) {

  }

  openPurchaseOrderCreateItemPopup() {
    this.popup.openPurchaseOrderItemCreatePage(this.id, this.item.data.relation.service)
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.refresh();
        }
      });
  }

  deleteItem(row) {
    this.confirmService.confirm({ message: `Delete ${row.id}?` })
      .subscribe(res => {
        if (res) {
          let loader = this.loader.open();
          this.purchaseOrderItemService.destroy(row.id)
            .finally(() => loader.close())
            .subscribe(() => {
              this.refresh();
              this.toast.show(`Purchase order item ${row.id} deleted!`);
            });
        }
      });
  }

  openEditItemPopup(row) {
    this.popup.openPurchaseOrderItemEditPage(row.id)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.refresh();
        }
      });
  }

  gotoTable() {
    this.router.navigate([`/${this.tableConfig.urlPrefix}`]);
  }

  refresh() {
    this.show();
  }

  openEditServicePopup(row) {
    this.popup.openServiceEditPage(row.id);
  }


  getClients() {
    let loader = this.loader.open();
    this._service.clients()
      .finally(() => loader.close())
      .subscribe((data: CompanyModel[]) => {
        this.clients = data;
      });
  }

  getServiceSuppliers(clientId = '') {
    let loader = this.loader.open();
    this._service.serviceSuppliers(clientId)
      .finally(() => loader.close())
      .subscribe((data: CompanyModel[]) => {
        this.serviceSuppliers = data;
      });
  }
}
