import { Conditions } from '@shared/models/conditions.model';
import * as _ from 'lodash';

export class LaravelPageRequest {
  per_page = 100;   // page length
  page = 1;       // cur page
  conditions: Conditions; // query condition
  keyword = '';
  others = {};

  constructor(
    per_page = 10,
    page = 0,
    conditions: Conditions = {},
    keyword = '',
    others = {}
  ) {
    this.per_page = per_page || 10;
    this.page = page || 0;
    this.conditions = conditions;
    this.keyword = keyword;
    this.others = others;
  }

  request(): any {
    let conditionsData = {};
    // tslint:disable-next-line:forin
    for (const conditionKey in this.conditions) {
      if (_.isString(this.conditions[conditionKey].value) || _.isNumber(this.conditions[conditionKey].value) || _.isArray(this.conditions[conditionKey].value)) {
        conditionsData[conditionKey] = this.conditions[conditionKey].value;
      }
      if (_.isObject(this.conditions[conditionKey].value)) {
        conditionsData[this.conditions[conditionKey].group_key] = this.conditions[conditionKey].value.group;
        conditionsData[this.conditions[conditionKey].item_key] = this.conditions[conditionKey].value.item;
      }
    }
    return {
      per_page: this.per_page,
      page: this.page,
      ...conditionsData,
      keyword: this.keyword,
      ...this.others
    };
  }
}
