import { Component, OnInit, Injector, Input } from '@angular/core';
import { egretAnimations } from '@shared/animations/egret-animations';
import { BaseTableComponent } from '@shared/components/base/base-table.component';
import { Conditions } from '@shared/models/conditions.model';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { ModelAttributeOptions } from '@shared/models/options';
import { ServiceService } from '@shared/services/api/service.service';
import { InterfaceBaseTable } from '@shared/interfaces/interface-base-table';
import { tableConfigs } from '@shared/config';
import {BuildingService} from '@shared/services/api/building.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-service-table',
  templateUrl: './service-table.component.html',
  animations: egretAnimations
})
export class ServiceTableComponent extends BaseTableComponent implements OnInit, InterfaceBaseTable {
  @Input() inPopup = false;
  tableConfig = tableConfigs.service;

  public conditions: Conditions = this._service.conditions;

  public items: any[];

  constructor(
    protected injector: Injector,
    private maoService: ModelAttributeOptionService,
    private buildingService: BuildingService,
    public _service: ServiceService
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.getOptions();
  }

  getOptions() {
    this.maoService.all()
      .subscribe((data: ModelAttributeOptions) => {
        if (data['service']) {
          this.conditions.discipline.items = data.service.discipline;
          this.conditions.status.items = data.service.status;
          this.conditions.severity.items = data.service.severity.map((item) => {
            item.option_name = item.option_value;
            return item;
          });
          this.conditions.service_type.items = data.service.service_type;
        }
      });
    this.buildingService.all()
      .subscribe((res: any) => {
        this.conditions.building_id.items = res;
      });
  }

  changeCondition() {
    let roomId = '';
    if (this.conditions.building_id.value) {
      this.conditions.building_id.items.forEach((item: any) => {
        if (this.conditions.building_id.value == item.id) {
          this.conditions.room_id.items = _.get(item, 'data.rooms', []);
          this.conditions.room_id.items.forEach((room: any) => {
            if (room.id == this.conditions.room_id.value) {
              roomId = room.id;
            }
          })
        }
      });
    }
    this.conditions.room_id.value = roomId;
    this.refresh();
  }
}
