export class CompanyModel {
  id: string;
  name: string;
  type: string;
  industry: string;
  email: string;
  phone: string;
  status: string;
  address1: string;
  address2: string;
  city: string;
  town: string;
  alias: string;
  relation?: { status: string; };
  select_engineers_name_string?: string;
  select_engineers?: { id: string; name: string }[];
  types_show_str?: string;
  types_show?: any[];
}
