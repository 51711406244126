import {Injectable} from '@angular/core';
import {ApiBaseService} from '@shared/services/api/api-base.service';
import {TableConfigModel} from '@shared/models/table-config.model';
import {tableConfigs} from '@shared/config';

@Injectable()
export class InvoiceItemService extends ApiBaseService {
  route_prefix = 'invoice-items';
  tableConfig: TableConfigModel = tableConfigs.invoice_item;

}
