import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, Inject } from '@angular/core';
import 'rxjs-compat/add/operator/finally';
import { egretAnimations } from '@app/shared/animations/egret-animations';

@Component({
  selector: 'app-timesheet-visit-detail',
  templateUrl: './timesheet-visit-detail.component.html',
  animations: egretAnimations
})
export class TimesheetVisitDetailComponent {
  public visitData: {
    created_at: string;
    start_time: string;
    end_time: string;
    seconds: number;
    service_id: number;
    rate_type: string;
    total_price: number;
    callout: {
      quantity: number;
      total_price: number;
      unit_price: number;
      type: string;
    },
    standard: {
      seconds: number;
      total_price: number;
      unit_price: number;
    },
    overtime: {
      seconds: number;
      total_price: number;
      unit_price: number;
    }
  };
  constructor(
    public dialogRef: MatDialogRef<TimesheetVisitDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.visitData = data;
  }

  getMinuteAndSecondStr(second) {
    return Math.floor(second / 60) + ':' + second % 60;
  }
}

