import {Component, OnInit, Injector, Input} from '@angular/core';
import {egretAnimations} from '@shared/animations/egret-animations';
import {BaseTableComponent} from '@shared/components/base/base-table.component';
import {Conditions} from '@shared/models/conditions.model';
import {QuotationService} from '@shared/services/api/quotation.service';
import {InterfaceBaseTable} from '@shared/interfaces/interface-base-table';
import {tableConfigs} from '@shared/config';

@Component({
  selector: 'app-quotation-table',
  templateUrl: './quotation-table.component.html',
  animations: egretAnimations
})
export class QuotationTableComponent extends BaseTableComponent implements OnInit, InterfaceBaseTable {
  @Input() dashboardType = '';
  @Input() dashboardGroup = '';
  @Input() inPopup = false;
  tableConfig = tableConfigs.quotation;

  public conditions: Conditions = this._service.conditions;

  public items: any[];

  constructor(
    public injector: Injector,
    public _service: QuotationService
  ) {
    super(injector);
  }

  ngOnInit() {
    if (this.dashboardType && this.dashboardGroup) {
      this.conditions.dashboard_type.value = {
        item: this.dashboardType,
        group: this.dashboardGroup
      };
    }
    super.ngOnInit();
  }
}
