import { Conditions } from '@shared/models/conditions.model';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { egretAnimations } from '@shared/animations/egret-animations';
import { LayoutService } from '@app/shared/services/system/layout.service';
import { AppLoaderService } from '@app/shared/services/system/app-loader/app-loader.service';
import { PpmService } from '@app/shared/services/api/ppm.service';

@Component({
  selector: 'app-ppm-dashboard',
  templateUrl: './ppm-dashboard.component.html',
  animations: egretAnimations
})
export class PpmDashboardComponent implements OnInit {
  public conditions: Conditions = this._service.conditions;
  dashboard = {
    ppm_to_log: null,
    due_in_n: null,
    over_due: null
  };

  constructor(private _router: Router,
    private _layout: LayoutService,
    private _loader: AppLoaderService,
    private _service: PpmService) {
    this.initData();
  }

  ngOnInit() {
  }

  toPPMTable(dashboard_type) {
    this.conditions.dashboard_type.value = dashboard_type;
    this._layout.layoutConf.switchTable = 'table';
  }

  initData() {
    setTimeout(() => this._loader.open());
    this._service.assetDashboard()
      .finally(() => this._loader.close())
      .subscribe((res: any) => {
        this.dashboard = res;
      });
  }

}
