import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material';
import { Injectable } from '@angular/core';
import { TimesheetVisitDetailComponent } from './timesheet-visit-detail.component';

@Injectable()
export class TimesheetVisitDetailService {

  constructor(private dialog: MatDialog) {
  }

  public show(data): Observable<any> {
    return this.dialog.open(TimesheetVisitDetailComponent, {
      // width: '380px',
      disableClose: false,
      data: data
    }).afterClosed();
  }
}
