import {Injectable} from '@angular/core';
import {ApiBaseService} from '@shared/services/api/api-base.service';
import {Conditions} from '@shared/models/conditions.model';
import {TableConfigModel} from '@shared/models/table-config.model';
import {tableConfigs} from '@shared/config';
import {Subject} from 'rxjs';
import {ModelAttributeOptions} from '@shared/models/options';

@Injectable()
export class BuildingService extends ApiBaseService {
  route_prefix = 'buildings';
  tableConfig: TableConfigModel = tableConfigs.building;

  public conditions: Conditions = {
    status: {
      value: '',
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Status'
    },
  };

  all() {
    return this.http.get(this.route_prefix + '/all');
  }
}
