import {Component, OnInit, Inject, Output, EventEmitter, Injector} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModelAttributeOptions, PurchaseOrderItemOptions} from '@shared/models/options';
import {ModelAttributeOptionService} from '@shared/services/api/model-attribute-option.service';
import {BaseEditComponent} from '@shared/components/base/base-edit.component';
import {InterfaceBaseEdit} from '@shared/interfaces/interface-base-edit';
import {tableConfigs} from '@shared/config';
import {PurchaseOrderItemService} from '@shared/services/api/purchase-order-item.service';

@Component({
  selector: 'app-purchase-order-item-edit-popup',
  templateUrl: './purchase-order-item-edit.component.html'
})
export class PurchaseOrderItemEditPopupComponent extends BaseEditComponent implements OnInit, InterfaceBaseEdit {
  tableConfig = tableConfigs.purchase_order_item;

  public id;
  public itemForm: FormGroup;
  public lineTotal = 0;
  public item;
  public options: PurchaseOrderItemOptions;
  @Output() onUpdated = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<PurchaseOrderItemEditPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public maoService: ModelAttributeOptionService,
    public injector: Injector,
    public _service: PurchaseOrderItemService
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.id = this.data.id;
    this.buildItemForm({});
    this.show();
    this.getOptions();
  }

  getOptions() {
    this.maoService.all()
      .subscribe((data: ModelAttributeOptions) => {
        this.options = data.purchase_order_item;
      });
  }

  buildItemForm(item) {
    this.itemForm = this.fb.group({
      item_name: [item.item_name || '', Validators.required],
      item_description: [item.item_description || '', Validators.required],
      item_unit_price: [item.item_unit_price || 0, Validators.required],
      item_quantity: [item.item_quantity || 0, Validators.required],
      item_status: [item.item_status || '', Validators.required]
    });
    this.itemForm.get('item_unit_price').valueChanges.subscribe((item_unit_price) => {
      let quantity = this.itemForm.value.item_quantity || 0;
      let price = item_unit_price || 0;
      this.lineTotal = quantity * price;
    });
    this.itemForm.get('item_quantity').valueChanges.subscribe((item_quantity) => {
      let q = item_quantity || 0;
      let price = this.itemForm.value.item_unit_price || 0;
      this.lineTotal = q * price;
    });
    if (item.item_unit_price) {
      this.lineTotal = this.item.item_unit_price * this.item.item_quantity;
    }
  }

  show() {
    let loader = this.loader.open();
    this._service.show(this.id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.item = data;
        this.buildItemForm(this.item);
      });
  }

  submit() {
    let data = {...this.itemForm.value};
    let loader = this.loader.open();
    this._service.update(this.id, data)
      .finally(() => loader.close())
      .subscribe(() => {
        this.onUpdated.emit();
        this.toast.show('Purchase order item updated!');
        this.dialogRef.close(true);
      });
  }
}
