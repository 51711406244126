import {Injectable} from '@angular/core';
import {ApiBaseService} from '@shared/services/api/api-base.service';
import {Observable} from 'rxjs';

@Injectable()
export class ServiceVisitService extends ApiBaseService {
  route_prefix = 'service-visits';

  public createServiceVisitAudit(params): Observable<Object> {
    return this.http.post(`${this.route_prefix}/audit`, {...params});
  }
}
