import { Component, OnInit, AfterViewInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { RoutePartsService } from '@shared/services/system/route-parts.service';
import { ThemeService } from '@shared/services/system/theme.service';

import { environment } from '@env/environment';
import { Logger } from '@app/shared/services/system/logger.service';

import { filter } from 'rxjs/operators';
import { GlobalSettingService } from '@shared/services/system/global-setting.service';
import { AppSelectCompanyService } from '@shared/services/system/app-select-company/app-select-company.service';
import { AuthGuard } from '@shared/services/system/auth/auth.guard';
import { AppLoaderService } from './shared/services/system/app-loader/app-loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  appTitle = 'Aims';
  pageTitle = '';

  constructor(
    public title: Title,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private routePartsService: RoutePartsService,
    private themeService: ThemeService,
    private renderer: Renderer2,
    private globalSettingService: GlobalSettingService,
    private selectCompanyService: AppSelectCompanyService,
    private auth: AuthGuard
  ) {
  }

  ngOnInit() {
    this.onPageChange();
    if (environment.production) {
      Logger.enableProductionMode();
    }
  }

  ngAfterViewInit() {
    this.themeService.applyMatTheme(this.renderer);
    setTimeout(() => {
      this.globalSettingService.initTranslation();
    }, 618 * 2);
  }

  onPageChange() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange) => {
      const routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
      if (!routeParts.length) {
        return this.title.setTitle(this.appTitle);
      }
      // Extract title from parts;
      this.pageTitle = routeParts
        .reverse()
        .map((part) => part.title)
        .reduce((partA, partI) => {
          return `${partA} > ${partI}`;
        });
      this.pageTitle += ` | ${this.appTitle}`;
      this.title.setTitle(this.pageTitle);
      if (this.auth.isLogin()) {
        this.checkUseCompanyIdIsSet();
      }
    });
  }

  checkUseCompanyIdIsSet() {
    if (!this.globalSettingService.getConfig('useCompanyId')) {
      this.selectCompanyService.show();
    }
  }
}
