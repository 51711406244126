import { Injectable } from '@angular/core';
import { ApiBaseService } from '@shared/services/api/api-base.service';
import { Conditions } from '@shared/models/conditions.model';
import { dashboardConfigs, tableConfigs } from '@shared/config';
import { TableConfigModel } from '@shared/models/table-config.model';

@Injectable({
  providedIn: 'root'
})
export class ServiceService extends ApiBaseService {

  route_prefix = 'services';
  tableConfig: TableConfigModel = tableConfigs.service;
  public conditions: Conditions = {
    building_id: {
      value: '',
      nullable: true,
      items: [],
      display_key: 'name',
      select_key: 'id',
      display_name: 'Building'
    },
    room_id: {
      value: '',
      nullable: true,
      items: [],
      display_key: 'name',
      select_key: 'id',
      display_name: 'Room'
    },
    service_type: {
      value: '',
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'ServiceType'
    },
    discipline: {
      value: '',
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Discipline'
    },
    severity: {
      value: '',
      nullable: true,
      items: [],
      display_key: 'option_name',
      select_key: 'option_value',
      display_name: 'Priority'
    },
    status: {
      value: '',
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Status'
    },
    dashboard_type: {
      value: { item: '', group: '' },
      nullable: true,
      useGroup: true,
      groups: dashboardConfigs.service,
      group_key: 'dashboard_group',
      item_key: 'dashboard_type',
      display_name: 'Dashboard Type'
    }
  };

  clients() {
    return this.http.get('services/clients');
  }

  buildingsByClient(clientId) {
    return this.http.get(`services/${clientId}/buildings`);
  }

  visits(service_id) {
    return this.http.get(`services/${service_id}/visits`);
  }

  quotations(service_id) {
    return this.http.get(`services/${service_id}/quotations`);
  }

  purchaseOrders(service_id) {
    return this.http.get(`services/${service_id}/purchase-orders`);
  }

  relationServices(service_id) {
    return this.http.get(`services/${service_id}/relation-services`);
  }

  sales(service_id) {
    return this.http.get(`services/${service_id}/sales`);
  }

  homeDashboard() {
    return this.http.get('services/home-dashboard');
  }

  filterServicesByBuilding(buildingId, keyword = '') {
    return this.http.get(`${this.route_prefix}/filter-services/building/${buildingId}`, { params: { keyword } });
  }

  engineers(serviceId) {
    return this.http.get(`services/${serviceId}/engineers`);
  }

  cancel(serviceId) {
    return this.http.post(`services/${serviceId}/cancel`, null);
  }

  checkValid(serviceId, type = '') {
    return this.http.post(`services/${serviceId}/valid`, { type });
  }

  // service uplift
  storeUplift(serviceId, { description = '', user_ids = [], budget = 0 }) {
    return this.http.post(`services/${serviceId}/uplifts`, { description, user_ids, budget });
  }

  approveUplift(serviceId, upliftId, body) {
    return this.http.post(`services/${serviceId}/uplifts/${upliftId}/approve`, body);

  }
  declineUplift(serviceId, upliftId, body) {
    return this.http.post(`services/${serviceId}/uplifts/${upliftId}/decline`, body);
  }

  uplifts(serviceId) {
    return this.http.get(`services/${serviceId}/uplifts`);
  }
}
