import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import {
  BuildingOptions,
  CompanyOptions,
  ModelAttributeOptions
} from '@shared/models/options';
import { CompanyService } from '@shared/services/api/company.service';
import { BaseCreateComponent } from '@shared/components/base/base-create.component';
import { BuildingService } from '@shared/services/api/building.service';
import { tableConfigs } from '@shared/config';
import { InterfaceBaseCreate } from '@shared/interfaces/interface-base-create';

@Component({
  selector: 'app-building-create',
  templateUrl: './building-create.component.html',
  styleUrls: ['./building-create.component.scss']
})
export class BuildingCreateComponent extends BaseCreateComponent
  implements OnInit, InterfaceBaseCreate {
  tableConfig = tableConfigs.building;
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();
  public itemForm: FormGroup;
  public buildingOption: BuildingOptions;
  public companyOption: CompanyOptions;

  constructor(
    private fb: FormBuilder,
    private maoService: ModelAttributeOptionService,
    public _service: BuildingService,
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.getOptions();
    this.buildItemForm();
  }

  buildItemForm() {
    this.itemForm = this.fb.group({
      name: ['', Validators.required],
      alias: [''],
      code: [''],
      address1: [''],
      address2: [''],
      town: [''],
      city: [''],
      postcode: [''],
      country: [''],
      latitude: [''],
      longitude: [''],
      email: ['', [Validators.email]],
      phone: ['']
    });
  }

  getOptions() {
    this.maoService.all().subscribe((data: ModelAttributeOptions) => {
      this.buildingOption = data.building;
      this.companyOption = data.company;
    });
  }

  submit() {
    let loader = this.loader.open();
    this._service
      .store({ ...this.itemForm.value })
      .finally(() => loader.close())
      .subscribe(() => {
        this.onSubmitted.emit();
        this.toast.show('Building Added!');
      });
  }
}
