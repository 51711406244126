import { GlobalSettingService } from './../global-setting.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {

  private isAuthenticated = false; // Set this value dynamically

  constructor(
    private router: Router,
    private globalSettingService: GlobalSettingService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.getToken();
    if (this.isAuthenticated) {
      return true;
    }
    this.router.navigate(['/sessions/signin']);
    return false;
  }

  isLogin() {
    this.getToken();
    if (this.isAuthenticated) {
      return true;
    }
    return false;
  }

  setToken(token: string): void {
    if (token) {
      this.isAuthenticated = true;
      this.globalSettingService.setConfig('token', token, { onEvent: false, onRefresh: false });
    }
  }

  getToken(): string {
    let authToken = this.globalSettingService.getConfig('token');
    if (authToken) {
      this.isAuthenticated = true;
    }
    return authToken;
  }

  signout() {
    this.isAuthenticated = false;
    this.globalSettingService.clearConfig();
    this.router.navigate(['/sessions/signin']);
  }
}
