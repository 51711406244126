import { Component, OnInit, Inject, Injector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import 'rxjs-compat/add/operator/startWith';
import 'rxjs-compat/add/operator/debounceTime';
import 'rxjs-compat/add/operator/distinctUntilChanged';
import 'rxjs-compat/add/operator/switchMap';
import { environment } from '@env/environment';
import { ModelAttributeOptions, ServiceVisitOptions } from '@shared/models/options';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { parseDate } from '@shared/helpers/utils';
import { BaseEditComponent } from '@shared/components/base/base-edit.component';
import { InterfaceBaseEdit } from '@shared/interfaces/interface-base-edit';
import { tableConfigs } from '@shared/config';
import { ServiceVisitService } from '@shared/services/api/service-visit.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-service-visit-edit-popup',
  templateUrl: './service-visit-edit.component.html'
})
export class ServiceVisitEditPopupComponent extends BaseEditComponent implements OnInit, InterfaceBaseEdit {
  tableConfig = tableConfigs.service_visit;
  _ = _;
  public id;
  public inPopup = true;
  public itemForm: FormGroup;
  public item;
  public options: ServiceVisitOptions;
  public questions: any = [];
  public new_audit: any = {};

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ServiceVisitEditPopupComponent>,
    private maoService: ModelAttributeOptionService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public injector: Injector,
    public _service: ServiceVisitService
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.id = this.data.id;
    this.getOptions();
    this.buildItemForm({});
    this.show();
  }

  getOptions() {
    this.maoService.all()
      .subscribe((data: ModelAttributeOptions) => {
        this.options = data.service_visit;
        if (data.audit.data_groups.audit_questions.length > 0) {
          this.questions = data.audit.data_groups.audit_questions[0].data.questions;
        }
      });
  }

  buildItemForm(item) {
    this.itemForm = this.fb.group({
      check_in_time: [parseDate(item, 'check_in_time')],
      check_out_time: [parseDate(item, 'check_out_time')],
      service_status: [item.service_status || ''],
      note: [item.note || ''],
      observer: [item.observer || ''],
      rate_type: [item.rate_type || ''],
      log_type: [item.log_type || ''],
      created_at: [item.created_at || ''],
      signature: [item.signature || ''],
      check_in_latitude: [item.check_in_latitude || ''],
      check_in_longitude: [item.check_in_longitude || ''],
      check_out_latitude: [item.check_out_latitude || ''],
      check_out_longitude: [item.check_out_longitude || ''],
    });
  }

  show() {
    let loader = this.loader.open();
    this._service.show(this.id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.item = data;
        this.buildItemForm(this.item);
        let answers = [];
        if (this.item.audits.length > 0) {
          answers = this.item.audits[0].data.content;
        }
        this.questions.forEach((question) => {
          this.new_audit[question['name']] = '';
          answers.forEach((answer) => {
            if (answer.name === question['name']) {
              this.new_audit[question['name']] = answer.answer;
            }
          });
        });
      });
  }

  gotoTable() {
    if (this.data) {
      this.dialogRef.close();
    }
  }

  getFullFileUrl(url) {
    return environment.fileUrl + url;
  }

  clickEmoji(name, value) {
    this.new_audit[name] = value;
  }

  createAudit(apply_to: string) {
    this.confirmService.confirm({}).subscribe(res => {
      if (res) {
        let params = {
          audit: this.new_audit,
          service_visit_id: this.item.id,
          apply_to: apply_to
        };
        let loader = this.loader.open();
        this._service
          .createServiceVisitAudit(params)
          .finally(() => {
            loader.close();
          })
          .subscribe(() => {
            this.toast.show('Service visit audit submitted!');
            this.refresh();
            // this.onSubmitted.emit();
          });
      }
    });
  }
}
