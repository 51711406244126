import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  public rolesLoading = false;
  public Roles = new Roles();
  public Roles$ = new BehaviorSubject(this.Roles);

  constructor(
    private http: HttpClient
  ) {
    this.initRoles();
  }

  initRoles() {
    this.rolesLoading = true;
    this.http.get('auth/roles')
      .finally(() => this.rolesLoading = false)
      .subscribe((roles: any) => {
        this.Roles.roles = roles;
        this.publishRoles();
      });
  }

  publishRoles() {
    this.Roles$.next(this.Roles);
  }


}

export class Roles {
  public roles = [];

  hasAnyRoles(roles: string[]) {
    let hasRoles = roles.filter((role) => {
      return this.roles.indexOf(role) !== -1;
    })
    return hasRoles.length > 0
  }
}
