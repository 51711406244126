import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-quotation-create-popup',
  template: `
    <app-quotation-create [serviceId]="serviceId" [inPopup]="true" (onSubmitted)="dialogRef.close(true)" (onBack)="dialogRef.close()"></app-quotation-create>`
})

export class QuotationCreatePopupComponent implements OnInit {
  public serviceId;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { serviceId: number },
    public dialogRef: MatDialogRef<QuotationCreatePopupComponent>,
  ) {
    if (this.data.serviceId) {
      this.serviceId = data.serviceId;
    }
  }

  ngOnInit() {
  }
}
