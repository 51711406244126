export class UserModel {
  public id: number;
  public type: string[];
  public company_name: string;
  public company_id: number;
  public name: string;
  public email: string;
  public phone: string;
  public gender: string;
  public date_of_birth?: string;
  public address1: string;
  public address2: string;
  public town: string;
  public city: string;
  public postcode: string;
  public country: string;
  public status: string;
  public migrate_source_id: number;
  public data: any[];
  public auth_token: string;
  public company: any;
  public roles: { id: number, name: string }[];
  public user_companies: {
    id: number;
    type: string[];
    company_id: number;
    company_name: string;
  }[];
  public avatar?: string;
}

