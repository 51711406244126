import { RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle, Route, PRIMARY_OUTLET } from '@angular/router';

export class SimpleReuseStrategy implements RouteReuseStrategy {

    public static _cacheRouters: { [key: string]: any } = {};
    public static _notNeedCache: { [key: string]: any } = {};

    /** 删除缓存路由快照的方法 */
    public static deleteRouteSnapshot(path: string): void {
        if (SimpleReuseStrategy._cacheRouters[path]) {
            delete SimpleReuseStrategy._cacheRouters[path];
        }
        SimpleReuseStrategy._notNeedCache[path] = true;
    }

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        // 默认对所有路由复用 可通过给路由配置项增加data: { keep: true }来进行选择性使用
        // {path: 'search', component: SearchComponent, data: {keep: true}},
        if (!route.data.keep) {
            return false;
        } else {
            return true;
        }
    }

    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        let path: string = this.getRoutePath(route);
        if (!SimpleReuseStrategy._notNeedCache[path]) {
            // 按 path 作为 key 存储路由快照&组件当前实例对象
            // path 等同 RouterModule.forRoot中的配置
            SimpleReuseStrategy._cacheRouters[path] = {
                snapshot: route,
                handle: handle
            };
        } else {
            SimpleReuseStrategy._notNeedCache[path] = false;
        }
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        // 在缓存中有的都认为允许还原路由
        return !!route.routeConfig && !!SimpleReuseStrategy._cacheRouters[this.getRoutePath(route)];
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        // 从缓存中获取快照，若无则返回null
        if (!route.routeConfig || route.routeConfig.loadChildren || !SimpleReuseStrategy._cacheRouters[this.getRoutePath(route)]) {
            return null;
        }
        return SimpleReuseStrategy._cacheRouters[this.getRoutePath(route)].handle;

    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        // 同一路由时复用路由
        return future.routeConfig === curr.routeConfig;
    }

    getRoutePath(route: ActivatedRouteSnapshot): string {
        let namedOutletCount = 0;
        // tslint:disable-next-line:no-shadowed-variable
        return route.pathFromRoot.reduce((path, route) => {
            let config: Route = route.routeConfig;
            if (config) {
                if (config.outlet && config.outlet !== PRIMARY_OUTLET) {
                    path += `(${config.outlet}:`;
                    namedOutletCount++;
                } else {
                    path += '/';
                }
                return path += config.path;
            }
            return path;
        }, '') + (namedOutletCount ? new Array(namedOutletCount + 1).join(')') : '');
    }

}
