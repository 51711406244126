import { Injectable } from '@angular/core';
import { ModelAttributeOptionService } from '@app/shared/services/api/model-attribute-option.service';
import { groupBy, get, set } from 'lodash';
import { FormControl, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ServiceExtraService {
  public formGroup: FormGroup = new FormGroup({});
  public extraForm: {}[] = [];

  constructor(
    public maoService: ModelAttributeOptionService
  ) {
    maoService.getServiceDataDefaultFormList().subscribe(data => {
      let form = {};
      let groupData = groupBy(data, 'data.group.value');
      console.log('groupData', groupData);
      for (const groupName in groupData) {
        if (groupData.hasOwnProperty(groupName)) {
          const groupItem = groupData[groupName];
          let itemForm = {};
          groupItem
            .sort((a, b) => {
              let aOrder = get(a, 'data.order', 0);
              let bOrder = get(b, 'data.order', 0);
              return aOrder - bOrder;
            })
            .map(item => {
              if (item.data.display && item.data.display.fm_client) {
                set(itemForm, item.option_value, new FormControl(''));
              }
            });
          set(form, groupName, new FormGroup(itemForm));
        }
      }
      this.formGroup = new FormGroup(form);
      this.extraForm = data;
    });
  }

  public setFormGroup(extra, options: { recall?: boolean; warranty?: boolean; } = {}) {
    for (const itemGroupKey in this.formGroup.controls) {
      if (this.formGroup.get(itemGroupKey)) {
        this.formGroup.get(itemGroupKey).patchValue(extra);
        if (this.formGroup.get(itemGroupKey).get('recall') && options['recall']) {
          this.formGroup.get(itemGroupKey).get('recall').setValue('yes');
        }
        if (this.formGroup.get(itemGroupKey).get('warranty') && options['warranty']) {
          this.formGroup.get(itemGroupKey).get('warranty').setValue('yes');
        }
      }
    }
  }

  all() {
    let result = {};
    for (const i in this.formGroup.value) {
      if (this.formGroup.value.hasOwnProperty(i)) {
        const element = this.formGroup.value[i];
        result = { ...result, ...element };
      }
    }
    return result;
  }

  clear() {
    this.formGroup.reset();
  }
}
