import { Routes } from '@angular/router';
import { AdminLayoutComponent } from '@shared/components/system/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from '@shared/components/system/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from '@shared/services/system/auth/auth.guard';

export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: './views/sessions/sessions.module#SessionsModule',
        data: { title: 'Session' }
      },
      /*{
        path: 'quick-review',
        loadChildren: './views/quick-review/quick-review.module#QuickReviewModule',
        data: { title: 'Quick review', breadcrumb: 'Quick review' }
      }*/
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: './views/dashboard/dashboard.module#DashboardModule',
        data: { title: 'Dashboard', breadcrumb: 'DASHBOARD' }
      },
      {
        path: 'service',
        loadChildren: './views/service/service.module#ServiceModule',
        data: { title: 'Service', breadcrumb: 'Service' }
      },
      {
        path: 'ppm',
        loadChildren: './views/ppm/ppm.module#PpmModule',
        data: { title: 'PPM', breadcrumb: 'PPM' }
      },
      {
        path: 'quotation',
        loadChildren: './views/quotation/quotation.module#QuotationModule',
        data: { title: 'Quotation', breadcrumb: 'Quotation' }
      },
      {
        path: 'application',
        loadChildren: './views/application/application.module#ApplicationModule',
        data: { title: 'Application', breadcrumb: 'Application' }
      },
      {
        path: 'purchase-order',
        loadChildren: './views/purchase-order/purchase-order.module#PurchaseOrderModule',
        data: { title: 'Purchase Order', breadcrumb: 'Purchase Order' }
      },
      {
        path: 'invoice',
        loadChildren: './views/invoice/invoice.module#InvoiceModule',
        data: { title: 'Invoice', breadcrumb: 'Invoice' }
      },
      {
        path: 'timesheet',
        loadChildren: './views/timesheet/timesheet.module#TimesheetModule',
        data: { title: 'Timesheet', breadcrumb: 'Timesheet' }
      },
      {
        path: 'search/:keyword',
        loadChildren: './views/search/search.module#SearchModule',
        data: { title: 'Search', breadcrumb: 'Search' }
      },
      {
        path: 'test',
        loadChildren: './views/test/test.module#TestModule',
        data: { title: 'Test', breadcrumb: 'Test' }
      },
      {
        path: '',
        loadChildren: './views/settings/settings.module#SettingsModule',
        data: { title: 'Settings', breadcrumb: 'Settings' }
      },
      {
        path: 'profile',
        loadChildren: './views/profile/profile.module#ProfileModule',
        data: { title: 'Profile', breadcrumb: 'PROFILE' }
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'sessions/404'
  }
];

