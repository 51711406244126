import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';

export class User {
  id: number;
  name: string;
  email: string;
  company: string;
}

@Injectable()
export class UserService {
  resource_prefix = 'users';
  public users: User[] = undefined;

  constructor(private http: HttpClient) {
  }

  accountantsByCompanyId(companyId = '') {
    return this.http.get(this.resource_prefix + '/accountants-by-company-id/' + companyId);
  }

  accountantsByServiceId(serviceId = '') {
    return this.http.get(this.resource_prefix + '/accountants-by-service-id/' + serviceId);
  }

  suppliersByCompanyId(companyId = '') {
    return this.http.get(this.resource_prefix + '/suppliers-by-company-id/' + companyId);
  }

  engineersByCompanyId(companyId = '') {
    return this.http.get(this.resource_prefix + '/engineers-by-company-id/' + companyId);
  }

  all({ company_ids } = { company_ids: [] }): Observable<User[] | any> {
    return this.http.post(`${this.resource_prefix}/all`, { company_ids });
  }
}
