export class Paginate {
  // The number of elements in the page, default table size
  size = 100;
  // The total number of elements
  totalElements = 0;
  // The total number of pages
  totalPages = 0;
  // The current page number
  pageNumber = 0;
}
