import {Component, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy, OnChanges} from '@angular/core';
import { Paginate } from '@shared/models/paginate.model';
import { ThemeService } from '@shared/services/system/theme.service';
import { tableReplaceValueColumn } from '@app/shared/config';
import { get, isArray } from 'lodash';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { LayoutService } from '@app/shared/services/system/layout.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html'
})
export class AppTableComponent implements OnInit, OnDestroy, OnChanges {

  isArray = isArray;
  @Input()
  set items(items) {
    items = items || [];
    this._items = items.map((item) => {
      for (let key in item) {
        if (get(tableReplaceValueColumn, key)) {
          item[key] = get(tableReplaceValueColumn, key)(item[key]);
        }
      }
      return item;
    });
    this.temp = [...this._items];
  }
  @Input() _items: any[] = [];
  @Input() displayedColumns: any[] = [];
  @Input() externalPaging = true;
  @Input() editButton = true;
  @Input() deleteButton = true;
  @Input() loading = false;
  @Input() checkbox = false;
  @Input() headerCheckboxable = false;
  @Output() onFilter = new EventEmitter();
  @Output() onClickEdit = new EventEmitter<number>();
  @Output() onClickDelete = new EventEmitter<number>();
  @Output() onClickPage = new EventEmitter<number>();
  @ViewChild(DatatableComponent) table: DatatableComponent;
  paginator: Paginate = new Paginate();
  selected = [];
  temp: any[] = [];
  showTable = true;
  destroy = false;
  finally_items = [];
  constructor(
    public theme: ThemeService,
    private layout: LayoutService
  ) {
  }

  ngOnInit() {
    this.layout.layoutConfSubject.takeWhile(() => !this.destroy)
      .subscribe((config) => {
        this.showTable = false;
        setTimeout(() => {
          this.showTable = true;
        });
      });
  }

  ngOnDestroy(): void {
    this.destroy = true;
  }

  setPaginator(length, pageSize, pageIndex) {
    this.paginator.totalElements = length;
    this.paginator.size = pageSize;
    this.paginator.pageNumber = pageIndex;
  }

  ngOnChanges(): void {
    if (this._items && this._items.length > 0) {
      this.finally_items = this._items.map((item) => {
        this.displayedColumns.forEach((column) => {
          if (column.multiLevel) {
            item[column.key] = this.content(item, column.key);
          }
        });

        return item;
      });
    }
  }

  clickEdit(row) {
    this.onClickEdit.emit(row);
  }

  clickDelete(row) {
    this.onClickDelete.emit(row);
  }

  clickPage($event) {
    this.onClickPage.emit($event);
  }

  /**
   * @param $event input filter string
   */
  filter($event) {
    if (this.externalPaging) {
      this.onFilter.emit($event);
    } else {
      const val = $event.toLowerCase();
      let temp = this.temp;
      if (val) {
        temp = this.temp.filter(item => {
          let isFind = false;
          this.displayedColumns.forEach(element => {
            if (item[element.key]) {
              if ((item[element.key]).toString().toLowerCase().indexOf(val) !== -1) {
                isFind = true;
              }

              if ((item[element.key]).toString().toLowerCase().replace('_', ' ').indexOf(val) !== -1) {
                isFind = true;
              }
            }
          });
          return isFind;
        });
      }
      this.finally_items = temp;
      this.table.offset = 0;
    }
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  content(row, key) {
    if (key.indexOf('.') >= 0) {
      let key_arr = key.split('.');
      let obj = row;
      key_arr.forEach((sub_key) => {
        if (obj && obj.hasOwnProperty(sub_key)) {
          obj = obj[sub_key];
        } else {
          obj = '';
        }
      });
      return obj;
    }

    return row[key];
  }

  sort() { }
}
