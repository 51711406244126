import { BuildingModel } from './../../../../models/building.model';
import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { AssetOptions, ModelAttributeOptions } from '@shared/models/options';
import { CompanyService } from '@shared/services/api/company.service';
import { BaseCreateComponent } from '@shared/components/base/base-create.component';
import { TableConfigModel } from '@shared/models/table-config.model';
import { tableConfigs } from '@shared/config';
import { AssetService } from '@shared/services/api/asset.service';
import * as _ from 'lodash';
import {GlobalSettingService} from '@shared/services/system/global-setting.service';
import {UserModel} from '@shared/models/user.model';
import {TimeConvertService} from '@shared/services/time-convert.service';

@Component({
  selector: 'app-asset-create',
  templateUrl: './asset-create.component.html'
})
export class AssetCreateComponent extends BaseCreateComponent
  implements OnInit {
  tableConfig: TableConfigModel = tableConfigs.asset;
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();
  @Input() data;
  public itemForm = new FormGroup({
    building_id: new FormControl('', [Validators.required]),
    name: new FormControl(''),
    category: new FormControl(''),
    description: new FormControl(''),
    service_interval: new FormControl(''),
    last_service_time: new FormControl(''),
    next_service_time: new FormControl('')
  });
  public dataForm = new FormGroup({
    notification_period: new FormControl(0),
    vehicle_make: new FormControl(''),
    asset_condition: new FormControl(''),
    compliant_status: new FormControl('')
  });
  public buildings: BuildingModel[] = [];
  public options: AssetOptions;
  public user: UserModel;

  constructor(
    private maoService: ModelAttributeOptionService,
    private globalSetting: GlobalSettingService,
    private companyService: CompanyService,
    private _service: AssetService,
    private timeConvertService: TimeConvertService,
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.fullItem();
    this.getOptions();
    this.user = this.globalSetting.getConfig('user');
    this.getBuildings(this.globalSetting.config.useCompanyId);
  }

  fullItem() {
    this.itemForm.patchValue({
      building_id: _.get(this.data, 'buildingId', '')
    });
  }

  getBuildings(company_id) {
    let loader = this.loader.open();
    this.companyService
      .getBuildingsByClientId(company_id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.buildings = data;
      });
  }

  getOptions() {
    this.maoService.all().subscribe((data: ModelAttributeOptions) => {
      this.options = data.asset;
    });
  }

  submit() {
    let loader = this.loader.open();
    let itemFormValue = this.itemForm.value;
    itemFormValue['last_service_time'] = this.timeConvertService.convert(itemFormValue['last_service_time']);
    itemFormValue['next_service_time'] = this.timeConvertService.convert(itemFormValue['next_service_time']);
    this._service
      .store({ ...itemFormValue, data: this.dataForm.value })
      .finally(() => loader.close())
      .subscribe(() => {
        this.onSubmitted.emit();
        this.toast.show('Asset Added!');
      });
  }
}
