import { Injectable } from '@angular/core';
import { ApiBaseService } from '@shared/services/api/api-base.service';
import { Conditions } from '@shared/models/conditions.model';
import { TableConfigModel } from '@shared/models/table-config.model';
import { tableConfigs } from '@shared/config';

@Injectable()
export class InvoiceService extends ApiBaseService {
  route_prefix = 'invoices';
  tableConfig: TableConfigModel = tableConfigs.invoice;

  public conditions: Conditions = {
    status: {
      value: '',
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Status'
    },
    type: {
      value: '',
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Type'
    }
  };

  getQuotationItems(id) {
    return this.http.get(this.route_prefix + '/quotations' + '/' + id);
  }

  getPurchaseOrderItems(id) {
    return this.http.get(this.route_prefix + '/purchase-orders' + '/' + id);
  }

  getQuotations({ page, pre_page, keyword }) {
    return this.http.get(this.route_prefix + '/quotations', { params: { page, pre_page, keyword } });
  }

  getPurchaseOrders({ page, pre_page, keyword }) {
    return this.http.get(this.route_prefix + '/purchase-orders', { params: { page, pre_page, keyword } });
  }

  cancel(id) {
    return this.http.post(this.route_prefix + '/' + id + '/cancel', {});
  }

  pay(id) {
    return this.http.post(this.route_prefix + '/' + id + '/pay', {});
  }
}
