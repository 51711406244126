import { ConfirmWithNoteService } from './system/confirm-with-note/confirm-with-note.service';
import { TimesheetService } from '@shared/services/api/timesheet.service';
import { ThemeService } from '@shared/services/system/theme.service';
import { LayoutService } from '@shared/services/system/layout.service';
import { NavigationService } from '@shared/services/system/navigation.service';
import { RoutePartsService } from '@shared/services/system/route-parts.service';
import { AuthGuard } from '@shared/services/system/auth/auth.guard';
import { AppConfirmService } from '@shared/services/system/app-confirm/app-confirm.service';
import { AppLoaderService } from '@shared/services/system/app-loader/app-loader.service';
import { MessageService } from '@shared/services/system/message.service';
import { GlobalSettingService } from '@shared/services/system/global-setting.service';
import { ApiBaseService } from '@shared/services/api/api-base.service';
import { BuildingService } from '@shared/services/api/building.service';
import { CompanyService } from '@shared/services/api/company.service';
import { InvoiceService } from '@shared/services/api/invoice.service';
import { InvoiceItemService } from '@shared/services/api/invoice-item.service';
import { PublicService } from '@shared/services/api/public.service';
import { PurchaseOrderService } from '@shared/services/api/purchase-order.service';
import { ServiceService } from '@shared/services/api/service.service';
import { AuthService } from '@shared/services/api/auth.service';
import { UserService } from '@shared/services/api/user.service';
import { DashboardService } from '@shared/services/api/dashboard.service';
import { QuotationItemService } from '@shared/services/api/quotation-item.service';
import { ApplicationService } from '@shared/services/api/application.service';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { PurchaseOrderItemService } from '@shared/services/api/purchase-order-item.service';
import { QuotationService } from '@shared/services/api/quotation.service';
import { DocumentService } from '@shared/services/api/document.service';
import { AssetService } from '@shared/services/api/asset.service';
import { ServiceVisitService } from '@shared/services/api/service-visit.service';
import { PopupService } from '@shared/services/popup.service';
import { NoteService } from '@shared/services/api/note.service';
import { AppSelectCompanyService } from '@shared/services/system/app-select-company/app-select-company.service';
import { SalaryTimeService } from '@shared/services/api/salary-time.service';
import { ChangeTimesheetTimeService } from '@shared/services/system/change-timesheet-time/change-timesheet-time.service';
import { SettingService } from '@shared/services/api/setting.service';
import { PpmService } from './api/ppm.service';
import { TimesheetVisitDetailService } from './system/timesheet-visit-detail/timesheet-visit-detail.service';
import { RoleService } from './role.service';
import {ServiceCustomizeService} from '@shared/components/common/service-customize.service';
import {ServiceExtraService} from '@shared/components/common/service-extra.service';
import {NotifyService} from '@shared/services/notify.service';
import {CacheService} from '@shared/services/cache.service';
import {TimeConvertService} from '@shared/services/time-convert.service';

export const services = [
  TimesheetService,
  ThemeService,
  LayoutService,
  NavigationService,
  RoutePartsService,
  AuthGuard,
  AppConfirmService,
  AppLoaderService,
  MessageService,
  GlobalSettingService,
  ApiBaseService,
  BuildingService,
  CompanyService,
  InvoiceService,
  InvoiceItemService,
  PublicService,
  PurchaseOrderService,
  PurchaseOrderService,
  ServiceService,
  AuthService,
  UserService,
  DashboardService,
  ServiceCustomizeService,
  QuotationItemService,
  ApplicationService,
  ModelAttributeOptionService,
  PurchaseOrderItemService,
  QuotationService,
  DocumentService,
  AssetService,
  ServiceVisitService,
  PopupService,
  NoteService,
  AppSelectCompanyService,
  SalaryTimeService,
  ChangeTimesheetTimeService,
  SettingService,
  PpmService,
  ConfirmWithNoteService,
  TimesheetVisitDetailService,
  RoleService,
  ServiceExtraService,
  NotifyService,
  CacheService,
  TimeConvertService
];
