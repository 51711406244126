import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { egretAnimations } from '@shared/animations/egret-animations';
import { DashboardService } from '@shared/services/api/dashboard.service';
import { ThemeService } from '@shared/services/system/theme.service';
import { ServiceDashboardModel } from '@shared/models/service-dashboard.model';
import * as _ from 'lodash';
import { LayoutService } from '@shared/services/system/layout.service';
import { dashboardConfigs } from '@shared/config';

@Component({
  selector: 'app-service-dashboard',
  templateUrl: './service-dashboard.component.html',
  animations: egretAnimations
})
export class ServiceDashboardComponent implements OnInit, OnDestroy {
  _ = _;
  public loading = false;
  public dashboard: ServiceDashboardModel = new ServiceDashboardModel;
  public displays = dashboardConfigs.service;
  private refreshSubArr = [];
  public destroy = false;
  @Output() onClickDashboard = new EventEmitter();

  constructor(
    private dashboardService: DashboardService,
    public theme: ThemeService,
    private layout: LayoutService
  ) {
  }

  ngOnInit() {
    this.refresh();
    this.layout.onRefresh
      .takeWhile(() => !this.destroy)
      .subscribe(() => this.refresh());
  }

  refresh() {
    this.dashboard = new ServiceDashboardModel;
    if (this.refreshSubArr) {
      this.refreshSubArr.map((sub) => {
        sub.unsubscribe();
      });
    }
    this.loading = true;
    for (let group in this.displays) {
      if (group) {
        this.displays[group].items.map((item) => {
          let type = item.key;
          const refreshSub = this.dashboardService.getServiceDashboard(group, type)
            .takeWhile(() => !this.destroy)
            .finally(() => this.loading = false)
            .subscribe((data: number) => {
              this._.set(this.dashboard, [group, type], data);
            });
          this.refreshSubArr.push(refreshSub);
        });
      }
    }
  }


  openTable(dashboardGroup, dashboardType) {
    this.onClickDashboard.emit({ group: dashboardGroup, item: dashboardType });
  }

  ngOnDestroy() {
    this.destroy = true;
  }
}
