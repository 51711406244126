import { GlobalSettingService } from './../../../services/system/global-setting.service';
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { NavigationService } from '@shared/services/system/navigation.service';
import { ThemeService } from '@shared/services/system/theme.service';
import { Subscription } from 'rxjs';
import PerfectScrollbar from 'perfect-scrollbar';
import { AuthGuard } from '@app/shared/services/system/auth/auth.guard';
import { UserModel } from '@app/shared/models/user.model';

@Component({
  selector: 'app-sidebar-side',
  templateUrl: './sidebar-side.component.html'
})
export class SidebarSideComponent implements OnInit, OnDestroy, AfterViewInit {
  private sidebarPS: PerfectScrollbar;
  public menuItems: any[];
  public hasIconTypeMenuItem: boolean;
  public iconTypeMenuTitle: string;
  private menuItemsSub: Subscription;
  public user: UserModel = new UserModel();
  public settings: any;
  public companyLogo = 'assets/images/logo.png';

  constructor(
    private navService: NavigationService,
    public themeService: ThemeService,
    public authGuard: AuthGuard,
    public globalSetting: GlobalSettingService
  ) {
  }

  ngOnInit() {
    this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;
    this.menuItemsSub = this.navService.menuItems$.subscribe(menuItem => {
      this.menuItems = menuItem;
      // Checks item list has any icon statu.
      this.hasIconTypeMenuItem = !!this.menuItems.filter(item => item.type === 'icon').length;
    });
    this.user = this.globalSetting.getConfig('user');
    this.settings = this.globalSetting.getConfig('settings');
    if (this.user.company.data.hasOwnProperty('logo')) {
      this.companyLogo = this.user.company.data.logo;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.sidebarPS = new PerfectScrollbar('#scroll-area', {
        suppressScrollX: true
      });
    });
  }

  ngOnDestroy() {
    if (this.sidebarPS) {
      this.sidebarPS.destroy();
    }
    if (this.menuItemsSub) {
      this.menuItemsSub.unsubscribe();
    }
  }

  signout() {
    this.authGuard.signout();
  }

}
