import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { CompanyOptions, ModelAttributeOptions } from '@shared/models/options';
import { BaseCreateComponent } from '@shared/components/base/base-create.component';
import { InterfaceBaseCreate } from '@shared/interfaces/interface-base-create';
import { tableConfigs } from '@shared/config';
import { CompanyService } from '@shared/services/api/company.service';

@Component({
  selector: 'app-company-create',
  templateUrl: './company-create.component.html',
  styleUrls: ['./company-create.component.scss']
})
export class CompanyCreateComponent extends BaseCreateComponent
  implements OnInit, InterfaceBaseCreate {
  tableConfig = tableConfigs.company;
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();
  public itemForm: FormGroup;
  public companyOption: CompanyOptions;

  constructor(
    private fb: FormBuilder,
    private maoService: ModelAttributeOptionService,
    protected injector: Injector,
    public _service: CompanyService
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.getOptions();
    this.buildItemForm();
  }

  buildItemForm() {
    this.itemForm = this.fb.group({
      type: [[], [Validators.required]],
      industry: [''],
      name: ['', Validators.required],
      alias: [''],
      address1: [''],
      address2: [''],
      town: [''],
      country: [''],
      city: [''],
      postcode: [''],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      status: ['', Validators.required]
    });
  }

  getOptions() {
    this.maoService.all().subscribe((data: ModelAttributeOptions) => {
      this.companyOption = data.company;
    });
  }

  submit() {
    let loader = this.loader.open();
    this._service
      .store(this.itemForm.value)
      .finally(() => loader.close())
      .subscribe(() => {
        this.onSubmitted.emit();
        this.toast.show('Company Added!');
      });
  }

  gotoTable() {
    this.router.navigate(['/settings/company']);
  }
}
