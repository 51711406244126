import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

import 'rxjs-compat/add/operator/startWith';
import 'rxjs-compat/add/operator/debounceTime';
import 'rxjs-compat/add/operator/distinctUntilChanged';
import 'rxjs-compat/add/operator/switchMap';

@Component({
  selector: 'app-document-create-popup',
  template: `
    <app-note-create [isPopup]="true" [id]="id" [type]="type"></app-note-create>`
})
export class DocumentCreatePopupComponent implements OnInit {
  public id;
  public type;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: number, type: string },
    public dialogRef: MatDialogRef<DocumentCreatePopupComponent>,
  ) {
    if (this.data.id) {
      this.id = data.id;
      this.type = data.type;
    }
  }

  ngOnInit() {
  }
}
