import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';

@Injectable()
export class ServiceCustomizeService {
  customizes = [];

  excludeKeys = [];

  public customizeFormGroup: FormGroup = new FormGroup({
    key: new FormControl('', Validators.required),
    value: new FormControl('', Validators.required),
  });

  constructor(
    private fb: FormBuilder,
    public mao: ModelAttributeOptionService
  ) {
    if (this.excludeKeys.length === 0) {
      this.mao.serviceSystemDataKeys()
        .subscribe((data: any) => {
          this.excludeKeys = data;
        });
    }
  }

  add(key: string, value: string) {
    this.customizes.unshift(this.fb.group({
      key: [key],
      value: [value]
    }));
  }

  remove(index) {
    this.customizes.splice(index, 1);
  }

  clean() {
    this.customizes = [];
    this.customizeFormGroup.reset();
  }

  allControl() {
    return this.customizes;
  }

  allObject() {
    let customizeDatas = {};
    for (let item of this.customizes) {
      customizeDatas[item.value.key] = item.value.value;
    }
    return customizeDatas;
  }

  setByObject(data: Object) {
    this.clean();
    for (let key in data) {
      if (this.excludeKeys.indexOf(key) === -1) {
        this.add(key, data[key]);
      }
    }
  }

}
