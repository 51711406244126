import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppLoaderService } from '@shared/services/system/app-loader/app-loader.service';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { ModelAttributeOptions, ServiceVisitOptions } from '@shared/models/options';
import { ServiceVisitService } from '@shared/services/api/service-visit.service';
import { ServiceService } from '@shared/services/api/service.service';
import { UserModel } from '@shared/models/user.model';
import {NotifyService} from '@shared/services/notify.service';
import {TimeConvertService} from '@shared/services/time-convert.service';

@Component({
  selector: 'app-service-visit-create',
  templateUrl: './service-visit-create.component.html'
})
export class ServiceVisitCreateComponent implements OnInit {
  @Input() id;  // type_id
  @Input() isPopup = false;
  @Input() rateType = 'hourly_rate';
  @Output() onCreated = new EventEmitter();

  public serviceVisitOptions: ServiceVisitOptions;
  public engineers: UserModel[] = [];

  public itemForm: FormGroup = new FormGroup({
    user_id: new FormControl('', Validators.required),
    check_in_time: new FormControl('', Validators.required),
    check_out_time: new FormControl('', Validators.required),
    service_status: new FormControl('', Validators.required),
    note: new FormControl(''),
    observer: new FormControl(''),
    rate_type: new FormControl(''),
    check_in_latitude: new FormControl(''),
    check_out_latitude: new FormControl(''),
    check_in_longitude: new FormControl(''),
    check_out_longitude: new FormControl(''),
  });

  constructor(
    private _service: ServiceVisitService,
    private serviceService: ServiceService,
    private loader: AppLoaderService,
    private toast: NotifyService,
    private mao: ModelAttributeOptionService,
    private timeConvertService: TimeConvertService
  ) {
  }

  ngOnInit() {
    this.getServiceEngineers();
    this.mao.all()
      .subscribe((data: ModelAttributeOptions) => {
        this.serviceVisitOptions = data.service_visit;
      });
    this.itemForm.patchValue({ rate_type: this.rateType });
  }

  getServiceEngineers() {
    let loader = this.loader.open();
    this.serviceService.engineers(this.id)
      .finally(() => loader.close())
      .subscribe((data: UserModel[]) => {
        this.engineers = data;
      });
  }

  submit() {
    let itemFormValue = this.itemForm.value;
    itemFormValue['check_in_time'] = this.timeConvertService.convert(itemFormValue['check_in_time']);
    itemFormValue['check_out_time'] = this.timeConvertService.convert(itemFormValue['check_out_time']);
    let data = { ...itemFormValue, service_id: this.id };
    let loader = this.loader.open();
    this._service.store(data)
      .finally(() => loader.close())
      .subscribe(() => {
        this.toast.show('Visit created!');
        this.onCreated.emit(true);
      });
  }
}
