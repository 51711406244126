export class NoteModel {
  id: number;
  type: string;
  type_id: number;
  title: string;
  note: string;
  source_user_id: number;
  source_user_name: string;
  target_user_id: number;
  target_company_id: number;
  target_user_name: string;
  target_company_name: string;
  severity: string;
  data: {};
  created_at: string;
  can_delete: boolean;
  can_edit: boolean;
}
