import { Injectable } from '@angular/core';
import { ApiBaseService } from '@shared/services/api/api-base.service';
import { Conditions } from '@shared/models/conditions.model';
import { TableConfigModel } from '@shared/models/table-config.model';
import { tableConfigs } from '@shared/config';

@Injectable()
export class CompanyService extends ApiBaseService {
  route_prefix = 'companies';
  tableConfig: TableConfigModel = tableConfigs.company;

  public conditions: Conditions = {
    status: {
      value: '',
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Status'
    },
  };

  supplierCompaniesBelongFm() {
    return this.http.get(`${this.route_prefix}/fm-supplier-companies`);
  }

  contractorsWithRelation(serviceId) {
    return this.http.get(`${this.route_prefix}/contractors-with-relation/${serviceId}`);
  }

  clientCompaniesBelongFm() {
    return this.http.get(`${this.route_prefix}/fm-client-companies`);
  }

  companiesByType(type) {
    return this.http.get(`${this.route_prefix}/type/${type}`);
  }

  getBuildingsByClientId(client_id) {
    return this.http.post(`companies/${client_id}/buildings`, { company_id: client_id });
  }

  indexAll() {
    return this.http.get(`${this.route_prefix}/index-all`);
  }

  self() {
    return this.http.get('companies/self');
  }

  users(companyId) {
    return this.http.get(`companies/${companyId}/users`);
  }
}
