import {
  Component,
  OnInit,
  Inject,
  EventEmitter,
  Output,
  Injector
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  InvoiceItemOptions,
  ModelAttributeOptions
} from '@shared/models/options';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { InvoiceItemModel } from '@shared/models/invoice-item.model';
import { BaseEditComponent } from '@shared/components/base/base-edit.component';
import { tableConfigs } from '@shared/config';
import { InterfaceBaseEdit } from '@shared/interfaces/interface-base-edit';
import { InvoiceItemService } from '@shared/services/api/invoice-item.service';

@Component({
  selector: 'app-invoice-item-edit-popup',
  templateUrl: './invoice-item-edit.component.html'
})
export class InvoiceItemEditPopupComponent extends BaseEditComponent
  implements OnInit, InterfaceBaseEdit {
  tableConfig = tableConfigs.invoice_item;
  public id;
  public itemForm: FormGroup;
  public lineTotal = 0;
  public item: InvoiceItemModel;
  public options: InvoiceItemOptions;
  @Output() onUpdated = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<InvoiceItemEditPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public maoService: ModelAttributeOptionService,
    public injector: Injector,
    public _service: InvoiceItemService
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.id = this.data.id;
    this.buildItemForm({});
    this.show();
    this.getOptions();
  }

  buildItemForm(item) {
    this.itemForm = this.fb.group({
      item_name: [item.item_name || '', Validators.required],
      item_description: [item.item_description || '', Validators.required],
      item_unit_price: [item.item_unit_price || 0, Validators.required],
      item_quantity: [item.item_quantity || 0, Validators.required],
      item_status: [item.item_status || '', Validators.required]
    });
    this.itemForm
      .get('item_unit_price')
      .valueChanges.subscribe(item_unit_price => {
        let quantity = this.itemForm.value.item_quantity || 0;
        let price = item_unit_price || 0;
        this.lineTotal = quantity * price;
      });
    this.itemForm.get('item_quantity').valueChanges.subscribe(item_quantity => {
      let q = item_quantity || 0;
      let price = this.itemForm.value.item_unit_price || 0;
      this.lineTotal = q * price;
    });
    this.lineTotal =
      this.itemForm.value.item_quantity * this.itemForm.value.item_unit_price;
  }

  getOptions() {
    this.maoService.all().subscribe((data: ModelAttributeOptions) => {
      this.options = data.invoice_item;
    });
  }

  show() {
    let loader = this.loader.open();
    this._service
      .show(this.id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.item = data;
        this.buildItemForm(this.item);
      });
  }

  submit() {
    let data = { ...this.itemForm.value };
    let loader = this.loader.open();
    this._service
      .update(this.id, data)
      .finally(() => loader.close())
      .subscribe(() => {
        this.toast.show('Invoice item updated!');
        this.onUpdated.emit();
        this.dialogRef.close(true);
      });
  }
}
