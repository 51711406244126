import { QuotationClientReviewCreate } from './components/business/quotation/quotation-client-review-create.component';
import { PpmListComponent } from './components/business/ppm/ppm-list/ppm-list.component';
import { PpmDashboardComponent } from './components/business/ppm/ppm-dashboard/ppm-dashboard.component';
import { PpmCreateComponent } from '@shared/components/business/ppm/ppm-create/ppm-create.component';
import { SearchSelectComponent } from './components/system/search-select/search-select.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import {
  MatSidenavModule,
  MatListModule,
  MatTooltipModule,
  MatOptionModule,
  MatSelectModule,
  MatMenuModule,
  MatSnackBarModule,
  MatGridListModule,
  MatToolbarModule,
  MatIconModule,
  MatButtonModule,
  MatRadioModule,
  MatCheckboxModule,
  MatCardModule,
  MatProgressSpinnerModule,
  MatRippleModule,
  MatDialogModule,
  MatAutocompleteModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatInputModule,
  MatTableModule,
  MatPaginatorModule,
  MatChipsModule, MatStepperModule, MatTabsModule, MatProgressBarModule, MatSlideToggleModule, MatExpansionModule
} from '@angular/material';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

// ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT
import { HeaderSideComponent } from './components/system/header-side/header-side.component';
import { SidebarSideComponent } from './components/system/sidebar-side/sidebar-side.component';

// ONLY REQUIRED FOR **TOP** NAVIGATION LAYOUT
import { HeaderTopComponent } from './components/system/header-top/header-top.component';
import { SidebarTopComponent } from './components/system/sidebar-top/sidebar-top.component';

// ONLY FOR DEMO (Removable without changing any layout configuration)
import { CustomizerComponent } from './components/system/customizer/customizer.component';

// ALL TIME REQUIRED
import { AdminLayoutComponent } from './components/system/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './components/system/layouts/auth-layout/auth-layout.component';
import { NotificationsComponent } from './components/system/notifications/notifications.component';
import { SidenavComponent } from './components/system/sidenav/sidenav.component';
import { BreadcrumbComponent } from './components/system/breadcrumb/breadcrumb.component';
import { AppComfirmComponent } from './services/system/app-confirm/app-confirm.component';
import { AppLoaderComponent } from './services/system/app-loader/app-loader.component';

// DIRECTIVES
import { FontSizeDirective } from './directives/font-size.directive';
import { ScrollToDirective } from './directives/scroll-to.directive';
import { AppDropdownDirective } from './directives/dropdown.directive';
import { DropdownAnchorDirective } from './directives/dropdown-anchor.directive';
import { DropdownLinkDirective } from './directives/dropdown-link.directive';
import { EgretSideNavToggleDirective } from './directives/egret-side-nav-toggle.directive';

// PIPES
import { RelativeTimePipe } from './pipes/relative-time.pipe';
import { ExcerptPipe } from './pipes/excerpt.pipe';

// SERVICES
import { GlobalSettingComponent } from '@shared/components/system/global-setting/global-setting.component';
import { AppTableComponent } from '@shared/components/system/table/table.component';
import { BaseTableComponent } from '@shared/components/base/base-table.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {
  Md2AutocompleteModule,
  Md2DatepickerModule,
  Md2ToastModule,
  MdNativeDateModule,
  NoConflictStyleCompatibilityMode
} from 'md2';
import { AppTableConditionComponent } from '@shared/components/system/table/condition/condition.component';
import { ServiceCreateComponent } from '@app/shared/components/business/service/service-create/service-create.component';
import { ServiceEditComponent } from '@shared/components/business/service/service-edit/service-edit.component';
import { PageCardHeaderComponent } from '@shared/components/system/page-card-header/page-card-header.component';
import { InvoiceEditComponent } from '@shared/components/business/invoice/invoice-edit/invoice-edit.component';
import { PurchaseOrderCreateComponent } from '@shared/components/business/purchase-order/purchase-order-create/purchase-order-create.component';
import { PurchaseOrderEditComponent } from '@shared/components/business/purchase-order/purchase-order-edit/purchase-order-edit.component';
import { QuotationCreateComponent } from '@shared/components/business/quotation/quotation-create/quotation-create.component';
import { QuotationEditComponent } from '@shared/components/business/quotation/quotation-edit/quotation-edit.component';
import { BuildingCreateComponent } from '@shared/components/business/building/building-create/building-create.component';
import { BuildingEditComponent } from '@shared/components/business/building/building-edit/building-edit.component';
import { CompanyCreateComponent } from '@shared/components/business/company/company-create/company-create.component';
import { CompanyEditComponent } from '@shared/components/business/company/company-edit/company-edit.component';
import { BaseCreateComponent } from '@shared/components/base/base-create.component';
import { QuotationItemCreatePopupComponent } from '@shared/components/business/quotation/quotation-item-create/quotation-item-create-popup.component';
import { ServiceEditPopupComponent } from '@shared/components/business/service/service-edit/service-edit-popup.component';
import { PurchaseOrderItemCreatePopupComponent } from '@shared/components/business/purchase-order/purchase-order-item-create/purchase-order-item-create-popup.component';
import { ApplicationEditComponent } from '@shared/components/business/application/application-edit/application-edit.component';
import { AppServiceCertificateComponent } from '@shared/components/business/app-service-certificate/app-service-certificate.component';
import { FileUploadModule } from 'ng2-file-upload';
import { ServiceCertificateVerifyComponent } from '@shared/components/business/service-certificate-verify/service-certificate-verify.component';
import { ServiceCertificateVerifyPopupComponent } from '@shared/components/business/service-certificate-verify/service-certificate-verify-popup.component';
import { ServiceDashboardComponent } from '@shared/components/business/service/service-dashboard/service-dashboard.component';
import { ServiceTableComponent } from '@shared/components/business/service/service-table/service-table.component';
import { ServiceTablePopupComponent } from '@shared/components/business/service/service-table/service-table-popup.component';
import { InvoiceItemCreatePopupComponent } from '@shared/components/business/invoice/invoice-item-create/invoice-item-create-popup.component';
import { ServiceVisitTableComponent } from '@shared/components/business/service-visit/service-visit-table/service-visit-table.component';
import { ServiceVisitEditPopupComponent } from '@shared/components/business/service-visit/service-visit-edit/service-visit-edit-popup.component';
import { NoteEditPopupComponent } from '@shared/components/business/note/note-edit/note-edit-popup.component';
import { NoteCreatePopupComponent } from '@shared/components/business/note/note-create/note-create-popup.component';
import { NoteTableComponent } from '@shared/components/business/note/note-table/note-table.component';
import { NoteCreateComponent } from '@shared/components/business/note/note-create/note-create.component';
import { DocumentTableComponent } from '@shared/components/business/document/document-table/document-table.component';
import { DocumentEditPopupComponent } from '@shared/components/business/document/document-edit/document-edit-popup.component';
import { DocumentCreateComponent } from '@shared/components/business/document/document-create/document-create.component';
import { DocumentCreatePopupComponent } from '@shared/components/business/document/document-create/document-create-popup.component';
import { InvoiceItemEditPopupComponent } from '@shared/components/business/invoice/invoice-item-edit/invoice-item-edit-popup.component';
import { PurchaseOrderItemEditPopupComponent } from '@shared/components/business/purchase-order/purchase-order-item-edit/purchase-order-item-edit-popup.component';
import { QuotationItemEditPopupComponent } from '@shared/components/business/quotation/quotation-item-edit/quotation-item-edit-popup.component';
import { AssetCreateComponent } from '@shared/components/business/asset/asset-create/asset-create.component';
import { AssetEditComponent } from '@shared/components/business/asset/asset-edit/asset-edit.component';
import { AssetTableComponent } from '@shared/components/business/asset/asset-table/asset-table.component';
import { AssetEditPopupComponent } from '@shared/components/business/asset/asset-edit/asset-edit-popup.component';
import { ProgressComponent } from '@shared/components/system/progress/progress.component';
import { BaseEditComponent } from '@shared/components/base/base-edit.component';
import { AppSelectCompanyComponent } from '@shared/services/system/app-select-company/app-select-company.component';
import { QuotationDashboardComponent } from '@shared/components/business/quotation/quotation-dashboard/quotation-dashboard.component';
import { QuotationTableComponent } from '@shared/components/business/quotation/quotation-table/quotation-table.component';
import { QuotationTablePopupComponent } from '@shared/components/business/quotation/quotation-table/quotation-table-popup.component';
import { AssetCreatePopupComponent } from '@shared/components/business/asset/asset-create/asset-create-popup.component';
import { ChangeTimesheetTimeComponent } from '@shared/services/system/change-timesheet-time/change-timesheet-time.component';
import { StrCheckboxComponent } from '@shared/components/system/str-checkbox/str-checkbox.component';
import { BuildingEditPopupComponent } from '@shared/components/business/building/building-edit/building-edit-popup.component';
import { ServiceVisitCreatePopupComponent } from '@shared/components/business/service-visit/service-visit-create/service-visit-create-popup.component';
import { ServiceVisitCreateComponent } from '@shared/components/business/service-visit/service-visit-create/service-visit-create.component';
import { PurchaseOrderEditPopupComponent } from '@shared/components/business/purchase-order/purchase-order-edit/purchase-order-edit-popup.component';
import { QuotationEditPopupComponent } from '@shared/components/business/quotation/quotation-edit/quotation-edit-popup.component';
import { QuotationCreatePopupComponent } from '@shared/components/business/quotation/quotation-create/quotation-create-popup.component';
import { PurchaseOrderCreatePopupComponent } from '@shared/components/business/purchase-order/purchase-order-create/purchase-order-create-popup.component';
import { HtmlPipe } from '@shared/pipes/html.pipe';
import { TableFilterComponent } from './components/system/table-filter/table-filter.component';
import { UpliftComponent } from './components/business/uplift/uplift.component';
import { ConfirmWithNoteComponent } from './services/system/confirm-with-note/confirm-with-note.component';
import { TimesheetVisitDetailComponent } from './services/system/timesheet-visit-detail/timesheet-visit-detail.component';
import {ServiceCustomizeDataCreateComponent} from '@shared/components/common/service-customize-data-create/service-customize-data-create.component';
import {ServiceCustomizeDataEditComponent} from '@shared/components/common/service-customize-data-edit/service-customize-data-edit.component';
import {ServiceExtraCreateComponent} from '@shared/components/common/service-extra-create/service-extra-create.component';
import {ServiceExtraEditComponent} from '@shared/components/common/service-extra-edit/service-extra-edit.component';
import {BuildingDetailCardComponent} from '@shared/components/common/building-detail-card/building-detail-card.component';
import {BuildingNotesCardComponent} from '@shared/components/common/building-notes-card/building-notes-card.component';
import {NgSelectModule} from '@ng-select/ng-select';
// import {Logger} from './services/logger.service';
/*
  Only Required if you want to use Angular Landing
  (https://themeforest.net/item/angular-landing-material-design-angular-app-landing-page/21198258)
*/
// import { LandingPageService } from '../shared/services/landing-page.service';

const classesToInclude = [
  HeaderTopComponent,
  SidebarTopComponent,
  SidenavComponent,
  NotificationsComponent,
  SidebarSideComponent,
  HeaderSideComponent,
  AdminLayoutComponent,
  AuthLayoutComponent,
  BreadcrumbComponent,
  CustomizerComponent,
  FontSizeDirective,
  ScrollToDirective,
  AppDropdownDirective,
  DropdownAnchorDirective,
  DropdownLinkDirective,
  EgretSideNavToggleDirective,
  RelativeTimePipe,
  ExcerptPipe,
  HtmlPipe,
  GlobalSettingComponent,
  AppTableComponent,
  BaseTableComponent,
  AppTableConditionComponent,
  ServiceCreateComponent,
  ServiceEditComponent,
  PageCardHeaderComponent,
  InvoiceEditComponent,
  PurchaseOrderCreateComponent,
  PurchaseOrderEditComponent,
  QuotationCreateComponent,
  QuotationEditComponent,
  BuildingCreateComponent,
  BuildingEditComponent,
  CompanyCreateComponent,
  CompanyEditComponent,
  BaseCreateComponent,
  BaseEditComponent,
  ServiceEditPopupComponent,
  ApplicationEditComponent,
  AppServiceCertificateComponent,
  ServiceCertificateVerifyComponent,
  ServiceDashboardComponent,
  ServiceTableComponent,
  ServiceVisitTableComponent,
  NoteTableComponent,
  NoteCreateComponent,
  DocumentTableComponent,
  DocumentCreateComponent,
  AssetCreateComponent,
  AssetEditComponent,
  AssetTableComponent,
  ProgressComponent,
  QuotationDashboardComponent,
  QuotationTableComponent,
  StrCheckboxComponent,
  ServiceVisitCreateComponent,
  TableFilterComponent,
  UpliftComponent,
  SearchSelectComponent,
  PpmDashboardComponent,
  PpmListComponent,
  ServiceCustomizeDataCreateComponent,
  ServiceCustomizeDataEditComponent,
  ServiceExtraCreateComponent,
  ServiceExtraEditComponent,
  BuildingDetailCardComponent,
  BuildingNotesCardComponent
];

// dialog entryComponents
const popups = [
  AppComfirmComponent,
  AppLoaderComponent,
  QuotationItemCreatePopupComponent,
  ServiceEditPopupComponent,
  PurchaseOrderItemCreatePopupComponent,
  ServiceCertificateVerifyPopupComponent,
  ServiceTablePopupComponent,
  InvoiceItemCreatePopupComponent,
  ServiceVisitEditPopupComponent,
  NoteEditPopupComponent,
  NoteCreatePopupComponent,
  DocumentCreatePopupComponent,
  DocumentEditPopupComponent,
  InvoiceItemEditPopupComponent,
  PurchaseOrderItemEditPopupComponent,
  QuotationItemEditPopupComponent,
  AssetEditPopupComponent,
  AppSelectCompanyComponent,
  QuotationTablePopupComponent,
  AssetCreatePopupComponent,
  ChangeTimesheetTimeComponent,
  BuildingEditPopupComponent,
  ServiceVisitCreatePopupComponent,
  PurchaseOrderEditPopupComponent,
  QuotationEditPopupComponent,
  QuotationCreatePopupComponent,
  PurchaseOrderCreatePopupComponent,
  PpmCreateComponent,
  QuotationClientReviewCreate,
  ConfirmWithNoteComponent,
  TimesheetVisitDetailComponent
];

const imports = [
  CommonModule,
  FormsModule,
  RouterModule,
  FlexLayoutModule,
  TranslateModule,
  MatSidenavModule,
  MatListModule,
  MatTooltipModule,
  MatOptionModule,
  MatSelectModule,
  MatMenuModule,
  MatSnackBarModule,
  MatGridListModule,
  MatToolbarModule,
  MatIconModule,
  MatButtonModule,
  MatRadioModule,
  MatCheckboxModule,
  MatCardModule,
  MatProgressSpinnerModule,
  MatRippleModule,
  MatDialogModule,
  MatAutocompleteModule,
  ReactiveFormsModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatInputModule,
  MatTableModule,
  MatPaginatorModule,
  NgxDatatableModule,
  MatChipsModule,
  MatStepperModule,
  MatTabsModule,
  Md2DatepickerModule,
  MdNativeDateModule,
  MatProgressBarModule,
  Md2AutocompleteModule,
  FileUploadModule,
  MatSlideToggleModule,
  Md2ToastModule,
  NoConflictStyleCompatibilityMode,
  MatExpansionModule,
  NgxMatSelectSearchModule,
  NgSelectModule
];

@NgModule({
  imports: imports,
  entryComponents: popups,
  providers: [
    // Logger,
    // LandingPageService
  ],
  declarations: [
    ...classesToInclude,
    ...popups
  ],
  exports: [
    ...classesToInclude,
    ...imports
  ]
})
export class SharedModule {
}
