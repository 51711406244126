import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InvoiceItemService } from '@shared/services/api/invoice-item.service';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { InvoiceOptions } from '@shared/models/options';
import { CompanyService } from '@shared/services/api/company.service';
import { PopupService } from '@shared/services/popup.service';
import { BaseEditComponent } from '@shared/components/base/base-edit.component';
import { InterfaceBaseEdit } from '@shared/interfaces/interface-base-edit';
import { tableConfigs } from '@shared/config';
import { InvoiceService } from '@shared/services/api/invoice.service';
import { InvoiceModel } from '@app/shared/models/invoice.model';

@Component({
  selector: 'app-invoice-edit',
  templateUrl: './invoice-edit.component.html'
})
export class InvoiceEditComponent extends BaseEditComponent
  implements OnInit, InterfaceBaseEdit {
  tableConfig = tableConfigs.invoice;
  tableConfigs = tableConfigs;
  public itemForm: FormGroup;
  @Input() id;
  public item: InvoiceModel;
  public invoiceOptions: InvoiceOptions;
  public clients;
  public suppliers;

  constructor(
    private fb: FormBuilder,
    private itemService: InvoiceItemService,
    private companyService: CompanyService,
    private maoService: ModelAttributeOptionService,
    private popup: PopupService,
    public injector: Injector,
    public _service: InvoiceService
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.getSupplier();
    this.getClient();
    this.getOptions();
    this.show();
    this.buildItemForm({});
  }

  buildItemForm(item) {
    this.itemForm = this.fb.group({
      target_company_id: [item.target_company_id || ''],
      source_company_id: [item.source_company_id || ''],
      vat: [item.vat || 0, [Validators.min(0)]],
      status: [item.status || ''],
      subject: [item.subject || ''],
      description: [item.description || '']
    });
  }

  getClient() {
    this.companyService.supplierCompaniesBelongFm().subscribe((data: any) => {
      this.suppliers = data;
    });
  }

  getSupplier() {
    this.companyService.clientCompaniesBelongFm().subscribe((data: any) => {
      this.clients = data;
    });
  }

  getOptions() {
    this.maoService
      .all()
      .subscribe((data: any) => {
        this.invoiceOptions = data.invoice;
      });
  }

  show() {
    let loader = this.loader.open();
    this._service
      .show(this.id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.item = data;
        this.buildItemForm(data);
      });
  }

  submit() {
    let data = { ...this.itemForm.value };
    let loader = this.loader.open();
    this._service
      .update(this.id, data)
      .finally(() => {
        loader.close();
        this.show();
      })
      .subscribe(() => {
        this.toast.show('Invoice updated!');
      });
  }

  pay() {
    this.confirmService.confirm().subscribe(res => {
      if (res) {
        let loader = this.loader.open();
        this._service
          .pay(this.id)
          .finally(() => loader.close())
          .subscribe(() => {
            this.toast.show('Invoice pay success.');
            this.show();
          });
      }
    });
  }

  cancel() {
    this.confirmService
      .confirm({
        message:
          'After cancelling, you can generate certificate again through application.'
      })
      .subscribe(res => {
        if (res) {
          let loader = this.loader.open();
          this._service
            .cancel(this.id)
            .finally(() => loader.close())
            .subscribe(() => {
              this.toast.show('Invoice cancel success.');
              this.show();
            });
        }
      });
  }

  openInvoiceItemCreatePopup() {
    this.popup
      .openInvoiceItemCreatePage(this.id)
      .afterClosed()
      .subscribe(res => {
        if (!res) {
          return;
        }
        this.refresh();
      });
  }

  deleteItem(row) {
    this.confirmService
      .confirm({ message: `Delete ${row.name}?` })
      .subscribe(res => {
        if (res) {
          let loader = this.loader.open();
          this.itemService
            .destroy(row.id)
            .finally(() => loader.close())
            .subscribe(() => {
              this.refresh();
              this.toast.show('Invoice item deleted!');
            });
        }
      });
  }

  openEditItemPopup(row) {
    this.popup
      .openInvoiceItemEditPage(row.id)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.refresh();
        }
      });
  }

  refresh() {
    this.show();
  }

  openEditServicePopup(row) {
    this.popup
      .openServiceEditPage(row.id)
      .afterClosed()
      .subscribe(res => {
        if (!res) {
          return;
        }
        this.refresh();
      });
  }

  openEditApplication(row) {
    this.router.navigate(['/application/edit', row.id]);
  }
}
