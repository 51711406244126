import {
  Component,
  EventEmitter,
  Injector,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import { ModelAttributeOptions, ServiceOptions } from '@shared/models/options';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { AssetService } from '@shared/services/api/asset.service';
import { AssetModel } from '@shared/models/asset.model';
import { PopupService } from '@shared/services/popup.service';
import { BaseCreateComponent } from '@shared/components/base/base-create.component';
import { InterfaceBaseCreate } from '@shared/interfaces/interface-base-create';
import { tableConfigs } from '@shared/config';
import { ServiceService } from '@shared/services/api/service.service';
import { ServiceModel } from '@shared/models/service.model';
import * as _ from 'lodash';
import { BuildingModel } from '@shared/models/building.model';
import { BuildingService } from '@shared/services/api/building.service';
import { SimpleReuseStrategy } from '@app/simple-reuse-strategy';
import { isArray } from 'lodash';
import { addHours, addDays, addMonths } from 'date-fns';
import {UserModel} from '@shared/models/user.model';
import {GlobalSettingService} from '@shared/services/system/global-setting.service';
import {ServiceCustomizeService} from '@shared/components/common/service-customize.service';
import {ServiceExtraService} from '@shared/components/common/service-extra.service';
import {TimeConvertService} from '@shared/services/time-convert.service';

@Component({
  selector: 'app-service-create',
  templateUrl: './service-create.component.html',
  providers: [
    SimpleReuseStrategy,
    ServiceCustomizeService,
    ServiceExtraService
  ]
})
export class ServiceCreateComponent extends BaseCreateComponent
  implements OnInit, OnDestroy, InterfaceBaseCreate {
  tableConfig = tableConfigs.service;
  _ = _;

  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();
  public isPPM = false;

  public secondFormGroup: FormGroup;
  public formGroup: FormGroup;

  public rooms = [];
  public buildings = [];
  public user: UserModel = new UserModel();
  public serviceOptions: ServiceOptions;
  public assets: AssetModel[];
  public filterServices: ServiceModel[] = [];
  public filterServicesTemp: ServiceModel[] = [];
  public building: BuildingModel;
  loadingPreviousService = false;
  loadingBuildings = false;

  constructor(
    private fb: FormBuilder,
    public customizeService: ServiceCustomizeService,
    public globalSetting: GlobalSettingService,
    private maoService: ModelAttributeOptionService,
    private assetService: AssetService,
    public popup: PopupService,
    public injector: Injector,
    public _service: ServiceService,
    public buildingService: BuildingService,
    public extraService: ServiceExtraService,
    private timeConvertService: TimeConvertService,
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.buildItemForm();
    this.getOptions();
    this.user = this.globalSetting.getConfig('user');
    this.maoService.getServiceDataDefaultFormList().subscribe(data => {
      let form = {};
      let groupData = _.groupBy(data, 'data.group.value');
      for (const groupName in groupData) {
        if (groupData.hasOwnProperty(groupName)) {
          const groupItem = groupData[groupName];
          let itemForm = {};
          groupItem
            .sort((a, b) => {
              let aOrder = _.get(a, 'data.order', 0);
              let bOrder = _.get(b, 'data.order', 0);
              return aOrder - bOrder;
            })
            .map(item => {
              _.set(itemForm, item.option_value, new FormControl(''));
            });
          _.set(form, groupName, new FormGroup(itemForm));
        }
      }
    });
    this.getBuildings(this.globalSetting.config.useCompanyId);
  }

  ngOnDestroy(): void {
  }

  buildItemForm() {
    this.secondFormGroup = this.fb.group({
      previous_service_id: []
    });
    this.formGroup = this.fb.group({
      building_id: [null, Validators.required],
      subject: [''], // string
      problem: ['', Validators.required], // string
      service_type: ['', Validators.required], // option
      discipline: ['', Validators.required], // option
      severity: [''], // option
      status: ['pending'],
      asset_ids: [[]],
      client_reference: [''],
      room_id: [null],
      expect_start_time: new FormControl('', Validators.required),
    });
    this.formGroup
      .get('building_id')
      .valueChanges.subscribe(building_id => {
        if (building_id) {
          this.getAssets(building_id);
          this.getServicesByBuildingId(building_id);
          this.getBuilding(building_id);
        }
      });
    this.formGroup
      .get('service_type')
      .valueChanges.subscribe(service_type => {
        if (service_type === 'ppm') {
          this.isPPM = true;
          this.getAssets(this.formGroup.get('building_id').value);
        } else {
          this.isPPM = false;
          this.formGroup.removeControl('asset_ids');
          this.formGroup.addControl('asset_ids', new FormControl([]));
        }
      });
    this.formGroup.get('severity').valueChanges.subscribe((severity: string) => {
      let now = new Date();
      let retDate;
      if (severity.indexOf('hours') !== -1) {
        retDate = addHours(now, parseInt(severity.slice(0, severity.length - 6)));
      }
      if (severity.indexOf('days') !== -1) {
        retDate = addDays(now, parseInt(severity.slice(0, severity.length - 5)));
      }
      if (severity.indexOf('months') !== -1) {
        retDate = addMonths(now, parseInt(severity.slice(0, severity.length - 7)));
      }
      if (retDate) {
        this.formGroup.patchValue({
          expect_start_time: retDate.toISOString()
        });
      }
    });
  }

  getBuilding(buildingId) {
    let loader = this.loader.open();
    this.buildingService
      .show(buildingId)
      .finally(() => loader.close())
      .subscribe((data: BuildingModel) => {
        this.building = data;
        this.rooms = _.get(this.building, 'data.rooms', []);
      });
  }

  getAssets(building_id) {
    let loader = this.loader.open();
    this.assetService
      .byBuildingId(building_id)
      .finally(() => loader.close())
      .subscribe((data: [AssetModel]) => {
        this.assets = data;
      });
  }

  getServicesByBuildingId(buildingId) {
    this.loadingPreviousService = true;
    this._service
      .filterServicesByBuilding(buildingId)
      .finally(() => (this.loadingPreviousService = false))
      .subscribe((data: ServiceModel[]) => {
        this.filterServices = data;
        this.filterServicesTemp = data;
      });
  }

  getOptions() {
    this.maoService
      .all()
      .subscribe((data: ModelAttributeOptions) => {
        this.serviceOptions = data.service;
      });
  }

  submit() {
    this.confirmService.confirm().subscribe(res => {
      if (res) {
        let loader = this.loader.open();
        let formGroupValue = this.formGroup.value;
        formGroupValue['expect_start_time'] = this.timeConvertService.convert(formGroupValue['expect_start_time']);
        let postData = {
          ...this.secondFormGroup.value,
          ...formGroupValue,
          extra: this.extraService.all(),
          customize: this.customizeService.allObject()
        };
        this._service
          .store(postData)
          .finally(() => {
            loader.close();
          })
          .subscribe(() => {
            this.onSubmitted.emit();
            SimpleReuseStrategy.deleteRouteSnapshot('//service/create');
            this.extraService.clear();
            this.customizeService.clean();
            this.toast.show('Service Added!');
          });
      }
    });
  }

  getBuildings(client_id) {
    this.loadingBuildings = true;
    this._service
      .buildingsByClient(client_id)
      .finally(() => {
        this.loadingBuildings = false;
      })
      .subscribe((data: any) => {
        if (data && isArray(data)) {
          this.buildings = data.map(item => {
            item.disabled = item.status === 'inactive';
            return item;
          });
        }
      });
  }

  openEditServicePopup(serviceId) {
    this.popup
      .openServiceEditPage(serviceId)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.getServicesByBuildingId(this.formGroup.value.building_id);
        }
      });
  }

  recall(service: ServiceModel, stepper) {
    this.secondFormGroup.patchValue({
      previous_service_id: service.id
    });
    this.formGroup.patchValue({
      client_reference: _.get(service, 'data.client_reference'),
      supplier_company_ids: service.supplier_company_ids,
      subject: service.subject, // string
      problem: service.problem, // string
      service_type: service.service_type, // option
      discipline: service.discipline, // option
      severity: service.severity, // option
    });
    this.extraService.setFormGroup(_.get(service, 'data.extra', {}), { recall: true });
    this.customizeService.setByObject(_.get(service, 'data.customize', {}));

    let assets = _.get(service, 'data.assets', []);
    if (isArray(assets)) {
      this.formGroup.patchValue({
        asset_ids: assets.map(asset => asset.id)
      });
    }
    stepper.next();
  }

  warranty(service: ServiceModel, stepper) {
    this.secondFormGroup.patchValue({
      previous_service_id: service.id
    });
    this.formGroup.patchValue({
      client_reference: _.get(service, 'data.client_reference'),
      supplier_company_ids: service.supplier_company_ids,
      subject: service.subject, // string
      problem: service.problem, // string
      service_type: service.service_type, // option
      discipline: service.discipline, // option
      severity: service.severity, // option
    });
    this.extraService.setFormGroup(_.get(service, 'data.extra', {}), { warranty: true });
    this.customizeService.setByObject(_.get(service, 'data.customize'));

    let assets = _.get(service, 'data.assets', []);
    if (isArray(assets)) {
      this.formGroup.patchValue({
        asset_ids: assets.map(asset => asset.id)
      });
    }
    stepper.next();
  }

  filter($event) {
    const val = $event.toLowerCase();
    let temp = this.filterServicesTemp;
    if (val) {
      temp = this.filterServicesTemp.filter(item => {
        let isFind = false;
        [
          'id',
          'service_type',
          'discipline',
          'create_user_name',
          'status',
          'created_at'
        ].forEach(element => {
          if (
            item[element]
              .toString()
              .toLowerCase()
              .indexOf(val) !== -1
          ) {
            isFind = true;
          }

          if (
            item[element]
              .toString()
              .toLowerCase()
              .replace('_', ' ')
              .indexOf(val) !== -1
          ) {
            isFind = true;
          }
        });
        return isFind;
      });
    }
    this.filterServices = temp;
  }

  searchBuilding(term, item) {
    return item.code.toString().toLowerCase().indexOf(term.toString().toLowerCase()) >= 0 ||
      item.name.toString().toLowerCase().indexOf(term.toString().toLowerCase()) >= 0;
  }

  searchRoom(term, item) {
    return item.name.toString().toLowerCase().indexOf(term.toString().toLowerCase()) >= 0;
  }
}
