import { Component, OnInit, Input } from '@angular/core';
import { BuildingModel } from '@app/shared/models/building.model';

@Component({
  selector: 'app-building-detail-card',
  templateUrl: './building-detail-card.component.html',
  styleUrls: ['./building-detail-card.component.scss']
})
export class BuildingDetailCardComponent implements OnInit {

  @Input() building: BuildingModel;

  constructor() { }

  ngOnInit() {
  }

}
