import { QuotationModel } from '@shared/models/quotation.model';
import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { QuotationItemService } from '@shared/services/api/quotation-item.service';
import { QuotationOptions } from '@shared/models/options';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { CompanyService } from '@shared/services/api/company.service';
import { PopupService } from '@shared/services/popup.service';
import { BaseEditComponent } from '@shared/components/base/base-edit.component';
import { InterfaceBaseEdit } from '@shared/interfaces/interface-base-edit';
import { TableConfigModel } from '@shared/models/table-config.model';
import { ApplicationService } from '@shared/services/api/application.service';
import { tableConfigs } from '@shared/config';
import { get } from 'lodash';
import { ConfirmWithNoteService } from '@app/shared/services/system/confirm-with-note/confirm-with-note.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-application-edit',
  templateUrl: './application-edit.component.html'
})
export class ApplicationEditComponent extends BaseEditComponent
  implements OnInit, InterfaceBaseEdit {
  tableConfig: TableConfigModel = tableConfigs.application;
  tableConfigs = tableConfigs;
  showActions = false;

  public itemForm: FormGroup;
  public quotationOptions: QuotationOptions;

  @Input() id;
  public item: QuotationModel;

  public clients;
  public suppliers;

  constructor(
    private fb: FormBuilder,
    private quotationItemService: QuotationItemService,
    private maoService: ModelAttributeOptionService,
    private companyService: CompanyService,
    private popup: PopupService,
    public _service: ApplicationService,
    public injector: Injector,
    private confirmWithNote: ConfirmWithNoteService,
    public router: Router
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.id = this.route.snapshot.paramMap.get('id');
    this.getOptions();
    this.buildItemForm({});
    this.show();
    this.companyService.supplierCompaniesBelongFm().subscribe((data: any) => {
      this.suppliers = data;
    });
    this.companyService.clientCompaniesBelongFm().subscribe((data: any) => {
      this.clients = data;
    });
  }

  buildItemForm(item) {
    this.itemForm = this.fb.group({
      subject: [item.subject || ''],
      description: [item.description || ''],
      vat: [item.vat || 0],
      target_company_id: [item.target_company_id || '', Validators.required], // client company
      status: [item.status || '', Validators.required]
    });
  }

  getOptions() {
    this.maoService.all().subscribe((data: any) => {
      this.quotationOptions = data.quotation;
    });
  }

  show() {
    let loader = this.loader.open();
    this._service
      .show(this.id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.item = data;
        this.buildItemForm(data);
        this.checkShowActions(data);
      });
  }

  checkShowActions(item) {
    if (
      item.status === 'approved' &&
      get(item, 'data.next_action.invoice.action') === 'create'
    ) {
      this.showActions = true;
    } else {
      this.showActions = false;
    }
  }

  submit() {
    let data = { ...this.itemForm.value, id: this.id };
    let loader = this.loader.open();
    this._service
      .update(this.id, data)
      .finally(() => {
        loader.close();
        this.show();
      })
      .subscribe(() => {
        this.toast.show(`${this.tableConfig.displayName} updated!`);
      });
  }

  openEditServicePopUp(row) {
    this.popup
      .openServiceEditPage(row.id)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.refresh();
        }
      });
  }

  openQuotationItemCreatePopup() {
    this.popup
      .openQuotationItemCreatePage(this.id, [])
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.show();
        }
      });
  }

  deleteItem(row) {
    this.confirmService
      .confirm({ message: `Delete ${row.id}?` })
      .subscribe(res => {
        if (res) {
          let loader = this.loader.open();
          this.quotationItemService
            .destroy(row.id)
            .finally(() => loader.close())
            .subscribe(() => {
              this.refresh();
              this.toast.show(
                `${this.tableConfig.displayName} item ${row.id} deleted!`
              );
            });
        }
      });
  }

  openEditItemPopup(row) {
    this.popup
      .openQuotationItemEditPage(row.id)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.refresh();
        }
      });
  }

  approve() {
    this.confirmWithNote.show().subscribe(res => {
      if (res) {
        let loader = this.loader.open();
        this._service
          .approve(this.id, { ...res })
          .finally(() => loader.close())
          .subscribe(() => {
            this.toast.show('Approve success.');
            this.refresh();
          });
      }
    });
  }

  decline() {
    this.confirmWithNote.show().subscribe(res => {
      if (res) {
        let loader = this.loader.open();
        this._service
          .decline(this.id, { ...res })
          .finally(() => loader.close())
          .subscribe(() => {
            this.toast.show('Decline success.');
            this.refresh();
          });
      }
    });
  }

  generateInvoice() {
    this.confirmWithNote.show().subscribe(res => {
      if (res) {
        let loader = this.loader.open();
        this._service
          .generateInvoice(this.id, { ...res })
          .finally(() => loader.close())
          .subscribe(() => {
            this.toast.show('Generate certificate success.');
            this.refresh();
          });
      }
    });
  }
}
