import { Injectable } from '@angular/core';
import { ApiBaseService } from '@shared/services/api/api-base.service';
import { Conditions } from '@shared/models/conditions.model';
import { dashboardConfigs, tableConfigs } from '@shared/config';
import { TableConfigModel } from '@shared/models/table-config.model';

@Injectable()
export class QuotationService extends ApiBaseService {
  route_prefix = 'quotations';

  tableConfig: TableConfigModel = tableConfigs.quotation;

  public conditions: Conditions = {
    status: {
      value: '',
      nullable: true,
      items: [
        { option_value: 'pending' },
        { option_value: 'approved' },
        { option_value: 'declined' },
      ],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Status'
    },
    dashboard_type: {
      value: { item: '', group: '' },
      nullable: true,
      useGroup: true,
      groups: dashboardConfigs.quotation,
      group_key: 'dashboard_group',
      item_key: 'dashboard_type',
      display_name: 'Dashboard Type'
    }
  };

  approve(quotationId, body: { note: string; }) {
    return this.http.post(`${this.route_prefix}/${quotationId}/approve`, body);
  }

  reject(quotationId, body: { note: string; }) {
    return this.http.post(`${this.route_prefix}/${quotationId}/reject`, body);
  }

  generateService(quotationId, body) {
    return this.http.post(`${this.route_prefix}/${quotationId}/generate-service`, body);
  }

  clientReview(quotationId, { note, user_ids }) {
    return this.http.post(`${this.route_prefix}/${quotationId}/client-review`, { description: note, user_ids });
  }
}
