import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-service-certificate-verify-popup',
  template: `
    <app-service-certificate-verify [id]="id" [inPopup]="true" (onSubmitted)="dialogRef.close(true)"></app-service-certificate-verify>`
})
export class ServiceCertificateVerifyPopupComponent implements OnInit {
  public id;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: number },
    public dialogRef: MatDialogRef<ServiceCertificateVerifyPopupComponent>,
  ) {
    if (this.data.id) {
      this.id = data.id;
    }
  }

  ngOnInit() {
  }
}
