import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ServiceCustomizeService } from '../service-customize.service';

@Component({
  selector: 'app-service-customize-data-edit',
  templateUrl: './service-customize-data-edit.component.html',
  styleUrls: ['./service-customize-data-edit.component.scss']
})
export class ServiceCustomizeDataEditComponent implements OnInit {
  public customizeFormGroup: FormGroup;

  constructor(
    public customizeService: ServiceCustomizeService
  ) {
    this.customizeFormGroup = customizeService.customizeFormGroup;
  }

  ngOnInit() {
  }

  addCustomize() {
    this.customizeService.add(
      this.customizeFormGroup.value.key,
      this.customizeFormGroup.value.value
    );
    this.customizeFormGroup.reset();
  }

  removeCustomize(index) {
    this.customizeService.remove(index);
  }

}
