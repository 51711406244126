import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseEditComponent } from '@shared/components/base/base-edit.component';
import { tableConfigs } from '@shared/config';
import { CompanyService } from '@shared/services/api/company.service';
import { InterfaceBaseEdit } from '@shared/interfaces/interface-base-edit';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { CompanyOptions, ModelAttributeOptions } from '@shared/models/options';

@Component({
  selector: 'app-company-edit',
  templateUrl: './company-edit.component.html',
  styleUrls: ['./company-edit.component.scss']
})
export class CompanyEditComponent extends BaseEditComponent
  implements OnInit, InterfaceBaseEdit {
  tableConfig = tableConfigs.company;
  @Input() id;

  public itemForm: FormGroup;
  public rateForm: FormGroup;
  public item;
  public options: CompanyOptions;
  public clients;

  constructor(
    private fb: FormBuilder,
    public injector: Injector,
    public _service: CompanyService,
    public mao: ModelAttributeOptionService
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.getOptions();
    this.getClients();
    this.buildItemForm({});
    this.show();
  }

  buildItemForm(item) {
    this.itemForm = this.fb.group({
      type: [item.types_show || [], [Validators.required]],
      industry: [item.industry || ''],
      name: [item.name || '', Validators.required],
      alias: [item.alias || ''],
      address1: [item.address1 || ''],
      address2: [item.address2 || ''],
      town: [item.town || ''],
      country: [item.country || ''],
      city: [item.city || ''],
      postcode: [item.postcode || ''],
      phone: [item.phone || '', Validators.required],
      email: [item.email || '', [Validators.required, Validators.email]],
      status: [item.status || '', Validators.required]
    });
    this.rateForm = this.fb.group({
      client_id: ['', Validators.required],
      name: ['', Validators.required],
      object_unit: ['labour', Validators.required],
      object_quantity: [1, Validators.required],
      time_unit: ['day', Validators.required],
      time_quantity: [1, Validators.required],
      buy_price: [0, Validators.required],
      sell_price: ['', Validators.required],
      vat: [0.2, Validators.required],
      currency: ['GBP', Validators.required],
      discipline: ['', Validators.required],
      inclusive_quantity: [1, Validators.required],
      inclusive_unit: ['', Validators.required],
      call_out_rate: [null, Validators.required],
      hourly_rate: [null, Validators.required]
    });
  }

  show() {
    let loader = this.loader.open();
    this._service
      .show(this.id)
      .finally(() => loader.close())
      .subscribe((data: any[]) => {
        this.item = data;
        this.buildItemForm(data);
      });
  }

  getOptions() {
    this.mao
      .all()
      .subscribe((data: ModelAttributeOptions) => {
        this.options = data.company;
      });
  }

  submit() {
    let loader = this.loader.open();
    this._service
      .update(this.id, this.itemForm.value)
      .finally(() => loader.close())
      .subscribe(() => {
        this.router.navigate(['/settings/company']);
        this.toast.show('Company Updated!');
      });
  }

  getClients() {
    let loader = this.loader.open();

    this._service
      .clientCompaniesBelongFm()
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.clients = data;
      });
  }
}
