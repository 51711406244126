import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { LayoutService } from '@shared/services/system/layout.service';
import { GlobalSettingConfig } from '@app/shared/interfaces/global-setting-config';
import { get, set, has } from 'lodash';
import { CompanyModel } from '@app/shared/models/company.model';
import { subYears, addYears } from 'date-fns';
import { UserModel } from '@app/shared/models/user.model';
import { CacheService, CacheKeys } from '../cache.service';
import { ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { NotifyService } from '../notify.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalSettingService {
  private cacheKey = CacheKeys.global_setting;
  // default settings
  public defaultConfig: GlobalSettingConfig = {
    apiUri: environment.apiURL + environment.adminPrefix,
    pageStartTimestamp: subYears(Date.now(), 2).getTime(),
    pageEndTimestamp: addYears(Date.now(), 2).getTime(),
    client: '',
    contractors: [],
    useCompanyId: '',
    useCompany: new CompanyModel,
    companies: [],
    user: new UserModel,
    token: ''
  };
  public config: GlobalSettingConfig;
  public config$ = new ReplaySubject(1);
  public onConfigChange = new EventEmitter();

  constructor(
    private layout: LayoutService,
    private cache: CacheService,
    private http: HttpClient,
    private translate: TranslateService,
    private toast: NotifyService
  ) {
    this.initConfigByCache();
    this.onConfigChange.subscribe((config: GlobalSettingConfig) => {
      this.saveConfigToCache();
      this.saveConfigToServer();
    });
  }

  public saveConfigToCache() {
    this.cache.set(this.cacheKey, this.config);
  }

  public saveConfigToServer() {
    // this.publicService.setSetting({settings: this.config}).subscribe();
  }

  public initConfigByCache(config: GlobalSettingConfig = {}) {
    // load config by cache
    if (Object.keys(config).length === 0) {
      this.config = this.cache.get(this.cacheKey, { ...this.defaultConfig });
    } else {
      this.config = { ...config };
    }
    this.saveConfigToCache();
  }

  public clearConfig() {
    this.cache.remove(this.cacheKey);
    this.config = { ...this.defaultConfig };
    this.onConfigChange.emit(this.config);
  }

  public setConfig(path, value = null, options: { onEvent?: boolean, onRefresh?: boolean } = { onEvent: true, onRefresh: true }) {
    if (has(this.config, path)) {
      set(this.config, path, value);
      if (options.onEvent) {
        this.onConfigChange.emit(this.config);
      }
      if (options.onRefresh) {
        this.layout.onRefresh.emit();
      }
    }
  }

  public publishConfig(lc: GlobalSettingConfig, onChange = true) {
    this.config = Object.assign(this.config, lc);
    if (onChange) {
      this.onConfigChange.emit(this.config);
      this.layout.onRefresh.emit();
      this.config$.next(this.config);
    }
  }

  public getConfig(path, def: any = '') {
    return get(this.config, path, def);
  }

  public initTranslation() {
    this.http
    .get('settings/translation/read')
    .subscribe(
      (res: any) => {
        if (res.translation) {
          this.translate.setTranslation('en', res.translation);
          // this.translate.reloadLang('en');
        }
      },
      error =>
        this.toast.show(this.translate.instant('alert_read_settings_failed'))
    );
  }
}
