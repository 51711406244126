import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { CompanyService } from '@shared/services/api/company.service';
import {
  BuildingOptions,
  CompanyOptions,
  ModelAttributeOptions
} from '@shared/models/options';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { BaseEditComponent } from '@shared/components/base/base-edit.component';
import { InterfaceBaseEdit } from '@shared/interfaces/interface-base-edit';
import { tableConfigs } from '@shared/config';
import { BuildingService } from '@shared/services/api/building.service';

@Component({
  selector: 'app-building-edit',
  templateUrl: './building-edit.component.html',
  styleUrls: ['./building-edit.component.scss']
})
export class BuildingEditComponent extends BaseEditComponent
  implements OnInit, InterfaceBaseEdit {
  tableConfig = tableConfigs.building;
  @Input() id;
  @Input() inPopup = false;
  @Output() onSubmitted = new EventEmitter();

  public itemForm: FormGroup;
  public item;
  public buildingOption: BuildingOptions;
  public companyOption: CompanyOptions;

  constructor(
    private fb: FormBuilder,
    private companyService: CompanyService,
    private maoService: ModelAttributeOptionService,
    public injector: Injector,
    public _service: BuildingService
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.getOptions();
    this.buildItemForm({});
    this.show();
  }

  buildItemForm(item) {
    this.itemForm = this.fb.group({
      name: [item.name || '', Validators.required],
      alias: [item.alias || ''],
      code: [item.code || ''],
      address1: [item.address1 || ''],
      address2: [item.address2 || ''],
      town: [item.town || ''],
      city: [item.city || ''],
      postcode: [item.postcode || ''],
      country: [item.country || ''],
      latitude: [item.latitude || ''],
      longitude: [item.longitude || ''],
      email: [item.email || '', [Validators.email]],
      phone: [item.phone || ''],
      status: [item.status || '']
    });
  }

  show() {
    let loader = this.loader.open();
    this._service
      .show(this.id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.item = data;
        this.buildItemForm(data);
      });
  }

  getOptions() {
    this.maoService.all().subscribe((data: ModelAttributeOptions) => {
      this.buildingOption = data.building;
      this.companyOption = data.company;
    });
  }

  submit() {
    let loader = this.loader.open();
    this._service
      .update(this.id, this.itemForm.value)
      .finally(() => loader.close())
      .subscribe(() => {
        if (this.inPopup) {
          this.onSubmitted.emit(true);
        } else {
          this.router.navigate([this.tableConfig.urlPrefix]);
        }
        this.toast.show('Building Updated!');
      });
  }
}
