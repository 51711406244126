import {
  Component, EventEmitter, forwardRef, Input, Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-str-checkbox',
  templateUrl: './str-checkbox.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StrCheckboxComponent),
      multi: true
    }
  ]
})
export class StrCheckboxComponent implements ControlValueAccessor {

  innerValue: boolean;

  @Input() value: any;
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() trueValue: any = true;
  @Input() falseValue: any = false;
  @Output() onChange = new EventEmitter();
  @Output() valueChange = new EventEmitter();

  customOnChange($event) {
    this.onChange.emit($event);
  }

  modelChange($event) {
    if ($event) {
      this.innerValue = true;
      this.value = this.trueValue;
      this.valueChange.emit(this.trueValue);
      this.onValueChange(this.trueValue);
    } else {
      this.innerValue = false;
      this.value = this.falseValue;
      this.valueChange.emit(this.falseValue);
      this.onValueChange(this.falseValue);
    }
  }

  onValueChange = (_: any) => {
  }

  registerOnChange(fn) {
    this.onValueChange = fn;
  }

  registerOnTouched(fn) {
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj;
    if (obj === this.trueValue) {
      this.innerValue = true;
    } else {
      this.innerValue = false;
    }
  }
}
