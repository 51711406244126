import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppLoaderService } from '@shared/services/system/app-loader/app-loader.service';
import { FileUploader } from 'ng2-file-upload';
import { DocumentService } from '@shared/services/api/document.service';
import { AuthGuard } from '@shared/services/system/auth/auth.guard';
import {NotifyService} from '@shared/services/notify.service';

@Component({
  selector: 'app-document-edit-popup',
  templateUrl: './document-edit.component.html'
})
export class DocumentEditPopupComponent implements OnInit {
  id;  // type_id
  public uploader: FileUploader;
  public itemForm: FormGroup;
  public inPopup = true;

  public item;

  constructor(
    private fb: FormBuilder,
    private loader: AppLoaderService,
    public dialogRef: MatDialogRef<DocumentEditPopupComponent>,
    private documentService: DocumentService,
    private auth: AuthGuard,
    private toast: NotifyService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    this.id = this.data.id;
    this.setUploadEvent();
    this.buildItemForm({});
    this.show(this.id);
  }

  buildItemForm(item) {
    this.itemForm = this.fb.group({
      subject: ['' || item.subject, Validators.required],
      description: ['' || item.description, Validators.required],
    });
  }

  setUploadEvent() {
    this.uploader = new FileUploader({
      url: this.documentService.uploadUrl() + '/' + this.id,
      method: 'POST',
      authToken: 'bearer ' + this.auth.getToken(),
      disableMultipart: false,
      headers: [
        { name: 'x-requested-with', value: 'XMLHttpRequest' },
      ]
    });
    this.uploader.onBuildItemForm = (fileItem, form) => {
      this.uploader.options.additionalParameter = { ...this.itemForm.value, _method: 'PUT' };
    };
    let loader;
    this.uploader.onBeforeUploadItem = (fileItem => {
      loader = this.loader.open('Uploading...');
    });
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      if (status === 200) {
        this.toast.show('Add success!');
        this.uploader.clearQueue();
        this.dialogRef.close(true);
      } else {
        this.toast.show('Add failed!');
      }
      loader.close();
    };
  }

  show(id) {
    let loader = this.loader.open();
    this.documentService.show(id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.item = data;
        this.buildItemForm(this.item);
      });
  }

  submit() {
    if (this.uploader.queue.length === 0) {
      // update date
      let data = { ...this.itemForm.value };
      let loader = this.loader.open();
      this.documentService.update(this.id, data)
        .finally(() => loader.close())
        .subscribe(() => {
          this.toast.show('Document updated!');
          this.dialogRef.close(true);
        });
    } else {
      // upload file
      this.uploader.uploadAll();
    }
  }
}
