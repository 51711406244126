import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LaravelPageRequest } from '@shared/models/laravel-page-request.model';
import { Conditions } from '@shared/models/conditions.model';
import { Router } from '@angular/router';
import { TableConfigModel } from '@shared/models/table-config.model';

@Injectable({
  providedIn: 'root'
})
export class ApiBaseService {

  route_prefix = 'base';
  tableConfig: TableConfigModel;

  public conditions: Conditions;

  constructor(
    public http: HttpClient,
    public router: Router
  ) {
  }

  index(params: LaravelPageRequest) {
    return this.http.get(this.route_prefix, { params: params.request() });
  }

  store(data) {
    return this.http.post(this.route_prefix, data);
  }

  show(id) {
    return this.http.get(this.route_prefix + '/' + id);
  }

  destroy(id) {
    return this.http.delete(this.route_prefix + '/' + id);
  }

  update(id, data) {
    return this.http.put(this.route_prefix + '/' + id, data);
  }

  changeConditionValue(key, value) {
    this.conditions[key].value = value;
  }

  gotoTable() {
    this.router.navigate([this.tableConfig.urlPrefix]);
  }
}
