import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import 'rxjs-compat/add/operator/finally';
import { CompanyModel } from '@shared/models/company.model';
import { UserService } from '@shared/services/api/user.service';
import { CompanyService } from '@shared/services/api/company.service';
import { NoteService } from '@shared/services/api/note.service';

@Component({
  selector: 'app-confirm-with-note',
  template: // html
    `
    <h1 mat-dialog-title translate>{{data?.title}}</h1>
    <div mat-dialog-content *ngIf="data?.message">{{ data?.message }}</div>
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutWrap="wrap" class="p-0">
      <div fxFlex="100">
        <mat-form-field class="full-width">
          <textarea matInput [formControl]="form.controls['note']" placeholder="Note" rows="5"></textarea>
        </mat-form-field>
      </div>
      <!--target company-->
    <div fxFlex="100" class="pr-1">
      <app-search-select [items]="companies" [placeholder]="'Target Companies'" [field_classes]="'full-width'"
        [form_ctrl]="form.controls['target_company']" [value_column]="'id'" [display_column]="'name'"
        [multi]="true">
        <ng-template let-row="row" #display>
          <span class="main-text">{{row?.name | translate}}</span>
          <span class="text-muted child-text" *ngIf="row?.alias && row?.name !== row?.alias">|{{row?.alias}}</span>
        </ng-template>
      </app-search-select>
    </div>
    <!--target user-->
    <div fxFlex="50" class="pr-1">
      <app-search-select [items]="companies" [placeholder]="'User Companies'" [field_classes]="'full-width'"
        [form_ctrl]="form.controls['user_company_ids']" [value_column]="'id'" [display_column]="'name'"
        [multi]="true">
        <ng-template let-row="row" #display>
          <span class="main-text">{{row?.name | translate}}</span>
          <span class="text-muted child-text" *ngIf="row?.alias && row?.name !== row?.alias">|{{row?.alias}}</span>
        </ng-template>
      </app-search-select>
    </div>
    <div fxFlex="50" class="pr-1">
      <app-search-select [items]="users" [placeholder]="usersLoading? 'Loading...' : 'Target Users'"
        [field_classes]="'full-width'" [form_ctrl]="form.controls['target_user']" [value_column]="'id'"
        [display_column]="'name'" [multi]="true">
        <ng-template let-row="row" #display>
          <span class="main-text">{{row?.name | translate}}</span>
          <span class="text-muted child-text" *ngIf="row?.email">|
            {{row?.email | translate}}</span>
        </ng-template>
      </app-search-select>
    </div>
    </div>
    <div mat-dialog-actions>
      <div fxFlex></div>
      <button mat-raised-button (click)="dialogRef.close()" color="warn" translate>
      <mat-icon>close</mat-icon>Close</button>
      <button mat-raised-button (click)="submit()" color="primary" translate>
      <mat-icon>check</mat-icon>OK</button>
    </div>
  `
})
export class ConfirmWithNoteComponent implements OnInit {

  public users = [];
  public companies: CompanyModel[] = [];
  public userCompanies: CompanyModel[] = [];
  public userAllSub;
  public usersLoading = false;

  form = new FormGroup({
    note: new FormControl(''),
    user_company_ids: new FormControl([]),
    target_user: new FormControl([]),
    target_company: new FormControl([])
  });

  constructor(
    public dialogRef: MatDialogRef<ConfirmWithNoteComponent>,
    private _user: UserService,
    private _service: NoteService,
    private _company: CompanyService,
    @Inject(MAT_DIALOG_DATA) public data: { title?: string; message?: string; },
  ) {
  }

  ngOnInit(): void {
    this._company.indexAll().subscribe((data: CompanyModel[]) => {
      this.companies = data;
      this.userCompanies = data;
    });

    this.form.get('user_company_ids').valueChanges.subscribe((user_company_ids) => {
      if (this.userAllSub) {
        this.userAllSub.unsubscribe();
      }
      this.usersLoading = true;
      this.userAllSub = this._user.all({ company_ids: user_company_ids })
        .finally(() => this.usersLoading = false)
        .subscribe((data: any) => {
          this.users = data;
        });
    });
  }

  severityStyle(value) {
    return this._service.severityStyle(value);
  }

  submit() {
    this.dialogRef.close({ ...this.form.value });
  }
}

