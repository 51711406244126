import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RoleService, Roles } from '../role.service';

interface IMenuItem {
  type: string,       // Possible values: link/dropDown/icon/separator/extLink
  name?: string,      // Used as display text for item and title for separator statu
  state?: string,     // Router state
  icon?: string,      // Material icon name
  tooltip?: string,   // Tooltip text
  disabled?: boolean, // If true, item will not be appeared in sidenav.
  sub?: IChildItem[], // Dropdown items
  badges?: IBadge[],
  roles?: string[],
}

interface IChildItem {
  type?: string,
  name: string,       // Display text
  state?: string,     // Router state
  icon?: string,
  sub?: IChildItem[]
}

interface IBadge {
  color: string;      // primary/accent/warn/hex color codes(#fff000)
  value: string;      // Display text
}

@Injectable()
export class NavigationService {
  constructor(
    private roleService: RoleService
  ) {
    roleService.Roles$.subscribe((Roles: Roles) => {
      console.log('load', Roles);
      let filtered = this.iconMenu.filter((item: IMenuItem) => {
        if (item['roles'] && Array.isArray(item['roles'])) {
          return Roles.hasAnyRoles(item['roles'])
        } else {
          return true;
        }
      });
      console.log('filtered', filtered);
      this.menuItems.next(filtered);
    });
  }

  defaultMenu: IMenuItem[] = []
  separatorMenu: IMenuItem[] = []
  iconMenu: IMenuItem[] = [
    {
      type: 'separator',
      name: 'Main Items'
    },
    {
      name: 'DASHBOARD',
      type: 'link',
      tooltip: 'Dashboard',
      icon: 'dashboard',
      state: 'dashboard',
    },
    {
      name: 'Jobs',
      type: 'link',
      tooltip: 'Jobs',
      icon: 'build',
      state: 'service'
    },
    /*{
      name: 'Asset',
      type: 'link',
      tooltip: 'asset',
      icon: 'playlist_play',
      state: 'settings/asset'
    },*/
    {
      name: 'BUILDING',
      type: 'link',
      tooltip: 'building',
      icon: 'account_balance',
      state: 'settings/building'
    },
    {
      name: 'SETTINGS',
      type: 'dropDown',
      tooltip: '',
      icon: 'settings',
      state: '',
      sub: [
        { name: 'GENERAL', state: 'settings/general' },
        { name: 'COMPANY', state: 'settings/company' }
      ]
    },
  ]

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon statu item is present in menu.
  iconTypeMenuTitle: string = 'Frequently Accessed';
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  // Customizer component uses this method to change menu.
  // You can destroy this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user statu.
  publishNavigationChange(menuType: string) {
    switch (menuType) {
      case 'separator-menu':
        this.menuItems.next(this.separatorMenu);
        break;
      case 'icon-menu':
        this.menuItems.next(this.iconMenu);
        break;
      default:
        this.menuItems.next(this.defaultMenu);
    }
  }
}
