import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler,
  HttpRequest, HttpResponse
} from '@angular/common/http';

import { finalize, tap } from 'rxjs/operators';
import { MessageService } from '../services/system/message.service';
import 'rxjs-compat/add/operator/mergeMap';
import 'rxjs-compat/add/operator/catch';
import '../services/system/logger.service';

import { Logger} from '@app/shared/services/system/logger.service';

const log = new Logger('LoggingInterceptor');

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  constructor(private messenger: MessageService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const started = Date.now();
    let ok: string;
    log.debug(req);
    // extend server response observable with logging
    return next.handle(req)
      .pipe(
        tap(
          // Succeeds when there is a response; ignore other events
          (event) => {
            log.debug(event);
            ok = event instanceof HttpResponse ? 'succeeded' : '';
          },
          // Operation failed; error is an HttpErrorResponse
          (error) => {
            ok = 'failed';
          }
        ),
        // Log when response observable either completes or errors
        finalize(() => {
          const elapsed = Date.now() - started;
          const msg = `${req.method} "${req.urlWithParams}"
             ${ok} in ${elapsed} ms.`;
          log.debug(msg);
          this.messenger.add(msg);
        })
      );
  }
}
