import { Component, OnInit, Inject, Output, EventEmitter, Injector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModelAttributeOptions, QuotationItemOptions } from '@shared/models/options';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { BaseEditComponent } from '@shared/components/base/base-edit.component';
import { QuotationItemService } from '@shared/services/api/quotation-item.service';
import { tableConfigs } from '@shared/config';

@Component({
  selector: 'app-quotation-item-edit-popup',
  templateUrl: './quotation-item-edit.component.html'
})
export class QuotationItemEditPopupComponent extends BaseEditComponent implements OnInit {
  tableConfig = tableConfigs.quotation_item;

  public id;
  public itemForm: FormGroup;
  public lineTotal = 0;
  public item;
  public options: QuotationItemOptions;
  @Output() onUpdated = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<QuotationItemEditPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public maoService: ModelAttributeOptionService,
    public injector: Injector,
    public _service: QuotationItemService
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.id = this.data.id;
    this.buildItemForm();
    this.show();
    this.getOptions();
  }

  getOptions() {
    this.maoService.all()
      .subscribe((data: ModelAttributeOptions) => {
        this.options = data.quotation_item;
      });
  }

  buildItemForm() {
    this.itemForm = this.fb.group({
      item_name: '',
      item_description: '',
      item_unit_price: 0,
      item_quantity: 0,
      item_status: 0
    });
    this.itemForm.valueChanges.subscribe((value) => {
      this.lineTotal = value.item_quantity * value.item_unit_price;
    });
  }

  fillForm(item) {
    this.itemForm.patchValue({
      item_name: item.item_name || '',
      item_description: item.item_description || '',
      item_unit_price: item.item_unit_price || 0,
      item_quantity: item.item_quantity || 0,
      item_status: item.item_status || '',
    });
  }

  show() {
    let loader = this.loader.open();
    this._service.show(this.id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.item = data;
        this.fillForm(this.item);
      });
  }

  submit() {
    let data = { ...this.itemForm.value };
    let loader = this.loader.open();
    this._service.update(this.id, data)
      .finally(() => {
        loader.close();
        this.show();
      })
      .subscribe(() => {
        this.onUpdated.emit();
        this.toast.show('Quotation item updated!');
        this.dialogRef.close(true);
      });
  }
}
