import { Component, OnInit, Injector, Input } from '@angular/core';
import { egretAnimations } from '@shared/animations/egret-animations';
import { BaseTableComponent } from '@shared/components/base/base-table.component';
import { Conditions } from '@shared/models/conditions.model';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { ModelAttributeOptions } from '@shared/models/options';
import { InterfaceBaseTable } from '@shared/interfaces/interface-base-table';
import { AssetService } from '@shared/services/api/asset.service';
import { tableConfigs } from '@shared/config';
import { BuildingService } from '@app/shared/services/api/building.service';
import { CompanyService } from '@app/shared/services/api/company.service';
import { GlobalSettingService } from '@app/shared/services/system/global-setting.service';

@Component({
  selector: 'app-asset-table',
  templateUrl: './asset-table.component.html',
  animations: egretAnimations
})
export class AssetTableComponent extends BaseTableComponent
  implements OnInit, InterfaceBaseTable {
  tableConfig = tableConfigs.asset;
  @Input() inPopup = false;
  public conditions: Conditions = {
    building_id: {
      value: '',
      nullable: true,
      items: [],
      display_key: 'name',
      select_key: 'id',
      display_name: 'Building'
    },
    category: {
      value: '',
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Category'
    },
    status: {
      value: '',
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Status'
    }
  };

  public items: any[];

  constructor(
    public _service: AssetService,
    protected injector: Injector,
    private companyService: CompanyService,
    private globalSetting: GlobalSettingService,
    private buildingService: BuildingService,
    private maoService: ModelAttributeOptionService
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.getOptions();
  }

  getOptions() {
    this.maoService.all().subscribe((data: ModelAttributeOptions) => {
      this.conditions.status.items = data.asset.data_groups.service_status;
      this.conditions.category.items = data.asset.category;
    });

    this.getBuildings(this.globalSetting.config.useCompanyId);
  }

  getBuildings(company_id) {
    let loader = this.loader.open();
    this.companyService
      .getBuildingsByClientId(company_id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.conditions.building_id.items = data;
      });
  }
}
