import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { catchError } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger } from '@shared/services/system/logger.service';
import { MatSnackBar } from '@angular/material';
import { AuthGuard } from '@shared/services/system/auth/auth.guard';
import {NotifyService} from '@shared/services/notify.service';

const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

  constructor(private snack: MatSnackBar,
    private auth: AuthGuard,
    private toast: NotifyService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(error => this.errorHandler(error)));
  }

  // Customize the default error handler here if needed
  private errorHandler(response: HttpErrorResponse): Observable<HttpEvent<any>> {
    let showError = '';
    if (!environment.production) {
      // Do something with the error
      log.error('Request error', response);
    }
    if (response.error.error) {
      showError = response.error.error;
    }
    if (response.error.message) {
      showError = response.error.message;
    }
    if (response.status === 429) {
      showError = 'Requests are too frequent, please open the page later.';
    }
    // input data error
    if (response.error.errors) {
      Object.keys(response.error.errors).forEach((key) => {
        for (let item of response.error.errors[key]) {
          this.toast.show(item);
        }
      });
    }
    if (!showError) {
      showError = 'Something bad happened, please try again later.';
    }
    if (response.error.error === 'token_not_provided') {
      showError = '';
    }
    if (showError) {
      this.toast.show(showError);
    }
    if (response.error.error === 'token_invalid') {
      this.auth.signout();
    }
    throw response;
  }

}
