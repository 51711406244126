import { Component, OnInit, OnDestroy } from '@angular/core';
import { get, find, keys } from 'lodash';
import { ServiceExtraService } from '../service-extra.service';
import { ServiceOptions } from '@app/shared/models/options';
import { ModelAttributeOptionService } from '@app/shared/services/api/model-attribute-option.service';

@Component({
  selector: 'app-service-extra-create',
  templateUrl: './service-extra-create.component.html',
  styleUrls: ['./service-extra-create.component.scss']
})
export class ServiceExtraCreateComponent implements OnInit, OnDestroy {
  public keys = keys;
  public serviceOptions: ServiceOptions;

  constructor(
    public serviceExtraService: ServiceExtraService,
    private mao: ModelAttributeOptionService,
  ) {
  }

  ngOnInit() {
    this.getOptions();
  }

  getOptions() {
    this.mao.all().subscribe((data) => {
      this.serviceOptions = data.service;
    })
  }

  ngOnDestroy() {
    this.serviceExtraService.clear();
  }

  getExtraFormValueData(key) {
    return get(find(this.serviceExtraService.extraForm, ['option_value', key]), 'data');
  }
}
