import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DashboardService {
  items: any[];

  constructor(private http: HttpClient) {
  }

  getServiceDashboard(group = '', type = '') {
    return this.http.post('services/dashboard', { group, type });
  }

  getQuotationDashboard() {
    return this.http.post('quotations/dashboard', {});
  }
}
