import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TimesheetService {
  constructor(
    public http: HttpClient
  ) {
  }

  index(body: { start_date_time: string; end_date_time: string; supplier_id: string; }) {
    return this.http.post(`timesheet/index`, body);
  }

  suppliers() {
    return this.http.get(`timesheet/suppliers`);
  }
}
