import { Injectable } from '@angular/core';
import { ApiBaseService } from '@shared/services/api/api-base.service';
import { Conditions } from '@shared/models/conditions.model';
import { TableConfigModel } from '@shared/models/table-config.model';
import { tableConfigs } from '@shared/config';

@Injectable()
export class PurchaseOrderService extends ApiBaseService {
  route_prefix = 'purchase-orders';
  tableConfig: TableConfigModel = tableConfigs.purchase_order;

  public conditions: Conditions = {
    status: {
      value: '',
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Status'
    },
  };

  pay(id) {
    return this.http.post(this.route_prefix + '/' + id + '/pay', {});
  }

  deliver(id) {
    return this.http.post(this.route_prefix + '/' + id + '/deliver', {});
  }

  clients() {
    return this.http.get(this.route_prefix + '/clients');
  }

  serviceSuppliers(clientId = '') {
    return this.http.get(this.route_prefix + '/service-suppliers', { params: { client_id: clientId } });
  }
}
