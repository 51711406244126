import { Injectable } from '@angular/core';
import { ApiBaseService } from '@shared/services/api/api-base.service';
import { Conditions } from '@shared/models/conditions.model';
import { TableConfigModel } from '@shared/models/table-config.model';
import { tableConfigs } from '@shared/config';

@Injectable()
export class ApplicationService extends ApiBaseService {

  route_prefix = 'applications';
  tableConfig: TableConfigModel = tableConfigs.application;
  public conditions: Conditions = {
    status: {
      value: '',
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Status'
    },
  };

  getCanGenerateQuotationServices(data) {
    return this.http.get('applications/get-can-generate-quotation-service', { params: data });
  }

  getClients(supplier_id) {
    return this.http.get('applications/clients', { params: { supplier_id } });
  }

  getSuppliers() {
    return this.http.get('applications/suppliers', {});
  }

  generateInvoice(quotationId, body) {
    return this.http.post(`${this.route_prefix}/${quotationId}/generate-invoice`, body);
  }

  approve(quotationId, body?: { note: string; }) {
    return this.http.post(`${this.route_prefix}/${quotationId}/approve`, body);
  }

  decline(quotationId, body?: { note: string; }) {
    return this.http.post(`${this.route_prefix}/${quotationId}/decline`, body);
  }

  pendingSubmission(supplierId, clientId) {
    return this.http.get(`${this.route_prefix}/pending-submission`, { params: { supplier_id: supplierId, client_id: clientId } });
  }

  manuallySubmit(supplierId, clientId, serviceIds) {
    return this.http.post(`${this.route_prefix}/manually-submit`, { supplier_id: supplierId, client_id: clientId, service_ids: serviceIds });
  }
}
