import { UserModel } from './../../../../models/user.model';
import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModelAttributeOptions, PurchaseOrderItemOptions, ServiceOptions } from '@shared/models/options';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { CompanyService } from '@shared/services/api/company.service';
import { PurchaseOrderOptions } from '@shared/models/options';
import { BaseCreateComponent } from '@shared/components/base/base-create.component';
import { InterfaceBaseCreate } from '@shared/interfaces/interface-base-create';
import { tableConfigs } from '@shared/config';
import { PurchaseOrderService } from '@shared/services/api/purchase-order.service';
import { ActivatedRoute } from '@angular/router';
import { GlobalSettingService } from '@shared/services/system/global-setting.service';
import { AuthGuard } from '@shared/services/system/auth/auth.guard';
import { PopupService } from '@shared/services/popup.service';
import { ServiceService } from '@shared/services/api/service.service';
import { CompanyModel } from '@app/shared/models/company.model';
import { SimpleReuseStrategy } from '@app/simple-reuse-strategy';
import { get } from 'lodash';

@Component({
  selector: 'app-purchase-order-create',
  templateUrl: './purchase-order-create.component.html',
  providers: [SimpleReuseStrategy]
})
export class PurchaseOrderCreateComponent extends BaseCreateComponent implements OnInit, InterfaceBaseCreate {
  tableConfig = tableConfigs.purchase_order;
  tableConfigs = tableConfigs;
  public purchaseOrderItemTableDisplayColumns = [
    { display: 'Type', key: 'item_type', useColor: false },
    { display: 'Total Price', key: 'item_total_price', useColor: false },
    { display: 'Name', key: 'item_name', useColor: false },
    { display: 'Unit Price', key: 'item_unit_price', useColor: false },
    { display: 'Quantity', key: 'item_quantity', useColor: false },
  ];
  @Input() data: { serviceId: number | string; clientId: number | string; } = { serviceId: '', clientId: '' };
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();

  public basicFormGroup: FormGroup;
  public itemFormGroup: FormGroup;

  public buildings;

  public purchaseOrderItems = [];

  public total = 0;
  public lineTotal = 0;
  public subtotal = 0;

  public serviceOptions: ServiceOptions;
  public purchaseOrderOptions: PurchaseOrderOptions;
  public purchaseOrderItemOptions: PurchaseOrderItemOptions;
  public clients: CompanyModel[];
  public serviceSuppliers: CompanyModel[];
  public serviceIdIsValid = false;
  public authUser: UserModel;

  public orderMethods = [
    { option_value: 'telephone' },
    { option_value: 'email' },
  ];
  public users = [];

  constructor(
    private fb: FormBuilder,
    private mao: ModelAttributeOptionService,
    private companyService: CompanyService,
    public injector: Injector,
    public _service: PurchaseOrderService,
    public route: ActivatedRoute,
    public auth: AuthGuard,
    public globalSetting: GlobalSettingService,
    public popup: PopupService,
    public serviceService: ServiceService
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.buildItemForm();
    this.authUser = this.globalSetting.getConfig('user');

    if (this.inPopup) {
      // in popup
    } else {
      this.data.serviceId = +this.route.snapshot.paramMap.get('serviceId');
    }

    this.getOptions();
    this.companyService.users(this.globalSetting.getConfig('useCompanyId'))
      .subscribe((data: any) => {
        this.users = data;
      });
    this.getClients();
  }

  buildItemForm() {
    this.basicFormGroup = this.fb.group({
      reference: ['', Validators.required],
      date_of_order: ['', Validators.required],
      vat: [0, Validators.required],
      subject: ['', Validators.required],
      description: ['', Validators.required],
      status: ['', Validators.required],
      delivery_to: ['', Validators.required],
      delivery_address: [''],
      delivery_date: [''],
      order_method: [''],
      emails: [[]],
      user_identity: [''],
      service_id: ['', { updateOn: 'blur' }],
      client_id: [''],
      supplier_id: ['']
    });

    this.itemFormGroup = this.fb.group({
      item_type: ['', Validators.required],
      item_name: ['', Validators.required],
      item_description: ['', Validators.required],
      item_unit_price: [0, Validators.compose([Validators.required, Validators.min(0)])],
      item_quantity: [0, Validators.compose([Validators.required, Validators.min(0)])],
    });

    this.basicFormGroup.get('delivery_to').valueChanges.subscribe((change) => {
      if (change === 'fm') {
        const useCompany = this.globalSetting.getConfig('useCompany', new CompanyModel);
        this.basicFormGroup.patchValue({ delivery_address: useCompany.address1 || useCompany.address2 });
      }
    });
    this.itemFormGroup.get('item_unit_price').valueChanges.subscribe((item_unit_price) => {
      let item_quantity = this.itemFormGroup.value.item_quantity || 0;
      let price = item_unit_price || 0;
      this.lineTotal = item_quantity * price;
    });
    this.itemFormGroup.get('item_quantity').valueChanges.subscribe((item_quantity) => {
      let q = item_quantity || 0;
      let price = this.itemFormGroup.value.item_unit_price || 0;
      this.lineTotal = q * price;
    });
    this.basicFormGroup.get('vat').valueChanges.subscribe((vat) => {
      this.computeTotal(vat);
    });
    this.basicFormGroup.get('service_id').valueChanges.subscribe((change) => {
      if (!change) {
        this.serviceIdIsValid = false;
      } else {
        let loader = this.loader.open();
        this.serviceService.checkValid(change)
          .finally(() => loader.close())
          .subscribe((data: any) => {
            if (data.valid) {
              this.serviceIdIsValid = true;
            } else {
              this.serviceIdIsValid = false;
            }
          });
      }
    });
    this.basicFormGroup.get('client_id').valueChanges.subscribe((client_id) => {
      this.getServiceSuppliers(client_id);
    });
    this.basicFormGroup.patchValue({
      service_id: get(this.data, 'serviceId', ''),
      reference: get(this.data, 'serviceId', ''),
      client_id: get(this.data, 'clientId'),
    });
  }

  getOptions() {
    this.mao.all()
      .subscribe((data: ModelAttributeOptions) => {
        this.serviceOptions = data.service;
        this.purchaseOrderOptions = data.purchase_order;
        this.purchaseOrderItemOptions = data.purchase_order_item;
      });
  }

  submit() {
    this.confirmService.confirm().subscribe((res) => {
      if (res) {
        let data = { ...this.basicFormGroup.value, items: this.purchaseOrderItems };
        let loader = this.loader.open();
        this._service.store(data)
          .finally(() => loader.close())
          .subscribe(() => {
            this.onSubmitted.emit();
            SimpleReuseStrategy.deleteRouteSnapshot('//purchase-order/create');
            this.toast.show('Purchase Order Added!');
          });
      }
    });
  }

  // Quotation Item Function

  addPurchaseOrderItem() {
    let now = this.purchaseOrderItems;
    now.push({ ...this.itemFormGroup.value, item_total_price: this.itemFormGroup.value.item_unit_price * this.itemFormGroup.value.item_quantity });
    this.purchaseOrderItems = [...this.purchaseOrderItems];
    this.computeTotal();
  }

  removePurchaseOrderItem(row) {
    let index = this.purchaseOrderItems.indexOf(row);
    this.purchaseOrderItems.splice(index, 1);
    this.purchaseOrderItems = [...this.purchaseOrderItems];
    this.computeTotal();
  }

  computeTotal(vat = 0) {
    let subtotal = 0;
    for (let item of this.purchaseOrderItems) {
      subtotal += item.item_unit_price * item.item_quantity;
    }
    this.subtotal = subtotal;
    if (!vat) {
      vat = this.basicFormGroup.value.vat || 0;
    }
    this.total = this.subtotal + vat;
  }

  openEditServicePopup(serviceId) {
    this.popup.openServiceEditPage(serviceId);
  }

  getClients() {
    let loader = this.loader.open();
    this._service.clients()
      .finally(() => loader.close())
      .subscribe((data: CompanyModel[]) => {
        this.clients = data;
      });
  }

  getServiceSuppliers(clientId = '') {
    let loader = this.loader.open();
    this._service.serviceSuppliers(clientId)
      .finally(() => loader.close())
      .subscribe((data: CompanyModel[]) => {
        this.serviceSuppliers = data;
      });
  }
}
