import {Observable} from 'rxjs';
import {MatDialog} from '@angular/material';
import {Injectable} from '@angular/core';
import {AppSelectCompanyComponent} from '@shared/services/system/app-select-company/app-select-company.component';

@Injectable()
export class AppSelectCompanyService {

  constructor(private dialog: MatDialog) {
  }

  public show(): Observable<boolean> {
    return this.dialog.open(AppSelectCompanyComponent, {
      width: '380px',
      disableClose: true,
      data: {}
    }).afterClosed();
  }
}
