import { NoteModel } from '@shared/models/note.model';

export class BuildingModel {
  id: string;
  address1: string;
  address2: string;
  alias: string;
  city: string;
  code: string;
  country: string;
  company_name: string;
  full_address: string;
  name: string;
  phone: string;
  postcode: string;
  town: string;
  email: string;
  notes: NoteModel[];
  status: string;
  data: {
    business_hours: {
      content: string;
      day: string[],
      time: { start_time: string; end_time: string }[]
    }[]
  };
}
