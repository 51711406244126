import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ContentChild, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ReplaySubject } from 'rxjs/Rx';
import { TranslateService } from '@ngx-translate/core';
import { type } from 'os';

@Component({
  selector: 'app-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.scss']
})
export class SearchSelectComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() multi = false;
  @Input() placeholder = '';
  _items = [];
  @Input() field_classes;
  @Input() filter_column = 'name';
  @Input() display_column = 'name';
  @Input() value_column = 'id';
  @Input() form_ctrl: FormControl;
  @Input() nullable = false;
  @Output() onValueChange = new EventEmitter<number>();
  filter_ctrl: FormControl = new FormControl();
  filter_result: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  @ContentChild(TemplateRef) display: TemplateRef<any>;
  selectShow = '';

  // private _limit = 100;

  constructor(
    private translate: TranslateService
  ) { }

  ngOnInit() {
  }

  @Input() set items(inputItems) {
    if (!inputItems) {
      inputItems = [];
    }
    this._items = inputItems;
    this.selectShow = this.computeSelectShow(this.form_ctrl.value);
  }

  get items() {
    return this._items;
  }

  ngAfterViewInit() {
    this.filter_ctrl.valueChanges
      .subscribe(() => {
        this.filter();
      });
    this.form_ctrl.valueChanges
      .subscribe((val) => {
        this.onValueChange.emit(val);
        this.selectShow = this.computeSelectShow(val);
      });
  }

  computeSelectShow(val) {
    if ((Array.isArray(val) && val.length > 0) || (typeof val === 'string' && val.length > 0) || (typeof val === 'number' && val > 0)) {
      let find = this.findItem(val);
      let str = '';
      if (find) {
        if (this.multi) {
          str = find.map((i) => {
            if (typeof i === 'string') {
              return this.translate.instant(i);
            } else if (i.hasOwnProperty(this.display_column)) {
              return this.translate.instant(i[this.display_column]);
            } else {
              return '';
            }
          }).join(' / ');
        } else {
          // string
          str = find[this.display_column];
          if (typeof str === 'string') {
            str = this.translate.instant(str);
          }
        }
        return str;
      }
    }
    return '';
  }

  findItem(value) {
    if (this.items.length > 0) {
      if (this.multi && Array.isArray(value)) {
        let findItems = this.items.filter((i) => {
          if (typeof i === 'string') {
            return value.indexOf(i) !== -1;
          } else {
            if (i.hasOwnProperty(this.value_column)) {
              return value.indexOf(i[this.value_column]) !== -1;
            }
            return false;
          }
        });
        return findItems;
      } else {
        let findItem = this.items.find((i) => {
          if (typeof i === 'string') {
            return i === value;
          } else {
            if (i.hasOwnProperty(this.value_column)) {
              return i[this.value_column] === value;
            }
            return false;
          }
        });
        return findItem;
      }
    }
  }

  ngOnChanges() {
    this.filter_result.next(this.items.slice());
  }

  private filter() {
    if (!this.items) {
      return;
    }
    // get the search keyword
    let search = this.filter_ctrl.value;
    if (!search) {
      this.filter_result.next(this.items.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filter_result.next(this.items.filter((val) => {
      const field = '' + val[this.filter_column];
      return field.toLowerCase().indexOf(search) > -1;
    }));
  }
}
