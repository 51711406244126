import { AppLoaderService } from './../../../../services/system/app-loader/app-loader.service';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AppConfirmService } from '@shared/services/system/app-confirm/app-confirm.service';
import { PopupService } from '@shared/services/popup.service';
import { DocumentService } from '@shared/services/api/document.service';
import { DocumentModel } from '@shared/models/document.model';
import { LayoutService } from '@app/shared/services/system/layout.service';
import {NotifyService} from '@shared/services/notify.service';

@Component({
  selector: 'app-document-table',
  templateUrl: './document-table.component.html'
})
export class DocumentTableComponent implements OnInit, OnDestroy {
  @Input() id;
  @Input() model;
  public items: DocumentModel[] = [];
  private onRefreshSub;

  constructor(
    private documentService: DocumentService,
    private confirmService: AppConfirmService,
    private toast: NotifyService,
    private popup: PopupService,
    private loader: AppLoaderService,
    private layout: LayoutService
  ) {
  }

  ngOnInit() {
    this.refresh({ showLoading: false });
    this.onRefreshSub = this.layout.onRefresh.subscribe(() => this.refresh());
  }

  ngOnDestroy() {
    if (this.onRefreshSub) {
      this.onRefreshSub.unsubscribe();
    }
  }

  deleteItem(row) {
    this.confirmService.confirm({ message: `Delete ${row.id}?` })
      .subscribe(res => {
        if (res) {
          let loader = this.loader.open();
          this.documentService.destroy(row.id)
            .finally(() => loader.close())
            .subscribe(() => {
              this.refresh();
              this.toast.show(`Note ${row.id} deleted!`);
            });
        }
      });
  }

  openEditPage(row) {
    this.popup.openDocumentEditPage(row.id)
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.refresh();
        }
      });
  }

  refresh({ showLoading } = { showLoading: true }) {
    let loader;
    if (showLoading) {
      loader = this.loader.open();
    }
    this.documentService.indexByModelAndModelId(this.model, this.id)
      .finally(() => {
        if (loader) {
          loader.close()
        }
      })
      .subscribe((response: DocumentModel[]) => {
        this.items = response;
      });
  }
}
