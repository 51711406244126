import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-purchase-order-create-popup',
  template: `
    <app-purchase-order-create [data]="data" [inPopup]="true" (onSubmitted)="dialogRef.close(true)" (onBack)="dialogRef.close()"></app-purchase-order-create>`
})

export class PurchaseOrderCreatePopupComponent implements OnInit {
  public serviceId;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { serviceId: number; clientId?: number },
    public dialogRef: MatDialogRef<PurchaseOrderCreatePopupComponent>,
  ) {
  }

  ngOnInit() {
  }
}
