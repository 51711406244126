import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

import 'rxjs-compat/add/operator/startWith';
import 'rxjs-compat/add/operator/debounceTime';
import 'rxjs-compat/add/operator/distinctUntilChanged';
import 'rxjs-compat/add/operator/switchMap';

@Component({
  selector: 'app-asset-create-popup',
  template: `
    <app-asset-create [inPopup]="true" (onSubmitted)="this.dialogRef.close(true)" [data]="data"></app-asset-create>`
})
export class AssetCreatePopupComponent implements OnInit {
  public id;
  public type;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {companyId, buildingId},
    public dialogRef: MatDialogRef<AssetCreatePopupComponent>,
  ) {
  }

  ngOnInit() {
  }
}
