import {Injectable} from '@angular/core';
import {ApiBaseService} from '@shared/services/api/api-base.service';
import {TableConfigModel} from '@shared/models/table-config.model';
import {tableConfigs} from '@shared/config';

@Injectable()
export class PurchaseOrderItemService extends ApiBaseService {
  route_prefix = 'purchase-order-items';
  tableConfig: TableConfigModel = tableConfigs.purchase_order_item;

}
