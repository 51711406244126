import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  signin(email: string, password: string, rememberMe: boolean = false) {
    return this.http.post('auth/login', { email, password });
  }

  sendEmail(params) {
    return this.http.post('auth/password/send-email', params);
  }

  resetPassword(params) {
    return this.http.post('auth/password/reset', params);
  }
}
