import { LaravelPageResponse } from '@shared/models/laravel-page-response.model';
import { AppTableComponent } from '@app/shared/components/system/table/table.component';
import { tableConfigs } from '@shared/config';
import { Paginate } from './../../../../models/paginate.model';
import { PpmService } from './../../../../services/api/ppm.service';
import { AssetModel } from './../../../../models/asset.model';
import { AssetService } from './../../../../services/api/asset.service';
import { CompanyModel } from '@shared/models/company.model';
import { CompanyService } from '@shared/services/api/company.service';
import { ModelAttributeOptions } from '@shared/models/options';
import { ServiceOptions } from './../../../../models/options';
import { ModelAttributeOptionService } from './../../../../services/api/model-attribute-option.service';
import { Subscription } from 'rxjs';
import { Component, Inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { LaravelPageRequest } from '@shared/models/laravel-page-request.model';
import { AppLoaderService } from '@app/shared/services/system/app-loader/app-loader.service';
import { AppConfirmService } from '@app/shared/services/system/app-confirm/app-confirm.service';
import { get } from 'lodash';
import {TimeConvertService} from '@shared/services/time-convert.service';

@Component({
  selector: 'app-ppm-create',
  templateUrl: './ppm-create.component.html'
})
export class PpmCreateComponent implements OnInit {
  assetTableDisplay = [
    { display: 'Id', key: 'id', useColor: false },
    { display: 'Building Name', key: 'building_name', useColor: false },
    { display: 'Category', key: 'category', useColor: false },
    { display: 'Name', key: 'name', useColor: false },
    { display: 'Description', key: 'description', useColor: false },
    { display: 'Service Interval', key: 'service_interval', useColor: false },
    { display: 'Last Service Time', key: 'last_service_time', useColor: false },
    { display: 'Next Service Time', key: 'next_service_time', useColor: false },
    { display: 'Status', key: 'ppm_status', useColor: true },
  ];
  basic_form: FormGroup;
  template = [];
  table_loading = true;
  public get_item_sub: Subscription;
  public page: Paginate = new Paginate();
  asset_ids = [];
  items: AssetModel[] = [];
  serviceOptions: ServiceOptions;
  assets: AssetModel[] = [];
  supplierCompanies: CompanyModel[] = [];
  @ViewChild(AppTableComponent) assetTable: AppTableComponent;
  assetTableConfig = {
    loading$: false,
    size: 10,
    keyword: ''
  };
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private _fb: FormBuilder,
    public _service: PpmService,
    public _loader: AppLoaderService,
    public _confirm: AppConfirmService,
    public dialog_ref: MatDialogRef<PpmCreateComponent>,
    private mao: ModelAttributeOptionService,
    private companyService: CompanyService,
    private assetService: AssetService,
    private timeConvertService: TimeConvertService
  ) {
    this.page.size = 10;
    this.page.pageNumber = 1;
    this.asset_ids = this.data.asset_ids;
    this.setPage({ offset: 0 });
  }

  ngOnInit() {
    this.buildForm();
    this.getAssets();
    this.getOptions();
    this.getCompanies();
  }

  buildForm() {
    this.basic_form = this._fb.group({
      caller: [''],
      subject: ['', Validators.required],
      problem: ['', Validators.required],
      severity: ['', Validators.required],
      discipline: ['', Validators.required],
      budget: ['', Validators.required],
      expect_start_time: [null],
      expect_end_time: [null],
      asset: [null, Validators.required],
      supplier_company: [null],
      cause: [''],
      finance_type: [''],
      log_type: [''],
      response_time: [''],
      recall: [''],
      warranty: [''],
      compliance: [''],
      out_of_hours_call_out: [''],
      holding_pool: ['']
    });
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    const request = new LaravelPageRequest(
      this.page.size,
      this.page.pageNumber + 1,
      {},
      '',
      { asset_ids: this.asset_ids }
    );

    this.get_item_sub = this._service.search(request.request())
      .subscribe((data: any) => {
        this.table_loading = false;
        this.page = {
          size: data.per_page,
          totalElements: data.total,
          totalPages: data.last_page,
          pageNumber: data.current_page - 1
        };
        this.items = data.data;

      });
  }

  clearTemplate() {
    this.template = [];
  }

  resetForm() {
    this.basic_form.reset();
  }

  save() {
    this._confirm.confirm({ message: `Are you sure you want to add these PPM?` })
      .subscribe(res => {
        if (res) {
          let service = this.basic_form.value;
          service['expect_start_time'] = this.timeConvertService.convert(service['expect_start_time']);
          service['expect_end_time'] = this.timeConvertService.convert(service['expect_end_time']);
          this._loader.open();
          this._service.store(service)
            .finally(() => this._loader.close())
            .subscribe((data: any) => {
              this.dialog_ref.close(true);
            });
        }
      });
  }

  getOptions() {
    this.mao.all()
      .subscribe((result: ModelAttributeOptions) => {
        this.serviceOptions = result.service;
      });
  }

  getCompanies() {
    setTimeout(() => this._loader.open());
    this.companyService.supplierCompaniesBelongFm()
      .finally(() => this._loader.close())
      .subscribe((data: any) => {
        this.supplierCompanies = data;
      });
  }

  getAssets() {
    this._loader.open();
    this.assetService.index(new LaravelPageRequest(
      this.assetTableConfig.size,
      this.assetTable.paginator.pageNumber + 1,
      {},
      '',
      { ids: this.asset_ids.join() })
    )
      .finally(() => this._loader.close())
      .subscribe((response: LaravelPageResponse) => {
        this.assets = response.data;
        this.assetTable.setPaginator(response.total, response.per_page, response.current_page - 1);
        this.updateBudget(this.assets);
      });
  }

  deleteAsset($event) {
    this.assets = this.assets.filter(item => item.id !== $event.id);
    this.asset_ids = this.asset_ids.filter(item => item !== $event.id);
    this.updateBudget(this.assets);
  }

  reloadData() {
    this.basic_form.controls['asset'].setValue(this.asset_ids);
    if (this.template.length > 0) {
      const service = this.template[0];
      this.basic_form.controls['subject'].setValue(service.subject);
      this.basic_form.controls['problem'].setValue(service.problem);
      this.basic_form.controls['caller'].setValue(service.data.caller);
      this.basic_form.controls['cause'].setValue(service.data.cause);
      this.basic_form.controls['severity'].setValue(service.severity);
      this.basic_form.controls['discipline'].setValue(service.discipline);
      this.basic_form.controls['log_type'].setValue(service.data.log_type);
      this.basic_form.controls['budget'].setValue(service.budget);
      this.basic_form.controls['finance_type'].setValue(service.data.finance_type);
      this.basic_form.controls['response_time'].setValue(service.data.response_time);
      this.basic_form.controls['recall'].setValue(service.data.recall);
      this.basic_form.controls['warranty'].setValue(service.data.warranty);
      this.basic_form.controls['holding_pool'].setValue(service.data.holding_pool);
      this.basic_form.controls['compliance'].setValue(service.data.compliance);
      this.basic_form.controls['out_of_hours_call_out'].setValue(service.data.out_of_hours_call_out);
      const company_ids = [];
      service.company_service_relations.map((row: any) => {
        company_ids.push(row.company_id);
      });
      this.basic_form.controls['supplier_company'].setValue(company_ids);
    }
  }

  close() {
    this.dialog_ref.close(false);
  }

  updateBudget(items) {
    let budget = items.reduce((a, b) => {
      return a + get(b, 'data.budget.default', 0);
    }, 0);
    this.basic_form.patchValue({ budget: budget });
    this.basic_form.controls['asset'].setValue(this.asset_ids);
  }

}
