import { LaravelPageRequest } from './../../../../models/laravel-page-request.model';
import { AssetEditPopupComponent } from './../../asset/asset-edit/asset-edit-popup.component';
import { ModelAttributeOptionService } from './../../../../services/api/model-attribute-option.service';
import { ModelAttributeOptions, ServiceOptions } from './../../../../models/options';
import { BaseTableComponent } from '@shared/components/base/base-table.component';
import { MatDialog } from '@angular/material';
import { Component, OnInit, Injector } from '@angular/core';
import { tableConfigs } from '@shared/config';
import { egretAnimations } from '@shared/animations/egret-animations';
import { PpmCreateComponent } from '@shared/components/business/ppm/ppm-create/ppm-create.component';
import { PpmService } from '@app/shared/services/api/ppm.service';
import {NotifyService} from '@shared/services/notify.service';

@Component({
  selector: 'app-ppm-list',
  templateUrl: './ppm-list.component.html',
  animations: egretAnimations
})
export class PpmListComponent extends BaseTableComponent implements OnInit {
  public items: any[];
  public table_config = tableConfigs.ppm;
  public conditions = this._service.conditions;
  public serviceOptions: ServiceOptions;
  constructor(protected injector: Injector,
    private _dialog: MatDialog,
    private _service: PpmService,
    public toast: NotifyService,
    private mao: ModelAttributeOptionService
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.getOptions();
    this.getBuildings();
  }

  getBuildings() {
    let loader = this.loader.open();
    this._service.buildings()
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.conditions.building.items = data;
      });
  }

  getOptions() {
    this.mao.all()
      .subscribe((result: ModelAttributeOptions) => {
        this.serviceOptions = result.service;
        this.conditions.category.items = result.asset.category;
      });
  }

  openAssetEditPage(row) {
    this._dialog.open(AssetEditPopupComponent, {
      width: '960px',
      disableClose: true,
      data: { id: row.id, title: 'Asset Edit(' + row.id + ')' }
    }).afterClosed()
      .subscribe((data: any) => {
        if (data) {
          this.items.splice(row.rowIndex, 1, data);
          this.items = [...this.items];
          this.toast.show('Asset Updated');
        }
      });
  }

  openPPMSelectAddPage() {
    if (this.tableComponent.selected.length > 0) {
      const asset_ids = [];
      this.tableComponent.selected.map((row: any) => {
        asset_ids.push(row.id);
      });
      this._dialog.open(PpmCreateComponent, {
        width: '960px',
        disableClose: true,
        data: { asset_ids: asset_ids }
      }).afterClosed()
        .subscribe((data: any) => {
          if (data) {
            this.tableComponent.selected = [];
            this.toast.show('PPM Add success!');
          }
        });
    } else {
      this.toast.show('Please choice asset');
    }
  }

  openPPMSingleAddPage($event) {
    this.tableComponent.selected = [$event];
    this.openPPMSelectAddPage();
  }

  openPPMAllAddPage() {
    if (this.conditions.category && this.conditions.category.value) {
      let request = new LaravelPageRequest(
        10, 0, this.conditions, '', { paginate_type: 'all' }
      );
      this.tableComponent.selected = [];
      let loader = this.loader.open();
      this._service.index(request)
        .finally(() => loader.close())
        .subscribe((data: any) => {
          console.log('get_type', data);
          const asset_ids = [];
          data.map((row: any) => {
            asset_ids.push(row.id);
          });
          this._dialog.open(PpmCreateComponent, {
            width: '960px',
            disableClose: true,
            data: { asset_ids: asset_ids }
          }).afterClosed()
            .subscribe((result: any) => {
              if (result) {
                this.tableComponent.selected = [];
                this.toast.show('PPM Add success!');
              }
            });
        });
    } else {
      this.toast.show('Please choice a category');
    }
  }
}
