import {Injectable} from '@angular/core';
import {ApiBaseService} from '@shared/services/api/api-base.service';
import {AssetModel} from '@shared/models/asset.model';
import {Observable} from 'rxjs';
import {TableConfigModel} from '@shared/models/table-config.model';
import {tableConfigs} from '@shared/config';

@Injectable()
export class AssetService extends ApiBaseService {
  route_prefix = 'assets';
  tableConfig: TableConfigModel = tableConfigs.asset;

  public byBuildingId(buildingId): Observable<[AssetModel] | Object> {
    return this.http.get(`${this.route_prefix}/by-building-id/${buildingId}`);
  }
}
