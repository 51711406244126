import { Injectable } from '@angular/core';
import * as moment from 'moment';
import 'moment-timezone';


@Injectable({
  providedIn: 'root'
})
export class TimeConvertService {

  constructor() { }

  public convert(time) {
    return moment(time).tz('Europe/London').format();
  }
}
