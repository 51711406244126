import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ServiceCustomizeService } from '../service-customize.service';

@Component({
  selector: 'app-service-customize-data-create',
  templateUrl: './service-customize-data-create.component.html',
  styleUrls: ['./service-customize-data-create.component.scss']
})
export class ServiceCustomizeDataCreateComponent implements OnInit, OnDestroy {
  public customizeFormGroup: FormGroup = this.customizeService.customizeFormGroup;

  constructor(
    public customizeService: ServiceCustomizeService,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.customizeService.clean();
  }

  addCustomize() {
    this.customizeService.add(
      this.customizeFormGroup.value.key,
      this.customizeFormGroup.value.value
    );
    this.customizeFormGroup.reset();
  }

  removeCustomize(index) {
    this.customizeService.remove(index);
  }

}
