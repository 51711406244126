import { GlobalSettingService } from './../../../services/system/global-setting.service';
import { Component, OnInit, Input, Renderer2 } from '@angular/core';
import { ThemeService } from '@shared/services/system/theme.service';
import { LayoutService } from '@shared/services/system/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthGuard } from '@shared/services/system/auth/auth.guard';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingService } from '@shared/services/api/setting.service';
import { AppLoaderService } from '@shared/services/system/app-loader/app-loader.service';
import { ModelAttributeOptionService } from '@app/shared/services/api/model-attribute-option.service';
import { UserService } from '@shared/services/api/user.service';
import {NotifyService} from '@shared/services/notify.service';

@Component({
  selector: 'app-header-side',
  templateUrl: './header-side.template.html'
})
export class HeaderSideComponent implements OnInit {
  @Input() globalSettingPanel;
  currentLang = 'en';
  public availableLangs = [
    {
      name: 'English',
      code: 'en'
    },
    {
      name: 'Chinese',
      code: 'zh-cn'
    }
  ];
  public egretThemes;
  public layoutConf: any;

  constructor(
    private themeService: ThemeService,
    private layout: LayoutService,
    public translate: TranslateService,
    private renderer: Renderer2,
    private authGuard: AuthGuard,
    private router: Router,
    private route: ActivatedRoute,
    private setting: SettingService,
    private loader: AppLoaderService,
    private toast: NotifyService,
    public globalSetting: GlobalSettingService,
    private user: UserService,
    private mao: ModelAttributeOptionService
  ) { }

  ngOnInit() {
    this.egretThemes = this.themeService.egretThemes;
    this.layoutConf = this.layout.layoutConf;
    this.translate.use(this.currentLang);
  }

  setLang(e) {
    this.translate.use(this.currentLang);
  }

  changeTheme(theme) {
    this.themeService.changeTheme(this.renderer, theme);
  }

  toggleGlobalSetting() {
    this.globalSettingPanel.toggle();
  }

  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      });
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    });
  }

  toggleCollapse() {
    // compact --> full
    if (this.layoutConf.sidebarStyle === 'compact') {
      return this.layout.publishLayoutChange(
        {
          sidebarStyle: 'full'
        },
        { transitionClass: true }
      );
    }

    // * --> compact
    this.layout.publishLayoutChange(
      {
        sidebarStyle: 'compact'
      },
      { transitionClass: true }
    );
  }

  signout() {
    this.authGuard.signout();
  }

  onEnter(keyword) {
    if (keyword) {
      this.router.navigate(['/search', keyword, { k: new Date().getTime() }]);
    }
  }

  changeTableStyle() {
    this.layout.publishLayoutChange(
      {
        switchTable:
          this.layout.layoutConf.switchTable === 'table' ? 'panel' : 'table'
      },
      { transitionClass: true }
    );
  }

  refresh() {
    this.layout.onRefresh.emit();
  }

  refreshCache() {
    this.setting.refreshCache();
  }
}
